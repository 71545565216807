import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './styles.scss'
import CartSummary from 'components/CartSummary'
import Login from 'components/Login'
import Page from 'containers/CreatePack/Cart/Page'

class CartSignin extends React.Component {

  static propTypes = {
    cart: PropTypes.shape({}).isRequired,
    logged_in: PropTypes.bool,
    createNotification: PropTypes.func,
  }

  static defaultProps = {
    cart: {
      party_packs:[],
    }
  }

  state = {
    cart: {
      party_packs: [],
    },
  }


  render() {
    return (
      <div className="cart-signin">
        <div className="container">

          <Page
            component={
              <Login
                orientation="cart-orientation"
                title={"Sign in to complete your purchase"}
                createNotification={this.props.createNotification}
              />
            }
            cartSummary={<CartSummary mobileEnabled/>}
          />
        </div> 
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    cart: state.party_data.cart,
    logged_in: state.auth_data.logged_in,
  };
};
export default withRouter(connect(mapStateToProps, { })(CartSignin));
