import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import partyPackersLogo from "assets/images/party-packers-logo.svg"
import { submitLogout, submitClearLocalCart } from 'redux/actions.js'

import './styles.scss'

import crossIcon from "assets/images/cross-icon.svg"

class SidePanel extends React.Component {

  static propTypes = {
    cart: PropTypes.shape({}).isRequired,
    logged_in: PropTypes.bool,
    createNotification: PropTypes.func,
    cartOpen: PropTypes.bool.isRequired,
    closeSidePanel: PropTypes.func.isRequired,
  }

  static defaultProps = {
    cart: {
      party_packs:[],
    },
  }

  state = {
    cart: {
      party_packs: [],
    },
    swapping: false,
    pack: null,
  }

  render_side_panel() {
    const { logged_in, closeSidePanel, cartOpen } = this.props


    let slideAnimation = ''

    if (cartOpen === true) {
      slideAnimation = 'slide-in-from-left'
    } else if (cartOpen === false) {
      slideAnimation = 'slide-out-from-left'
    } else {
      slideAnimation='closed-side-panel'
    }

    return (
      <div className="side-panel">
        { cartOpen && (
          <div className="underlay" onClick={() => closeSidePanel()} />
        )}
        <div className={`content ${slideAnimation}`}>
          <div className="heading">
            <h4 className="title">
              <img alt="Party Packers" src={partyPackersLogo} />
            </h4>
            <div className="cross-icon" onClick={() => closeSidePanel()}>
              <img alt="Cross" src={crossIcon} />
            </div>
          </div>
          <div className="container">
            <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/'}>Home</Link>
            <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/build'}>Build a Party Pack</Link>
            <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/pick'}>Pick a Party Pack</Link>
            <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/about'}>About Us</Link>
            <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/contact'}>Contact Us</Link>
            { logged_in ? 
              (
                <React.Fragment>
                  <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/account/basic'}>Profile</Link>
                  <Link
                    className="side-panel-link"
                    to={'/'}
                    onClick={ () => {
                      this.props.onSubmitLogout()
                      closeSidePanel()
                    }}
                  >
                    Logout
                  </Link>
                </React.Fragment>
              )
              :
              (
                <React.Fragment>
                  <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/login'}>Login</Link>
                  <Link onClick={() => closeSidePanel()} className="side-panel-link" to={'/signup'}>Register</Link>
                </React.Fragment>
              )
            }

          </div>
        </div>
      </div>
    )
  }
  render() {
    const { swapping } = this.state

    return(
      <div className="">
        <CSSTransition
          in={!swapping}
          timeout={500}
          classNames="slide-pack"
          mountOnEnter
          unmountOnExit
        >
          { this.render_side_panel() }
        </CSSTransition>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    cart: state.party_data.cart,
    logged_in: state.auth_data.logged_in,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmitLogout: () => {
      dispatch(submitLogout())
      dispatch(submitClearLocalCart())
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidePanel));
