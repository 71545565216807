import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

import { NotificationManager } from 'react-notifications'

import Input from 'components/Input'

import { submitLogin, submitMergeCart, submitGetCartServer } from 'redux/actions.js'

import loadingGif from "assets/images/loading.gif"

import AuthHeading from '../AuthHeading'

import './styles.scss'

class Login extends React.Component {

  static propTypes = {
    user: PropTypes.shape({}),
    logged_in: PropTypes.bool,
    error_message: PropTypes.string,
    subtitle_overide: PropTypes.string,
    is_checking_out: PropTypes.bool,
    createNotification: PropTypes.func,
  };

  static defaultProps = {
    subtitle_overide: null,
  }

  state = {
    email: '',
    password: '',
    error_message: '',
  };

  async handle_login(e, data) {
    e.preventDefault();

    data = {
      username: data.email,
      password: data.password,
    }

    const locationState = this.props.location.state


    this.props.submitLogin(data).then(()=> {
      this.props.submitMergeCart().then(() => {
        this.props.submitGetCartServer().then(()=>{
          NotificationManager.success("You have successfully logged in.", 'Success');
          if (locationState) {
            this.props.history.push('/checkout')
          } else {
            this.props.history.push('/')
          }
        })
      })
    }).catch(err => {
      NotificationManager.error("Incorrect username or password.", 'Error...', 5000)
    })
  }


  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
    this.setState({ error_message:'' })
  };


  render() {
    const { is_loading, error_message } = this.props

    const { email, password } = this.state

    return (
      <div className="login-page">
        <AuthHeading headingText={"Sign into your account"}/>
        <div className="login-container">
          <form className="signup-form" onSubmit={e => this.handle_login(e, this.state)}>
            <div className="form-input">
              <div className="form-control">
                <Input
                  type="text"
                  name="email"
                  value={email}
                  handle_change={this.handle_change}
                  required={true}
                  placeholder='Email address*'
                />
              </div>
              <div className="form-control">
                <Input
                  type="password"
                  name="password"
                  value={password}
                  handle_change={this.handle_change}
                  required={true}
                  placeholder='Password*'
                />
              </div>
            </div>
            <div className="forgot-password">
              <Link to="/reset/password">Forgot password?</Link>
            </div>
            { is_loading && (
              <img className="loader" src={loadingGif} alt="Loader"/>
            )}
            <div className="button-container">
              <input type="submit" value="Sign In" />
            </div>
          </form>
          <div className="register">
            New user? <Link to="/signup">Create an account</Link>
          </div>
        </div>
      </div>
    )
  }

  reset_form = () => {
    this.setState({
      email: '',
      password: '',
    })
  }

}

const mapStateToProps = state => {
  return {
    user: state.auth_data.user,
    logged_in: state.auth_data.logged_in,
    error_message: state.auth_data.error_message,
    is_checking_out: state.checkout_data.is_checking_out
  };
};

export default withRouter(connect(mapStateToProps, { submitGetCartServer, submitLogin, submitMergeCart })(Login));
