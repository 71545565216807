import React from 'react'
import './styles.scss'
import FullPageHeader from 'components/FullPageHeader'
import PAIAManual from 'assets/pdfs/melbro-paia-manual.pdf'

import { Helmet } from 'react-helmet'

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy Policy" />
        <meta name="keywords" content="privacy policy, Party Packers, laws, policies, Melbro Wholesalers, introduction, contact us, amendment, indemnity, information collection, disclaimer" />
      </Helmet>
      <FullPageHeader headingText={"Privacy Policy"} />
      <div className="container">
        <div className="text">
          <div className="section">
            <h4>Introduction</h4>
            <p>For purposes of this Policy:</p>
            <br/>

            <p>“Applicable Laws” means any laws, regulations, treaties and codes, 
              including the Consumer Protection Act 68 of 2008 and the Protection 
              of Personal Information Act 4 of 2013, which may be applicable to your 
              relationship with us;
            </p>
            <br/>

            <p>"Client" or “you” means any prospective, new or existing client of Party Packers; and</p>
            
            <br/>
            <p>"Party Packers", “we” or “us” means Melbro Wholesale (Pty) Ltd trading as Party Packers, 
              Company Registration Number: 1999/023585/07, of 9 Packer Avenue, Epping Industria II, Eppindust, Cape Town, 7460, South Africa.
            </p>
            <br/>

            <p>
              This Policy sets out how your personal information will be used by Party Packers, our website www.partypackers.co.za (“Website”), 
              and any of our affiliated companies or brands and applies to any information, including personal and special personal information, 
              you give to Party Packers, or which Party Packers may collect from third parties.
            </p>
            <br/>

            <p>This policy should be read with the Website terms and conditions.</p>
            <br/>

            <p>It is important that you read this Policy carefully before submitting any personal information to Party Packers.</p>
            <br/>

            <p>
              By submitting any personal information to Party Packers you provide consent to the 
              processing of your personal information as set out in this Policy.
            </p>
            <br/>

            <p>The provisions of this Policy are subject to mandatory, unalterable provisions of Applicable Laws.</p>

            <p>
              Please do not submit any personal information to Party Packers if you do not agree to any of the provisions of this Policy. 
              If you do not consent to the provisions of this Policy, or parts of the Policy, Party Packers may not be able to 
              provide its products and services to you.
            </p>
          </div>
          
          <div className="section">
            <h4>How to contact us</h4>
            <p>
              If you have any complaints, comments, or questions about this Policy please contact our Group Information Officer, 
              via email at POPIA@melbro.co.za, or via our National Administrator at +27 21 505 5810.
            </p>
          </div>
          
          <div className="section">
            <h4>Amendment of this policy</h4>
            <p>
              We may amend this Policy from time to time for any of the following reasons:
              <ul>
              <li>To provide for the introduction of new systems, methods of operation, services, products, offerings or facilities;</li>
              <li>To comply with changes to any legal or regulatory requirements;</li>
              <li>To ensure that our Policy is clearer and more favourable to you or us;</li>
              <li>To rectify any mistake that might be discovered from time to time; and/or</li>
              <li>For any other reason which we, in its sole discretion, may deem reasonable or necessary.</li>
              </ul>
            </p>
            <br/>
            <p>
              Any such amendment/s will come into effect and be binding on all parties upon notice to you via 
              electronic means (such as SMS) or by publication of the amendment/s on the official website.
            </p>
          </div>

          <div className="section">
            <h4>Privacy and indemnity</h4>
            <p>
              Party Packers takes your privacy and the protection of your personal information very seriously, and we will only 
              use your personal information in accordance with this Policy and Applicable Laws. 
              It is important that you take all necessary and appropriate steps to protect your personal information yourself.
            </p>
            <br/>
            <p>
              We have implemented reasonable technical and operational measures to keep your personal information secure.
            </p>
            <br/>
            <p>
              You hereby indemnify and hold Party Packers harmless from any loss, damages or injury that you 
              may incur as a result of any unintentional disclosures of your personal information to 
              unauthorised persons or the provision of incorrect or incomplete personal information to Party Packers.
            </p>
            <br/>
            <p>
              Information which we may collect about you:
              <ul>
                <li>Your name, address, contact details, date of birth;</li>
                <li>Records of correspondence or enquiries from you or anyone acting on your behalf;</li>
                <li>Details of purchases you make from us;</li>
                <li>Sensitive or special categories of personal information, such as images (from CCTV camera footage).</li>
              </ul>
              We may require you to provide additional personal information, in order for us to meet our legal or regulatory obligations.
            </p>
            <p>

            </p>
          </div>


          <div className="section">
            <h4>How we collect information</h4>
            <p>
              You may provide personal information to us either directly or indirectly, by entering our promotional competitions, through our Website, in our stores, over the telephone, CCTV cameras, digital recordings or any other means.
            </p>
            <br/>
            <p>
              We may also collect your personal information from any regulator, or other 
              third party that may hold such information, where we are lawfully entitled to do so.
            </p>
          </div>


          
          <div className="section">
            <h4>Use of information collected</h4>
            <p>
              We may use, transfer and disclose your personal information for the purposes of:
              <ul>
                <li>Providing you with our goods;</li>
                <li>Complying with your instructions or requests;</li>
                <li>Matters relating to our promotional competitions;</li>
                <li>Detecting and preventing fraud and money laundering and/or in the interest of security and crime prevention;</li>
                <li>Assessing and dealing with complaints and requests;</li>
                <li>Operational, marketing, auditing, statistical, legal and record keeping requirements;</li>
                <li>Complying with Applicable Laws, including lawful requests for information received from local or foreign law enforcement, government and tax collection agencies;</li>
                <li>Recording and/or monitoring your telephone calls and electronic communications to/with Party Packers in order to keep record thereof, accurately carry out your instructions and requests, to use as evidence and in the interests of crime prevention;</li>
                <li>Conducting market research;</li>
                <li>Where you have unsubscribed from certain direct marketing communications, for the purposes of ensuring that we do not send such direct marketing to you again;</li>
                <li>To disclose your personal information to third parties for reasons set out in this Policy or where it is not unlawful to do so; and</li>
                <li>Improving or evaluating the effectiveness of our business or offerings.</li>
              </ul>
            </p>
          </div>


          <div className="section">
            <h4>Direct marketing and unsubscribing</h4>
            <p>We may from time to time contact you about our goods, services, and offerings, which we believe may be of interest to you, by email, phone, text or other electronic means, unless you have unsubscribed from receiving such communications.</p>
            <br/>
            <p>If you no longer wish to receive e-mails from us and want to be removed from our mailing list, please e-mail us at the address Unsubscribe.MWS@melbro.co.za with “Party Packers Email Unsubscribe” in the subject heading.</p>
            <br/>
            <p>If you choose to unsubscribe from our mailing lists, we will hold your contact details on file so marked so that we do not contact you again. This is so that we do not contact you if your details are subsequently provided to us by a third party.</p>

          </div>

          <div className="section">
            <h4>Disclosure of your information</h4>
              <p>Your personal information may be shared with our agents and sub-contractors, and selected third parties who process the information on our behalf.</p>
              <p>
                We may also disclose your personal information to third parties when we are entitled or obliged to do so under Applicable Law, including to:
                <ul>
                <li>determine which goods, services and offerings may be of interest to you and/or to send you information about these, unless you object or choose not to receive such communications;</li>
                <li>have a better understanding of the your circumstances and needs to provide and improve our goods, services and offerings;</li>
                <li>give effect to our promotional competitions;</li>
                <li>prevent, detect and report fraud and criminal activities, and to identify the proceeds of unlawful activities and the combatting of crime; and</li>
                <li>comply with requests from third parties, including local or foreign regulators, if Party Packers is required to do so in terms of Applicable Laws; or to protect the rights, property or safety of Party Packers, clients or other third parties.</li>
                </ul>
              </p>
              
              <p>We may transfer your information to an agent, sub-contractor or third party who carries on business in another country, including one which may not have data protection laws similar to those of the Republic. If this happens, we will ensure that anyone to whom we pass your information agrees to treat your information with the same level of protection as if we were dealing with it.</p>
              <br/>
              <p>If you do not wish us to disclose this information to third parties, please contact our Information Officer at the contact details set out above. We may, however, not be able to provide our goods, services or offerings to you if such disclosure is necessary.</p>

          </div>

          



          <div className="section">
            <h4>Retention of your information</h4>
            <p>
              We may retain your personal information indefinitely, unless you object, in which case we will only retain it if we are permitted or required to do so in terms of Applicable Laws. However, as a general rule, we will retain your information in accordance with retention periods set out in Applicable Laws, unless we need to retain it for longer for a lawful purpose.
            </p>
          </div>


          <div className="section">
            <h4>Access to correction and deletion of your personal information</h4>
            <p>You may request details of personal information which we hold about you under the Protection of Personal Information Act 4 of 2013 or information about third parties under the Promotion of Access to Information Act 2 of 2000 (“PAIA”). Fees to obtain a copy or a description of such personal information are prescribed in terms of PAIA. Confirmation of whether or not we hold personal information about you may be requested free of charge.</p>
            <br/>
            <p>You may request the correction of personal information which Party Packers holds about you. Please ensure that the information we hold about you is complete, accurate and up to date. If you fail to keep your information updated, or if your information is incorrect, Party Packers may limit the goods, services and offerings offered to you.</p>
            <br/>
            <p>You have a right in certain circumstances to request the destruction or deletion of and, where applicable, to obtain restriction on the processing of personal information held about you. If you wish to exercise this right, please contact us using the contact details set out above.</p>
            <br/>
            <p>You have a right to object on reasonable grounds to the processing of your personal information.</p>
            <br/>
            <p>For more information in this regard please read our <a className="paia-manual-link" href={PAIAManual} target = "_blank">PAIA manual</a>.</p>


          </div>


          <div className="section">
            <h4>Linking</h4>
            <p>Our Website contain hyperlinks to other pages on our Website. We may use technology to track how often these links are used and which pages on our Website our visitors choose to view. This technology does not identify you personally: it simply enables us to compile statistics about the use of these hyperlinks.</p>
            <br/>
            <p>You may link to another website which is outside of our control. Once you have left our Website, Party Packers does not remain responsible for the protection and privacy of any information which you provide. It is imperative that you exercise caution and look at the privacy statement applicable to the website of any third party.</p>
          </div>

          <div className="section">
            <h4>Cookies</h4>
            <p>Party Packers uses cookies on our Website that store information on your hard drive or browser, allowing the Website to recognise that you have visited these before.</p>
            <br/>
            <p>In order to collect the anonymous data (i.e. data that does not identify you personally but purely statistical data), we may use temporary “cookies” that remain in the cookies file of your browser until the browser is closed. Cookies are small, often encrypted text files, located in browser directories. They help users navigate Website efficiently and allow certain functions, such as buying products online. When you visit Website, cookies may be stored on your computer. They are used to store a variety of information about you and your preferences, which is afterwards sent back to the visited website.</p>
            <br/>
            <p>Cookies by themselves cannot be used to discover your identity. Cookies do not damage your computer. You can set your browser to notify you when you receive a cookie. This enables you to decide if you want to accept it or not. If you choose not to accept cookies from our Website this may limit their functionalities or performance. You can stop your browsers from accepting cookies by changing the settings on your web browser. Explore the settings and options on your browser to disable or enable cookies.</p>
            <p>
              Our use of cookies is to enable more user-friendly, efficient and safe visits to our Website. We use cookies for several reasons:
              <ul>
                <li>to identify you as an authorised user;</li>
                <li>to provide you with safe restricted access areas;</li>
                <li>to remember you so that when you come back to our Website you do not need to enter your information again; and</li>
                <li>to understand what brought you to our Website and what pages you have visited.</li>
              </ul>
            </p>
            <p>Besides these, we may also use cookies which enable us to save your preferences from each of your visits to our Website and present you with a version of our Website which reflects your preferences. Our system is therefore able to recognize you each time you visit our Website, without requiring you to systematically log in each time you visit.</p>
            <br/>
            <p>You can find more information about cookies at: http://www.allaboutcookies.org/ and for a video about cookies, visit <a style={{textDecoration: 'underline'}} href="http://www.google.com/policies/technologies/cookies/">this</a> link.</p>

          </div>

          <div className="section">
            <h4>Feedback</h4>
              <p>We welcome comments about this Policy. If you have any questions about this Policy, you may contact us by calling our National Administrator at +27 21 505 5810. We will store any correspondence from you at our offices.</p>
              <p>COMPLAINTS</p>
              <p>Should you believe that we have utilised your personal information contrary to Applicable Laws, you undertake to first attempt to resolve any concerns with us.</p>
              <p>If you are not satisfied with such process, you may have the right to lodge a complaint with the Information Regulator, using the contact details listed below:</p>
              <p>Tel: 012 406 4818</p>
              <p>Fax: 086 500 3351</p>
              <p>Email: inforeg@justice.gov.za</p>
          </div>

          <div className="section">
            <h4>Website Disclaimer</h4>
              <i><p>While we have taken care to ensure that the contents and other information on our Website are accurate, this Website and the products or services accessible on this Website and your use of and reliance on the information on this Website and the online services, are entirely at your own risk. Party Packers accepts no liability for any loss or damage of whatsoever nature that may be caused, directly or indirectly, by the use of this Website or reliance on any information contained therein.</p></i>
              <br/>
              <i><p>Party Packers will not be liable for any event over which we have no control e.g. downtime or failures for services provided by third parties such as public telecommunication service providers, Internet services providers, electricity suppliers or local authorities.</p></i>
              <br/>
              <i><p>Party Packers will not be liable for any damage or loss regarding customer data directly or indirectly caused by any malfunction of our system, third party systems, power failures, computer viruses or unlawful access of data on our system or third party systems or programming defects.</p></i>
          </div>

          <div className="section">
            <h4>Note</h4>
              <i><p>This website uses cookies and similar technologies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our Privacy Policy and our cookies and/or similar technologies usage.</p></i>
          </div>

          

          

          
          













          








        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
