import React from 'react';
import './styles.scss'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { NOTIFICATIONS } from 'constants/notifications.js'
import { connect } from 'react-redux'


import { submitConfirmEmailChange } from 'redux/actions.js'

class ConfirmEmailChange extends React.Component {

  static propTypes = {
    createNotification: PropTypes.func,
  }

  componentDidMount(){
    const id = this.props.match.params.id;
    const data = {
      id
    }
    this.props.submitConfirmEmailChange(data).then(()=>{
      this.props.createNotification(NOTIFICATIONS.SUCCESS, "Your email address was updated successfully.")
      this.props.history.push('/login')
    }).catch(err => {
      this.props.createNotification(NOTIFICATIONS.ERROR, "Something went wrong...")
    })
  }

  render() {
    return (
      <div className="confirm-email-change">
        <div className="container">
          <div className="title-block">
            <div className="title">
              <b>Email Change Confirmation</b>
            </div>
            <div className="description">
              Please log in again.
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(connect(null, {submitConfirmEmailChange})(ConfirmEmailChange));
