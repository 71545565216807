import React from 'react';
import { Link, useLocation } from 'react-router-dom'

import { authenticated } from 'redux/auth/action.js'

import './styles.scss'

function BreadCrumbs() {

    const crumb_data = [
      {
        path: '/cart',
        title: 'Cart',
        protected: false,
      },
      {
        path: '/cart/signin',
        title: 'Sign In',
        protected: false,
      },
      {
        path: '/cart/information',
        title: 'Information',
        protected: true,
      },
      {
        path: '/cart/payment',
        title: 'Payment',
        protected: true,
      },
    ]

    const location = useLocation();

    return (
      <div className="bread-crumbs">
        { crumb_data.map((crumb, index) => {
          return (
            <div key={crumb.path} className={`crumb ${location.pathname===crumb.path ? 'active' : ''}`}>
              <div className="crumb-title"><Link className={`${(crumb.protected  & !authenticated() )? 'unauthorised' : ''}`} to={crumb.path}>{ crumb.title }</Link></div>
              { index !== crumb_data.length - 1 && (
                <div className="crumb-divider">&gt;</div>
              )}
            </div>
          )
        })}
      </div>
    )



}



export default BreadCrumbs;
