import React from 'react'
import { withRouter, Link } from 'react-router-dom';

import { connect } from 'react-redux'


import 'react-multi-carousel/lib/styles.css';
import 'rc-slider/assets/index.css';
import './styles.scss'


import buildPackBackground from "assets/images/build-pack-card.jpg"

import { startBuildingPack } from 'redux/actions'

class BuildYourOwn extends React.Component {
  render() {   
    return (
        <div className='build-your-own'>
          <h4 className="text">
            . . . or start with an empty pack
          </h4>
          <div className="image-container">
            <img alt="Background" src={buildPackBackground}></img>
            <Link to="/build" className="build-button" onClick={()=>{this.props.startBuildingPack()}}>
                Build your own Pack
            </Link>
          </div>
        </div>
    )
  }
}

export default withRouter(connect(null, { startBuildingPack })(BuildYourOwn));
