import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'


class ContinueShoppingModal extends React.Component {
  render() {
    return(
      <div className="continue-shopping-modal">
        <div className="underlay"></div>
        <div className="main">
          {/* <div className="close-container" onClick={() => this.close()}>
            <img src={crossIcon} className="close-image"/>
          </div> */}
          <h4 className="text">
            Would you like to continue?
          </h4>
          <div className="button-container">
            <Link to="/pick" className="checkout-button">
                Continue Shopping
            </Link>
            <Link to="/cart" className="continue-shopping-button">
                Go To Checkout
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default ContinueShoppingModal;
  