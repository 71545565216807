import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import { updateFilters, resetFilters } from 'redux/actions.js'
import { connect } from 'react-redux'
import Select from 'react-select';

import magnifyingGlassIcon from "assets/images/magnifying-glass-icon.svg"
import dropdownIcon from "assets/images/dropdown-icon.svg"
import deleteIcon from "assets/images/cross-icon.svg"

import Price from './Price'
import Age from './Age'
import Tags from './Tags'
import Gender from './Gender'
import Other from './Other'
import SortBy from './SortBy'
import Reset from './Reset'

import 'react-multi-carousel/lib/styles.css';
import 'rc-slider/assets/index.css';
import './styles.scss'

class CreatePackFilter extends React.Component {
  static propTypes = {
    filters: PropTypes.shape({}).isRequired,
  }

  state = {
    toggled_filter: '',
    width: window.innerWidth,
    toggled_filter_mobile: false,
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  toggleFilter = filter_name => {
    if(filter_name === this.state.toggled_filter) {
      this.setState({toggled_filter: ''})
    } else {
      this.setState({toggled_filter: filter_name})
    }
  }

  updateFilterRedux = (filters) => {
    this.props.updateFilters(filters)
  }

  updateFilters = e => {
    let filters = Object.assign({}, this.props.filters)
    const name = e.target.name
    const filter_name = name.split('.')[2]
    filters.other[filter_name] = !filters.other[filter_name]
    this.updateFilterRedux(filters)
  }

  updateSearch = e => {
    const term = e.target.value
    let filters = Object.assign({}, this.props.filters)
    filters.search_term = term
    this.updateFilterRedux(filters)
  }

  clearSearch() {
    let filters = Object.assign({}, this.props.filters)
    filters.search_term = ""
    this.updateFilterRedux(filters)
  }

  reset(){
    this.props.resetFilters()
    this.setState({toggled_filter_mobile: false})
  }

  renderFilters() {
    const { toggled_filter } = this.state
    return (
      <React.Fragment>
        <Price filters={ this.props.filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'price'} contentOnly={false} type={this.props.type} />
        <Age filters={ this.props.filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'age'}  contentOnly={false} />
        <Tags filters={ this.props.filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'tags'} contentOnly={false} />
        <Gender filters={ this.props.filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'gender'} contentOnly={false} />
        <SortBy filters={ this.props.filters } updateFilters={ this.updateFilterRedux }  toggleFilter={this.toggleFilter} toggled={toggled_filter === 'sort_by'} contentOnly={false} />
        <Other filters={ this.props.filters } updateFilters={ this.updateFilterRedux }  toggleFilter={this.toggleFilter} toggled={toggled_filter === 'other'} contentOnly={false} />
        <Reset />
      </React.Fragment>
    )
  }

  renderMobileFilters() {
    const { toggled_filter, toggled_filter_mobile } = this.state
    const { filters } = this.props
    return (
      toggled_filter_mobile ? (
      <div className="mobile-filters-container">
        <div className="header">
          <div className="title">
            Filter
          </div>
          <div className="close-icon" onClick={() => this.setState({toggled_filter_mobile: false})}>
            <img src={deleteIcon} />
          </div>
        </div>
        <div className="mobile-filter">
          <div className="heading">
            Price Range
          </div>
          <Price filters={ filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'price'} contentOnly={true}/>
          <div className="divider" />
        </div>
        <div className="mobile-filter">
          <div className="heading">
            Age Range
          </div>
          <Age filters={ filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'age'}  contentOnly={true} />
          <div className="divider" />
        </div>
        <div className="mobile-filter">
          <div className="heading">
            Tags
          </div>
          <Tags filters={ filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'tags'} contentOnly={true} />
          <div className="divider" />
        </div>
        <div className="mobile-filter">
          <div className="heading">
            Gender
          </div>
          <Gender filters={ filters } updateFilters={ this.updateFilterRedux } toggleFilter={this.toggleFilter} toggled={toggled_filter === 'gender'} contentOnly={true} />
          <div className="divider" />
        </div>
        <div className="mobile-filter">
          <div className="heading">
            Sort By
          </div>
          <SortBy filters={ filters } updateFilters={ this.updateFilterRedux }  toggleFilter={this.toggleFilter} toggled={toggled_filter === 'sort_by'} contentOnly={true} />
          <div className="divider" />
        </div>
        <div className="mobile-filter">
          <div className="heading">
            Other
          </div>
          <Other filters={ filters } updateFilters={ this.updateFilterRedux }  toggleFilter={this.toggleFilter} toggled={toggled_filter === 'other'} contentOnly={true} />
          <div className="divider" />
        </div>
        <div className="filter-footer">
          <div className="reset" onClick={() => this.reset()}>
            Reset filters
          </div>
          <div className="close" onClick={() => this.setState({toggled_filter_mobile: false})}>
            Close
          </div>
        </div>
      </div>
      )
      :
      (
        <div className="filter-modal-button" onClick={() => this.setState({toggled_filter_mobile: true})}>Filters</div>
      )
    )

  }


  render() {

    const { filters, tags } = this.props
    const { toggled_filter, width } = this.state

    const isMobile = width <= 768

    const options = tags.map(tag => {
      if (tag){
        return { value: tag, label: tag.toLowerCase() }
      }
    })

    return (
      <div className="filter-component">
        { (toggled_filter !== '' && !isMobile) && (
          <div className="filter-underlay" onClick={() => this.setState({toggled_filter: ''})} />
        )}
        <div className={`filters ${ toggled_filter !== '' ? 'filter-actives' : ''}`}>
          { isMobile ? (
            this.renderMobileFilters()
          )
          :
          (
            this.renderFilters()
          )}

          <div className="age-search">
            <div className="search">
              <div className="search-container">
                <div className={`search ${filters.search_term.length > 0 ? 'search-active' : ''}`}>
                  { filters.search_term.length > 0 ? (
                    <img className="search-icon" src={deleteIcon} onClick={ () => this.clearSearch() }/>
                  )
                  :
                  (
                    <img className="search-icon" src={magnifyingGlassIcon} onClick={ () => this.clearSearch() }/>
                  )}
                  <input type="text" className="searchTerm search-big" placeholder="Search for products to pack" onChange={this.updateSearch} value={filters.search_term} />
                  <input type="text" className="searchTerm search-small" placeholder="Search..." onChange={this.updateSearch} value={filters.search_term} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filter_data.filters,
    tags: state.filter_data.tags,
  };
};

export default withRouter(connect(mapStateToProps, { updateFilters, resetFilters })(CreatePackFilter));
