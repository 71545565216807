import React from 'react'
import { connect } from 'react-redux'
import greenSquiggle from 'assets/images/squiggle.svg'
import starDots from 'assets/images/star-dots.svg'
import yellowSquiggle from 'assets/images/yellow-squiggle.svg'
import starDots2 from 'assets/images/star-dots-2.svg'
import partyPack from 'assets/images/party-packs.png'
import cart from 'assets/images/cart-icon.svg'
import pointerCursor from 'assets/images/pointer-cursor.svg'
import exampleItemPNG from 'assets/images/landing-item.png'
import exampleItemWebP from 'assets/images/landing-item.webp'
import './styles.scss'


function HowItWorks({cg_delivery_time}) {
  return (
    <div className="how-it-works">
      <h2 className="heading" id="how-it-works">
        How it works
      </h2>
      <div className="steps">
        <div className="step">
          <div className="image-container">
            <div className="grey-container">
              <div className="image-background">
                <div className="add-to-pack-button">+ Add to Pack</div>
                <picture>
                  <source srcSet={exampleItemWebP} type="image/webp" />
                  <source srcSet={exampleItemPNG} type="image/png" />
                  <img alt="Party Item" className="image" src={exampleItemPNG}/>
                </picture>
              </div>
            </div>
          </div>
          <div className="text-container">
            <div className="text">
              <div className="title-container">
                <div className="step-number yellow">
                  <span>1</span>
                </div>
                <h3>
                  Get packing!
                </h3>
              </div>
              <p>
                Customise and assemble your own party packs. Select the price range, age and gender to get started.
              </p>
            </div>
          </div>
          <img alt="Doodle" className="doodle green-squiggle" src={greenSquiggle} />
          <img alt="Doodle" className="doodle star-dots" src={starDots} />
        </div>
        <div className="step step-reverse checkout-step">
          <div className="image-container">
            <div className="grey-container">
              <div className="image-background">
                <img alt="Cart" className="image" src={cart}></img>
                <img className="pointer" alt="Pointer" src={pointerCursor} />
              </div>
            </div>
          </div>
          <div className="text-container">
            <div className="text">
              <div className="title-container">
                <div className="step-number teal">
                  <span>2</span>
                </div>
                <h3>
                  Check out
                </h3>
              </div>
              <p>
                Once you’ve added all your awesome party essentials to your cart, head to checkout.
              </p>
            </div>
          </div>
          <img alt="Squiggle" className="doodle yellow-squiggle" src={yellowSquiggle} />
        </div>
        <div className="step get-order-step">
          <div className="image-container">
            <div className="grey-container">
              <img alt="Party Pack" className="image" src={partyPack}></img>
            </div>
          </div>
          <div className="text-container">
            <div className="text">
              <div className="title-container">
                <div className="step-number green">
                  <span>3</span>
                </div>
                <h3>
                  Receive your order
                </h3>
              </div>
              <p>
                Get your Party Packs delivered straight to your door within {cg_delivery_time} working days.
              </p>
            </div>
          </div>
          <img alt="Doodle" className="doodle star-dots-2" src={starDots2} />
        </div>
      </div>
    </div>
  );

}

function mapStateToProps(state){
  return {
    cg_delivery_time: state.meta_data.global_settings.cg_delivery_time,
  }
}


export default connect(mapStateToProps)(HowItWorks);
