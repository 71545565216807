import {
  SUBMITTING,
  SUBMIT_FAILURE,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_CANCEL_ORDER_SUCCESS,
  SUBMIT_GET_ORDERS_SUCCESS,
  SUBMIT_GET_ORDER_SUCCESS,
  SUBMIT_RETRY_PAYMENT_SUCCESS,
  SAVE_SPECIAL_INSTRUCTIONS
} from './types'

import { submitAuthorizedGet, submitAuthorizedPost } from 'redux/api.js'

const submitting = () => ({
  type: SUBMITTING
});

const submitFailure = error_message => ({
  type: SUBMIT_FAILURE,
  payload: {
    error_message
  }
});

export const submitGetOrdersRequest = () => async dispatch => {
  submitAuthorizedGet('/orders/', submitting, submitGetOrdersSuccess, submitFailure)
}

const submitGetOrdersSuccess = (data) => {
  return ({
    type: SUBMIT_GET_ORDERS_SUCCESS,
    payload: { data }
  });
}

export const submitGetOrderRequest = (order_id) => async dispatch => {
  return submitAuthorizedGet('/order/'+order_id+'/', submitting, submitGetOrderSuccess, submitFailure)
}

const submitGetOrderSuccess = (data) => {
  return ({
    type: SUBMIT_GET_ORDER_SUCCESS,
    payload: { data }
  });
}

export const submitOrder = (selected_courier, special_instructions) => async dispatch => {
    return submitAuthorizedPost('/orders/submit/', { "selected_courier": selected_courier, "special_instructions": special_instructions }, submitting, submitOrderSuccess, submitFailure)
}

const submitOrderSuccess = (data) => {
  return ({
    type: SUBMIT_ORDER_SUCCESS,
    payload: { data }
  });
}


export const submitCancelOrder = (order_id) => async dispatch => {
  return submitAuthorizedPost('/orders/cancel/', {"order_id": order_id}, submitting, submitCancelOrderSuccess, submitFailure)
}

const submitCancelOrderSuccess = (orders) => {
  return ({
    type: SUBMIT_CANCEL_ORDER_SUCCESS,
    payload: { orders }
  })
}

export const submitRetryPayment = (data) => async dispatch => {
  return submitAuthorizedPost('/orders/payment/', data, submitting, submitRetryPaymentSuccess, submitFailure)
}

const submitRetryPaymentSuccess = (data) => {
  const { order } = data
  return ({
    type: SUBMIT_RETRY_PAYMENT_SUCCESS,
    payload: { order }
  });
}

export const saveSpecialInstructions = (data) => {
  return ({
    type: SAVE_SPECIAL_INSTRUCTIONS,
    payload: data
  });
}
