import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { NOTIFICATIONS } from 'constants/notifications.js'
import { PROVINCES } from 'constants/provinces.js'
import { submitUserUpdate, submitFetchUser, submitPasswordUpdate, submitEmailUpdate } from 'redux/actions.js'
import { withRouter } from 'react-router-dom'

import LocationSearchInput from 'components/LocationSearchInput'

import loadingGif from "assets/images/loading.gif"
import './styles.scss'

class BasicInfo extends React.Component {


  static propTypes = {
    createNotification: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
    is_loading: PropTypes.bool,
  }

  state = {
    user: this.props.user,
    changing_email: false,
    new_email:"",
    reenter_email: "",
    changing_password: false,
    new_password: "",
    reenter_password: "",
  };

  componentDidMount(){
    this.props.submitFetchUser()
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }

  componentDidUpdate(){

  }

  handle_profile_update = (e, data) => {
    e.preventDefault();
    this.setState({ is_loading:true })
    this.props.submitUserUpdate(data).then(res=>{
      this.props.createNotification(NOTIFICATIONS.SUCCESS, "Your information was updated successfully.")
      this.props.history.push('/account/basic')
    }).catch(err => {
      this.props.createNotification(NOTIFICATIONS.ERROR, "Oh no, something went wrong.")
    })
    return
  }

  handle_password_update = () => {
    this.setState({ is_loading:true })
    const { new_password, reenter_password } = this.state
    if(new_password === reenter_password){
      const data = {
        password: new_password,
      }
      this.setState({new_password: "", reenter_password:"", changing_password:false})
      this.props.submitPasswordUpdate(data).then(res=>{
        this.props.createNotification(NOTIFICATIONS.SUCCESS, "Your password was updated successfully.")
      }).catch(err => {
        this.props.createNotification(NOTIFICATIONS.ERROR, "Oh no, something went wrong.")
      })
      return
    } else {
      this.props.createNotification(NOTIFICATIONS.ERROR, "Your passwords do not match")
    }
  }

  handle_email_update = () => {
    this.setState({ is_loading:true })
    const { new_email, reenter_email } = this.state
    if(new_email === reenter_email){
      const data = {
        email: new_email,
      }
      this.setState({new_password: "", reenter_password:"", changing_password:false})
      this.props.submitEmailUpdate(data).then(res=>{
        this.props.createNotification(NOTIFICATIONS.INFO, "We have sent a mail to " + this.state.user.email + " to confirm this action")
      }).catch(err => {
        this.props.createNotification(NOTIFICATIONS.ERROR, "Oh no, something went wrong.")
      })
      return
    } else {
      this.props.createNotification(NOTIFICATIONS.ERROR, "Your emails do not match")
    }
  }

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;

    const split = name.split('.')

    this.setState(prevstate => {
      const newState = { ...prevstate };
      if(split.length === 1) {
        newState.user[name] = value;
      } else if(split.length === 2) {
        newState.user[split[0]][split[1]] = value
      }
      return newState;
    });
  };

  handle_change_password = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState
    })
  }

  toggleChangePassword(state) {
    this.setState({changing_password: state})
  }

  toggleChangeEmail(state) {
    this.setState({changing_email: state})
  }

  autocompleteShippingAddress = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          shipping_address_1: address.streetAddress,
          shipping_address_2: address.suburb,
          shipping_city: address.city,
          shipping_province: address.province,
          shipping_postal_code: address.postalCode,
        }
      }
    })
  }

  handleShippingAddressChange = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          shipping_address_1: address,
        }
      }
    })
  }

  autocompleteBillingAddress = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          billing_address_1: address.streetAddress,
          billing_address_2: address.suburb,
          billing_city: address.city,
          billing_province: address.province,
          billing_postal_code: address.postalCode,
        }
      }
    })
  }

  handleBillingAddressChange = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          billing_address_1: address,
        }
      }
    })
  }

  render() {

    const { is_loading } = this.props
    const { user, changing_email, changing_password } = this.state

    return (
      <div className="basic-info">
        <div className="profile-container">
            <form className="profile-form" onSubmit={e => this.handle_profile_update(e, user)}>
              <div className="form-input">
                <div className="form-section">
                  <div className="address-heading">Basic Info</div>
                  { !changing_email ? (
                    <div className="form-control">
                      <label htmlFor="email">Email</label>
                      <div className="email">
                        <input
                          type="text"
                          name="email"
                          value={user.email}
                          onChange={this.handle_change}
                          required
                        />
                        <button className="button" onClick={ () => this.toggleChangeEmail(true)}>Change Email</button>
                      </div>
                    </div>
                  ) :
                  (
                    <React.Fragment>
                      <div className="form-control">
                        <label htmlFor="new_email">New Email Address</label>
                        <input
                          type="email"
                          name="new_email"
                          value={this.state.new_email}
                          onChange={this.handle_change_password}
                          required
                        />
                      </div>
                      <div className="form-control">
                        <label htmlFor="reenter_email">Re-enter Email Adress</label>
                        <input
                          type="email"
                          name="reenter_email"
                          value={this.state.reenter_email}
                          onChange={this.handle_change_password}
                          required
                        />
                      </div>
                      <div className="button-container">
                        <button type="button" className="button" onClick={ () => this.toggleChangeEmail(false)}>Cancel</button>
                        <button type="button" className="button" onClick={ () => this.handle_email_update()}>Change Email</button>
                      </div>
                    </React.Fragment>
                  )
                  }

                  <div className="form-control">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={user.first_name}
                      onChange={this.handle_change}
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={user.last_name}
                      onChange={this.handle_change}
                      required
                    />
                  </div>
                  { !changing_password ? (
                    <div className="form-control">
                      <label htmlFor="password">Password</label>
                      <div className="password">
                        <input
                          type="password"
                          name="password"
                          onChange={this.handle_change}
                          disabled
                        />
                        <button className="button" onClick={ () => this.toggleChangePassword(true)}>Change Password</button>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="form-control">
                        <label htmlFor="new_password">New Password</label>
                        <input
                          type="password"
                          name="new_password"
                          value={this.state.new_password}
                          onChange={this.handle_change_password}
                          required
                        />
                      </div>
                      <div className="form-control">
                        <label htmlFor="reenter_password">Re-enter Password</label>
                        <input
                          type="password"
                          name="reenter_password"
                          value={this.state.reenter_password}
                          onChange={this.handle_change_password}
                          required
                        />
                      </div>
                      <div className="button-container">
                        <button type="button" className="button" onClick={ () => this.toggleChangePassword(false)}>Cancel</button>
                        <button type="button" className="button" onClick={ () => this.handle_password_update()}>Change Password</button>
                      </div>

                    </React.Fragment>
                  )}
                  <div className="form-control">
                    <label htmlFor="cell_number">Cellphone Number</label>
                    <input
                      type="text"
                      name="profile.cell_number"
                      value={user.profile.cell_number}
                      onChange={this.handle_change}
                      required
                    />
                  </div>
                </div>
                <div className="form-section">
                  <div className="address-heading">Shipping Address</div>
                  <div className="form-control">
                    <label htmlFor="shipping_address_1">Address 1 (Street Address)</label>
                    <LocationSearchInput autocompleteCallback={this.autocompleteShippingAddress} handleChange={this.handleShippingAddressChange} address={user.profile.shipping_address_1} />
                    {/* <input
                      type="text"
                      name="profile.shipping_address_1"
                      value={user.profile.shipping_address_1}
                      onChange={this.handle_change}
                      required
                    /> */}
                  </div>
                  <div className="form-control">
                    <label htmlFor="shipping_address_2">Address 2 (Apartment, suite or space number)</label>
                    <input
                      type="text"
                      name="profile.shipping_address_2"
                      value={user.profile.shipping_address_2}
                      onChange={this.handle_change}
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="shipping_city">City</label>
                    <input
                      type="text"
                      name="profile.shipping_city"
                      value={user.profile.shipping_city}
                      onChange={this.handle_change}
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="shipping_province">Province</label>
                    <select
                      name="profile.shipping_province"
                      className="select"
                      onChange={this.handle_change}
                      value={user.profile.shipping_province}
                      required
                      >
                      { Object.keys(PROVINCES).map(key => {
                        return(
                          <option key={key} value={PROVINCES[key]}>{PROVINCES[key]}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="form-control">
                    <label htmlFor="shipping_country">Country</label>
                    <input
                      type="text"
                      name="profile.shipping_country"
                      value={user.profile.shipping_country}
                      onChange={this.handle_change}
                      disabled
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="shipping_postal_code">Postal Code</label>
                    <input
                      type="text"
                      name="profile.shipping_postal_code"
                      value={user.profile.shipping_postal_code}
                      onChange={this.handle_change}
                      required
                    />
                  </div>
                </div>
                <div className="form-section">
                  <div className="address-heading">Billing Address</div>
                  <div className="form-control">
                    <label htmlFor="billing_address_1">Address 1 (Street Address)</label>
                    <LocationSearchInput autocompleteCallback={this.autocompleteBillingAddress} handleChange={this.handleBillingAddressChange} address={user.profile.billing_address_1} />
                    {/* <input
                      type="text"
                      name="profile.billing_address_1"
                      value={user.profile.billing_address_1}
                      onChange={this.handle_change}
                      required
                    /> */}
                  </div>
                  <div className="form-control">
                    <label htmlFor="billing_address_2">Address 2 (Apartment, suite or space number)</label>
                    <input
                      type="text"
                      name="profile.billing_address_2"
                      value={user.profile.billing_address_2}
                      onChange={this.handle_change}
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="billing_city">City</label>
                    <input
                      type="text"
                      name="profile.billing_city"
                      value={user.profile.billing_city}
                      onChange={this.handle_change}
                      required
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="billing_province">Province</label>
                    <select
                      name="profile.billing_province"
                      className="select"
                      onChange={this.handle_change}
                      value={user.profile.billing_province}
                      >
                      { Object.keys(PROVINCES).map(key => {
                        return(
                          <option key={key} value={PROVINCES[key]}>{PROVINCES[key]}</option>
                        )
                      })}
                      required
                    </select>
                  </div>
                  <div className="form-control">
                    <label htmlFor="billing_country">Country</label>
                    <input
                      type="text"
                      name="profile.billing_country"
                      value={user.profile.billing_country}
                      onChange={this.handle_change}
                      required
                      disabled
                    />
                  </div>
                  <div className="form-control">
                    <label htmlFor="billing_postal_code">Postal Code</label>
                    <input
                      type="text"
                      name="profile.billing_postal_code"
                      value={user.profile.billing_postal_code}
                      onChange={this.handle_change}
                      required
                    />
                  </div>
                </div>
              </div>
              { is_loading && (
                <img className="loader" src={loadingGif} alt="Loader"/>
              )}
              <div className="form-submit">
                <input type="submit" />
              </div>
            </form>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth_data.user_data.user,
    is_loading:state.auth_data.is_loading,
  };
};

export default withRouter(connect(mapStateToProps, { submitUserUpdate, submitFetchUser, submitPasswordUpdate, submitEmailUpdate })(BasicInfo));
