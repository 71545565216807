import React from 'react'

import PropTypes from 'prop-types'

import Collapsible from 'react-collapsible'

import './styles.scss'

class FAQCollapsible extends React.Component{

    static props = {
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired,
    }

    state = {
        expanded: false,
    }

    render(){
        const { question, answer } = this.props

        return(
            <div className="faq-collapsible">
                <div className="question" onClick={ () => this.setState({ expanded: !this.state.expanded })}>
                    { question }
                </div>
                <Collapsible
                    open={ this.state.expanded }
                    transitionTime={350}
                    easing="cubic-bezier(.25,.1,.25,1)"
                >
                    <div className="answer">
                        { answer }
                    </div>
                </Collapsible>
            </div>

        )
    }
}

export default FAQCollapsible