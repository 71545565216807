import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';
import { connect } from 'react-redux'

import dashIcon from 'assets/images/minus-icon.svg'
import dropdownIcon from "assets/images/dropdown-icon.svg"

import 'react-multi-carousel/lib/styles.css';
import 'rc-slider/assets/index.css';
import './styles.scss'

class Price extends React.Component {
  static propTypes = {
    toggled: PropTypes.bool.isRequired,
    filters: PropTypes.shape({}).isRequired,
    contentOnly: PropTypes.bool,
    updateFilters: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contentOnly: false
  }

  handle_slider_change = new_value => {
    let filters = Object.assign({}, this.props.filters)
    filters.price_range = new_value
    this.props.updateFilters(filters)
  }

  handle_input_change = (input, event) => {
    let num = +event.target.value
    if(!isNaN(num)){
      if(num > this.getMaxAmount()){
        num = this.getMaxAmount()
      }
      let filters = Object.assign({}, this.props.filters)
      if(input === 'max') {
        filters.price_range[1] = num
      } else if(input === 'min'){
        filters.price_range[0] = num
      }

      this.props.updateFilters(filters)
    }
  }

  handleQuantityInputChange = (event) => {
    let num = +event.target.value
    if(!isNaN(num)){
      this.setState({ quantity: num })
    }
  }

  getMaxAmount() {
    const max = 400
    return max
  }

  renderContent(filters) {
   


    return(
      <div className="price-range">
        <div className="range-container">
            <Range
            className="range"
            onChange={this.handle_slider_change}
            defaultValue={filters.price_range}
            min={0}
            max={this.getMaxAmount()}
            step={5}
            handleStyle={[{backgroundColor:'#339966', width:'22px', height:'22px', marginTop:'-9px'} , {backgroundColor:'#339966', width:'22px', height:'22px', marginTop:'-9px'}]}
            trackStyle={[{backgroundColor:'#339966', height:'5px' }]}
            railStyle={{backgroundColor:'#878787', height:'5px' }}
            />
        </div>
        <div className="inputs">
            <div className="input-container input-container-min">
                <div className="input-label">Min price</div>
                {/* <input onChange={(event) => this.handle_input_change('min', event)} value={ filters.price_range[0] } /> */}
                <div className="value"><b>R{ filters.price_range[0] }</b></div>
            </div>
            <div className="dash">
                <img src={dashIcon} />
            </div>
            <div className="input-container input-container-max">
                <div className="input-label">Max price</div>
                {/* <input onChange={(event) => this.handle_input_change('max', event)} value={ filters.price_range[1] } /> */}
                <div className="value"><b>R{ filters.price_range[1] }</b></div>
            </div>
        </div>
      </div>
    )
  }

  renderContentDropdown(filters) {
    return(
      <div className="filter-content">
        { this.renderContent(filters) }
      </div>
    )
  }

  render() {

    const { toggled, filters, toggleFilter, contentOnly } = this.props
    const toggledStyle= toggled ? {transform:'rotate(180deg)', paddingRight:'10px', paddingLeft:'0px'} : {}

    const highlighted = ( filters.price_range[0] !== 0 ) || (filters.price_range[1] !== this.getMaxAmount())

    return (
      contentOnly ? (
        this.renderContent(filters)
      )
      :
      (
        <div className='price-filter'>
          <div className={`filter-heading ${highlighted ? 'active' : ''}`} onClick={ () => toggleFilter('price') }>
            Price Range
            <img src={ dropdownIcon } style={toggledStyle} />
            { highlighted && (
              <div className="filter-number">
                <div className="text">
                  1
                </div>
              </div>
            )}
          </div>
          { toggled && (
            this.renderContentDropdown(filters)
          )}
        </div>
      )

    )
  }
}

export default withRouter(connect(null, { })(Price));
