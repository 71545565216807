import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { DELIVERY_STATUS_MAP } from 'containers/Account/constants.js'
import { submitGetOrdersRequest, addIdAccount, removeIdAccount, submitRetryPayment } from 'redux/actions.js'
import { withRouter } from 'react-router-dom'
import dropdownIcon from 'assets/images/dropdown-icon.svg'
import { COURIER_GUY_STATUS_MAP } from 'constants/courier_guy.js'
import OrderedPack from './OrderedPack'

import AccountPageWrapper from '../AccountPageWrapper'
import AccountHeading from 'components/AccountHeading'
import './styles.scss'

class OrderDetail extends React.Component {
  static propTypes = {
    order_id: PropTypes.string.isRequired,
  }

  componentDidMount() {
    this.props.submitGetOrdersRequest()
  }

  handle_payment(id){
    const data = {
      order_id: id,
    }
    this.props.submitRetryPayment(data).then(res => {
      window.location.replace(res.redirect_url);
    })
  }


  handleCancelOrder(order_id){
    if(window.confirm("Are you sure you want to cancel this order?")){
      this.props.submitCancelOrder(order_id)
    }
  }

  togglePackCollapse(party_id) {
    const { collapse } = this.props

    if(collapse.includes(party_id)){
      this.props.removeIdAccount(party_id)
    } else {
      this.props.addIdAccount(party_id)
    }
  }

  isCollapsed(party_id) {
    const { collapse } = this.props
    if(collapse.includes(party_id)){
      return true
    }
    return false
  }


  render() {
    const { orders, order_id } = this.props
    const order = orders.find(o => o.id === parseInt(order_id))
    const retry_payment_array = [ 'succeeded', 'successful' ]    

    return (
      <React.Fragment>
      { order !== undefined ?
      (
        <React.Fragment>

        <AccountPageWrapper>

          <div className="order-detail">
            <div className="order-detail-header">
              <div className="title">
                <img alt="Arrow" className="back-arrow" src={dropdownIcon} />
                <div className="text">Order #{order.id} ({order.status_string})</div>
              </div>
              <div className="button-container">
                { !retry_payment_array.includes(order.payment_status.toLowerCase()) && (
                    <React.Fragment>
                      <button className="pay-button" onClick={() => this.handle_payment(order.id)}>Pay Now</button>
                    </React.Fragment>
                ) }
              </div>
            </div>
            {/* <div className="delivery-status">{order.status_string}</div> */}
            { order.courier_guy_shipment && (
              <div className="delivery-status">Delivery Status: {COURIER_GUY_STATUS_MAP[order.courier_guy_shipment.status]}</div>
            )}
            <div className="created-at">Order date: { order.created_at }</div>

            { order.snapshot !== null ?
              (
                <div className="party-packs">
                  { order.snapshot.party_packs.map((party_pack, index) => {
                    return(
                      <OrderedPack key={ party_pack.id } party_pack={party_pack} />
                    )
                  })}
                </div>
              )
              :
              (
                <div className="missing-snapshot">
                  Unable to retrieve Party Pack items history for this order.
                </div>
              )
            }


            <div className="order-breakdown">
              <div className="fee">
                <div className="label">Total</div>
                <div className="value">R{(parseFloat(order.amount_due) - parseFloat(order.transport_cost ? order.transport_cost: 0)).toFixed(2)}</div>
              </div>
              <div className="fee">
                <div className="label">Shipping</div>
                <div className="value">R{order.transport_cost ? (order.transport_cost).toFixed(2) : (0).toFixed(2)}</div>
              </div>
              <div className="fee">
                <div className="label">Vouchers</div>
                <div className="value">R{order.voucher_amount ? (order.voucher_amount).toFixed(2) : (0).toFixed(2)}</div>
              </div>
              <div className="fee">
                <div className="label total-label">Total amount paid</div>
                <div className="value total-value">R{((parseFloat(order.amount_paid).toFixed(2)) - (order.voucher_amount ? (order.voucher_amount).toFixed(2) : (0).toFixed(2))).toFixed(2)}</div>
              </div>
              <div className="fee">
                <div className="label">VAT</div>
                <div className="value">R{(order.amount_due - order.amount_due/1.15).toFixed(2)}</div>
              </div>
            </div>
          </div>
        </AccountPageWrapper>
          <div className="spacer" />
          <AccountPageWrapper heading={"Order Information"}>
            { order.snapshot.user ? (
              <div className="order-info-container">
                <div className="column">
                  <h5>Shipping Address</h5>
                  <div className="address">
                    <p>{order.snapshot.user.profile.shipping_address_1}</p>
                    <p>{order.snapshot.user.profile.shipping_address_2}</p>
                    <p>{order.snapshot.user.profile.shipping_city}</p>
                    <p>{order.snapshot.user.profile.shipping_country}</p>
                    <p>{order.snapshot.user.profile.shipping_postal_code}</p>
                    <p>{order.snapshot.user.profile.shipping_province}</p>
                  </div>
                </div>
                <div className="column">
                  <h5>Billing Address</h5>
                  <div className="address">
                    <p>{order.snapshot.user.profile.billing_address_1}</p>
                    <p>{order.snapshot.user.profile.billing_address_2}</p>
                    <p>{order.snapshot.user.profile.billing_city}</p>
                    <p>{order.snapshot.user.profile.billing_country}</p>
                    <p>{order.snapshot.user.profile.billing_postal_code}</p>
                    <p>{order.snapshot.user.profile.billing_province}</p>
                  </div>
                </div>
                <div className="column">
                  {/* <h5>Payment Method</h5>
                  <div className="address">
                    <p>Yoco</p>
                  </div> */}
                </div>
              </div>
            )
            :(
              <div className="order-info-container">
                <h5>Order information is unavailable for this order.</h5>
              </div>
            )}
            

          </AccountPageWrapper>
        </React.Fragment>
      )
      :
      (
        <AccountPageWrapper>
          <div className="order-detail">
            <div className="order-detail-header">
              <div className="title">
                <div className="text">This order was not found.</div>
              </div>
             </div>
          </div> 
        </AccountPageWrapper>
      )
      }
      
      </React.Fragment>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    collapse: state.collapse_data.collapse_account,
    orders: state.order_data.orders
  };
};


export default withRouter(connect(mapStateToProps, { submitGetOrdersRequest, addIdAccount, removeIdAccount, submitRetryPayment })(OrderDetail));
