
import './styles.scss'
import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { submitLogout, submitClearLocalCart } from 'redux/actions.js'

import CartComponent from 'components/CartComponent'
import SidePanel from 'components/SidePanel'

import profileIcon from "assets/images/account-icon.svg"
import cartIcon from "assets/images/cart-icon.svg"
import partyPackersLogo from "assets/images/party-packers-logo.svg"
import hamburgerMenu from "assets/images/hamburger-menu.svg"

import { getTotal, getTotalWithoutVoucher } from 'utils/cart'


class Header extends React.Component {

  state = {
    width:window.innerWidth,
    cart_open: null,
    menu_open: null,
    account_open: false,
  }

  static propTypes = {
    logged_in: PropTypes.bool.isRequired,
    onSubmitLogout: PropTypes.func,
    cart: PropTypes.shape({})
  }

  getNumberOfPartyPacks(){
    const { cart } = this.props
    try{
      return cart.party_packs.length
    } catch {
      return 0
    }
  }


  getTotal(){
    const { cart } = this.props
    return getTotalWithoutVoucher(cart).toFixed(2)
  }

  updateWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  handleSubmitLogout() {
    this.props.onSubmitLogout()
    this.props.history.push('/')
    this.setState({account_open: false})
  }
  render() {

    const { logged_in } = this.props

    const { width, cart_open, menu_open, account_open } = this.state


    const nav_list = (
      <ul className="auth-list">
        { account_open && (
          <div className="underlay" onClick={() => this.setState({account_open: false})} />
        )}
        <React.Fragment>

          <li className="nav-link account-icon-container">
            <img alt="Account" className="account-icon" src={profileIcon} onClick={() => this.setState({account_open: !account_open})} />
            <div className={`account-menu ${account_open ? 'account-open' : ''}`}>
              { logged_in ? (
                <div className="item-list">
                  <Link onClick={() => this.setState({account_open: false})} to={'/account/basic'} className="item my-account">My Account</Link>
                  <div className="item logout" onClick={ () => this.handleSubmitLogout() }>Logout</div>
                </div>
              )
              :
              (
                <div className="item-list">
                  <Link onClick={() => this.setState({account_open: false})} className="item signup" to={'/signup'}>Register</Link>
                  <Link onClick={() => this.setState({account_open: false})} className="item login" to={'/login'}>Login</Link>
                </div>
              )
              }
            </div>
          </li>
        </React.Fragment>
        <li className="nav-link">
          <div className="cart-button" onClick={() => this.setState({cart_open: !cart_open})} > {/*   */}
            <div className="cart-container">
              <img className="cart-icon" src={cartIcon} alt="Cart" />
              { this.getNumberOfPartyPacks() > 0 && (
                <React.Fragment>
                <div className="circle">
                  <div className="pack-amount">
                    {this.getNumberOfPartyPacks()}
                  </div>
                </div>
                <div className="total">
                  R{this.getTotal()}
                </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </li>
      </ul>
    )

    const mobile_nav = (
      
      <React.Fragment>
        <div className="hamburger-menu">
        <img alt="Burger Menu" onClick={() => this.setState({menu_open: !menu_open}) } className="hamburger" src={hamburgerMenu} />
        <SidePanel cartOpen={menu_open} closeSidePanel={() => this.setState({menu_open: !menu_open})}/>
      </div>
          <div className="cart-button" onClick={() => this.setState({cart_open: !cart_open})} > {/*   */}
            <div className="cart-container">
              <img className="cart-icon" src={cartIcon} alt="Cart" />
              { this.getNumberOfPartyPacks() > 0 && (
                <div className="circle">
                  <div className="pack-amount">
                    {this.getNumberOfPartyPacks()}
                  </div>
                </div>
              )}
            </div>
          </div>
      </React.Fragment>
      
    )
    return (
      <div className="header-component">
        <div>{/*  style={{visibility: `${ cart_open ? 'visible': 'hidden'}` }} */}
          <CartComponent cartOpen={cart_open} closeCart={() => this.setState({cart_open: false})}/>
        </div>
        <div className="navbar">
          <div className="logo">
            <Link to={'/'}><img src={partyPackersLogo} alt="Home"/></Link>
          </div>
          { width > 840 && (
            <div className="desktop-view">
              <ul className="nav-links">
                <li><Link className="nav-link" to={'/pick'}><h5 className="pick-pack-link">Pick a Pack</h5></Link></li>
                {/* <li><Link className="nav-link" to={'/build'}><h5 className="build-pack-link">Build a Pack</h5></Link></li> */}
                <li><Link className="nav-link" to={'/about'}><h5 className="about-link">About Us</h5></Link></li>
                <li><Link className="nav-link" to={'/contact'}><h5 className="contact-link">Contact Us</h5></Link></li>
                <li><Link className="nav-link" to={'/faq'}><h5 className="contact-link">FAQs</h5></Link></li>
              </ul>
              { nav_list }
            </div>
          )}
          { width <= 840 && (
            <div className="mobile-view">
            { mobile_nav }
            </div>
          )}

        </div>
      </div>
    );
  }




}

const mapStateToProps = state => {
  return {
    cart: state.party_data.cart,
    logged_in: state.auth_data.logged_in,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmitLogout: () => {
      dispatch(submitLogout())
      dispatch(submitClearLocalCart())
    },
  };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
