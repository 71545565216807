import { SUBMIT_SIGNUP, SUBMIT_SIGNUP_SUCCESS, SUBMIT_SIGNUP_FAILURE } from './types.js'
import { SUBMIT_LOGIN } from '../auth/types.js'
import { SUBMIT_LOGIN_SUCCESS, SUBMIT_LOGIN_FAILURE} from 'redux/auth/types.js'
import { SUBMIT_MERGE_CART, SUBMIT_MERGE_CART_SUCCESS, SUBMIT_MERGE_CART_FAILURE } from 'redux/parties/types.js'

import { submitAuthorizedPost, submitUnauthorizedPost } from 'redux/api.js'

import axios from 'axios'
import store from 'redux/store'




export const submitSignup = signup_data => async () =>  {
  return submitUnauthorizedPost('/users/', signup_data, submittingSignup, submitSignupSuccess, submitSignupFailure)

  // signupPromise
  //   .then((res) => {
  //     const login_data = {username: signup_data.email, password: signup_data.password}
  //     const loginPromise = submitUnauthorizedPost('/token-auth/',login_data, submittingLogin, submitLoginSuccess, submitLoginFailure)
      
  //     loginPromise.then(() => {
  //       const cart_data = store.getState().party_data.cart
  //       return submitAuthorizedPost('/cart/merge', cart_data, submittingMergeCart, submitMergeCartSuccess, submitMergeCartFailure)
  //     })
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //     console.log(signupPromise)
  //     return signupPromise
  //   })
}


// function unauthorizedPost(url, data){
//   return axios.post(process.env.REACT_APP_API_URL+"/users/", signup_data)
// }


const submittingMergeCart = () => ({
  type: SUBMIT_MERGE_CART
});

const submitMergeCartSuccess = parties => ({
  type: SUBMIT_MERGE_CART_SUCCESS,
  payload: {
    parties
  }
});


const submitMergeCartFailure = error => ({
  type: SUBMIT_MERGE_CART_FAILURE,
  payload: {
    error
  }
});



const submitLoginSuccess = user => {
  return ({
    type: SUBMIT_LOGIN_SUCCESS,
    payload: {
      user
    }
  });
}

const submittingLogin = () => ({
  type: SUBMIT_LOGIN
});

const submitLoginFailure = error => ({
  type: SUBMIT_LOGIN_FAILURE,
  payload: {
    error
  }
});

const submitSignupSuccess = user => ({
  type: SUBMIT_SIGNUP_SUCCESS,
  payload: {
    user
  }
});

const submittingSignup = () => ({
  type: SUBMIT_SIGNUP
});

const submitSignupFailure = error => ({
  type: SUBMIT_SIGNUP_FAILURE,
  payload: {
    error
  }
});
