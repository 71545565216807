import React, { useEffect, Fragment } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

function ScrollToTop({ history }) {
  const { pathname } = useLocation()


  React.useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth' })
  }, [pathname])

  return null;
}

export default withRouter(ScrollToTop);