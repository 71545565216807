import React from 'react';
import Personal from './Personal'
import Orders from './Orders'
import OrderDetail from './OrderDetail'
import profileIcon from "assets/images/account-icon.svg"
import cartIcon from "assets/images/cart-icon.svg"
import {Helmet} from 'react-helmet'
import {
  Switch,
  Route,
  Link,
  useParams,
  withRouter,
  useLocation
} from "react-router-dom";

import './styles.scss'

function Account () {
  let { pathname: url } = useLocation()
  const { id } = useParams();
  return(
      <div className="account">
      <Helmet>
        <title>Account</title>
        <meta name="description" content="Manage your Party Packer account details as well as view and track your orders." />
        <meta name="keywords" content="party packers account, settings, orders, order history, change password, update information" />
      </Helmet>
        <div className="heading">
          <div className="title">My Account</div>
        </div>
        <div className="container">
          <div className="nav-container">
            <div className="navbar">
              <div className="navlist">
                <Link to="/account/basic" className={url==='/account/basic' ? 'navlink active':'navlink'}><div className="image-container"><img alt="Profile" src={profileIcon} /></div>My details</Link>
                <Link to="/account/orders" className={url==='/account/orders' ? 'navlink active':'navlink'}><div className="image-container"><img alt="Orders" src={cartIcon} /></div>Orders</Link>
              </div>
            </div>
          </div>
          <div className="account-container">
            <Switch>
              <Route exact path={`/account/basic`}>
                <Personal />
              </Route>
              <Route exact path={`/account/orders`}>
                <Orders />
              </Route>
              <Route path={`/account/orders/:id?`}>
                <OrderDetail order_id={id} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
  )
}


export default withRouter(Account);
