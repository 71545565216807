import React from 'react'

import './styles.scss'

function Input({placeholder, value, type, name, required, disabled, handle_change}) {
    return (
        <div className="input-component">
            <input type={ type } name={ name } value={ value } onChange={handle_change} required={required} disabled={disabled} />
            <label htmlFor={ name }>
                <div className="text">{ placeholder }<div className="label-underline" /></div>
            </label>
        </div>

    )
}

export default Input