import React from 'react'
import './styles.scss'
import FullPageHeader from 'components/FullPageHeader'

import {Helmet} from 'react-helmet'

function Shipping() {
  return (
    <div className="shipping">
      <Helmet>
        <title>Shipping</title>
        <meta name="description" content="Shipping" />
      </Helmet>
      <FullPageHeader headingText={"Shipping"} />
      <div className="container">
        <div className="text">
          <div className="section">
          <p>We will endeavor to deliver the ordered products to your designated address within 5 working days of the confirmation of your order. In the event that the delivery is delayed we will advise you accordingly. Please note that the risk of damage to the goods will pass to you upon delivery.</p>
          <br/>
          <p>Delivery options will be made available to you at the checkout. To avoid any delays or potential issues, please make sure that you enter the correct delivery address details.</p>
          <br/>
          <p>Our standard delivery time is approximately 5 working days, however, you may select a different option (if applicable). Once your order has been placed, you will receive a Confirmation email that will include your order details, and the estimated delivery date. </p>
          <br/>
          <p>You can also track your delivery at any time by selecting the Track My Order option on your Account profile. This is the most current tracking information we have for your order. If this date changes, we’ll let you know straight away by email or SMS.</p>
          </div>
          
        </div>
      </div>
    </div>
  );
}
export default Shipping;
