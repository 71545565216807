import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { submitRetryPayment, submitPasswordUpdate, submitUserUpdate } from 'redux/actions.js'
import { Link, withRouter } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
import LocationSearchInput from 'components/LocationSearchInput'
import { PROVINCES } from 'constants/provinces.js'

import Input from 'components/Input'
import OrdersTable from 'components/OrdersTable'

import AccountHeading from 'components/AccountHeading'
import AccountPageWrapper from '../AccountPageWrapper';
import './styles.scss'

class Personal extends React.Component {


  static propTypes = {
    user: PropTypes.shape({}).isRequired,
  }

  state = {
    user: this.props.user,
    error_message: '',
    changing_personal: false,
    changing_password: false,
    current_password: '',
    new_password: '',
    confirm_password: '',
  }

  submit_password_change() {
    const { current_password, new_password, confirm_password } = this.state

    if(new_password.length < 8) {
      NotificationManager.warning('Your password needs to be at least 8 characters long.', 'Error...', 3000);
      return
    }

    if(new_password !== confirm_password){
      NotificationManager.warning('Your password do not match.', 'Error...', 3000);
      return
    }

    this.props.submitPasswordUpdate({
      current_password,
      new_password,
      confirm_password
    }).then(res => {
      NotificationManager.success('Your password was updated successfully.', 'Success!', 2000);
      this.setState({changing_password:false})
    }).catch(err => {
      NotificationManager.error(err, 'Oh no!', 2000);
    })
  }

  submit_personal_change() {
    const { user } = this.state

    this.props.submitUserUpdate(user).then(res=>{
      NotificationManager.success('Your details have been updated successfully.', 'Success!', 2000);
      this.setState({changing_personal: false})
    }).catch(err => {
      NotificationManager.error(err, 'Oh no!', 2000);
    })
  }

  handle_user_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    const split = name.split('.')

    this.setState(prevstate => {
      const newState = { ...prevstate };
      if(split.length === 1) {
        newState.user[name] = value;
      } else if(split.length === 2) {
        newState.user[split[0]][split[1]] = value
      }
      return newState;
    });
  };

  handle_password_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
    this.setState({ error_message:'' })
  };

  autocompleteShippingAddress = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          shipping_address_1: address.streetAddress,
          shipping_address_2: address.suburb,
          shipping_city: address.city,
          shipping_province: address.province,
          shipping_postal_code: address.postalCode,
        }
      }
    })
  }

  handleShippingAddressChange = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          shipping_address_1: address,
        }
      }
    })
  }

  autocompleteBillingAddress = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          billing_address_1: address.streetAddress,
          billing_address_2: address.suburb,
          billing_city: address.city,
          billing_province: address.province,
          billing_postal_code: address.postalCode,
        }
      }
    })
  }

  handleBillingAddressChange = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          billing_address_1: address,
        }
      }
    })
  }

  toggle_password_change() {
    this.setState({ changing_password : !this.state.changing_password })
  }

  toggle_personal_change() {
    this.setState({ changing_personal : !this.state.changing_personal })
  }

  render() {
    const { user, changing_personal, changing_password, current_password, new_password, confirm_password} = this.state

    return (
      <AccountPageWrapper heading='My Details'>
        <div className="personal">
          <AccountHeading text="Personal Information" link_text={changing_personal ? 'Cancel' : 'Edit'} link_to='#' handle_on_click={() =>this.toggle_personal_change()}/>
          <div className="field-section">
            <div className="form-control">
              <Input
                type="text"
                name="email"
                value={user.email}
                handle_change={this.handle_user_change}
                required
                disabled
                placeholder="Email address"
              />
            </div>
            <div className="form-control">
              <Input
                type="text"
                name="first_name"
                value={user.first_name}
                handle_change={this.handle_user_change}
                required={true}
                placeholder='First name*'
                disabled={!changing_personal}
              />
            </div>
            <div className="form-control">
              <Input
                type="text"
                name="last_name"
                value={user.last_name}
                handle_change={this.handle_user_change}
                required
                placeholder='Last name*'
                disabled={!changing_personal}
              />
            </div>
            <div className="form-control">
                <Input
                  type="text"
                  name="profile.cell_number"
                  value={user.profile.cell_number}
                  handle_change={this.handle_user_change}
                  required
                  placeholder="Cellphone number*"
                  disabled={!changing_personal}
                />
            </div>
            <div className="form-control">
              <LocationSearchInput
                label="Address 1 (Street Address)" 
                autocompleteCallback={this.autocompleteShippingAddress}
                handleChange={this.handleShippingAddressChange}
                address={user.profile.shipping_address_1}
                disabled={!changing_personal}
              />
            </div>
            <div className="form-control">
              <Input
                type="text"
                name="profile.shipping_address_2"
                value={user.profile.shipping_address_2}
                required
                placeholder="Address 2 (Apartment, suite or space number)"
                handle_change={this.handle_user_change}
                disabled={!changing_personal}
              />
            </div>
            <div className="form-control">
              <Input
                type="text"
                name="profile.shipping_city"
                value={user.profile.shipping_city}
                handle_change={this.handle_user_change}
                placeholder="City"
                required
                disabled={!changing_personal}
              />
            </div>
            <div className="form-control">
              <select
                name="profile.shipping_province"
                className="select"
                onChange={this.handle_user_change}
                value={user.profile.shipping_province}
                placeholder="Province"
                required
                disabled={!changing_personal}
                >
                { Object.keys(PROVINCES).map(key => {
                  return(
                    <option key={key} value={PROVINCES[key]}>{PROVINCES[key]}</option>
                  )
                })}
              </select>
            </div>
            <div className="form-control">
              <Input
                type="text"
                name="profile.shipping_country"
                value={user.profile.shipping_country}
                handle_change={this.handle_user_change}
                placeholder="Country"
                disabled
                required
              />
            </div>
            <div className="form-control">
              <Input
                type="text"
                name="profile.shipping_postal_code"
                value={user.profile.shipping_postal_code}
                handle_change={this.handle_user_change}
                placeholder="Postal Code"
                required
                disabled={!changing_personal}
              />
            </div>
          </div>
          { changing_personal && (
            <div className="submit-button" onClick={() => this.submit_personal_change()}>
              Save
            </div>
          )}

          <AccountHeading text="Password" link_text={ changing_password ? 'Cancel' : 'Change Password' } link_to="#" handle_on_click={() =>this.toggle_password_change()} />
          <div className="field-section password-field-section">
            <div className="form-control">
              <Input
                type="password"
                name="current_password"
                value={changing_password ? current_password : 'random_password'}
                required
                disabled = { !changing_password }
                placeholder={changing_password ? "Current password*" : "Password"}
                handle_change={this.handle_password_change}
              />
            </div>
            { changing_password && (
              <>
                <div className="form-control">
                  <Input
                    type="password"
                    name="new_password"
                    value={ new_password }
                    required
                    placeholder="New password*"
                    handle_change={this.handle_password_change}
                  />
                </div>
                <div className="form-control">
                  <Input
                    type="password"
                    name="confirm_password"
                    value={confirm_password}
                    required
                    placeholder="Confirm password*"
                    handle_change={this.handle_password_change}
                  />
                </div>
                <div className="submit-button" onClick={() => this.submit_password_change()}>
                  Save
                </div>
              </>
            )}

          </div>

          <div className="heading-container">
            <div className="title">Recent Orders</div>
            <div className="edit-link-container">
              <Link className="edit-link" to={'/account/orders'}>
                <u>View All</u>
              </Link>
            </div>
          </div>

          <OrdersTable />

        </div>
        </AccountPageWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth_data.user_data.user,
    is_loading:state.auth_data.is_loading,
    orders: state.order_data.orders
  };
};

export default withRouter(connect(mapStateToProps, { submitRetryPayment, submitPasswordUpdate, submitUserUpdate})(Personal));
