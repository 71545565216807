import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './styles.scss'
import CartSummary from 'components/CartSummary'
import Checkout from 'containers/CreatePack/Cart/CartPayment/Checkout'
import Page from 'containers/CreatePack/Cart/Page'

class CartPayment extends React.Component {

  static propTypes = {
    cart: PropTypes.shape({}).isRequired,
    logged_in: PropTypes.bool,
    createNotification: PropTypes.func,
  }

  static defaultProps = {
    cart: {
      party_packs:[],
    }
  }

  state = {
    cart: {
      party_packs: [],
    },
  }


  render() {
    return (
      <div className="cart-payment">
        <div className="container">
          <Page
            cartSummary={<CartSummary mobileEnabled displayItems={this.props.history.location.pathname !== '/cart/payment'} />}
            component={<Checkout/>}
          />
        </div> 
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    cart: state.party_data.cart,
    logged_in: state.auth_data.logged_in,
  };
};
export default withRouter(connect(mapStateToProps, { })(CartPayment));
