import React from 'react'
import ReactPixel from 'react-facebook-pixel';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import './styles.scss'

import { submitOrder, refreshCart, submitGetOrdersRequest } from 'redux/actions'

import { NotificationManager } from 'react-notifications';

import Loader from 'components/Loader'

class YocoPayment extends React.Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
  }

  state = {
    loading: false,
  }

  handleSubmitOrder(){
      const { cart  } = this.props

      if(this.props.total < this.props.minimum_order_total) {
          NotificationManager.warning(`Your order total needs to be above R${this.props.minimum_order_total}.`, '', 3000);
          return
      }

      const selected_courier = "courier_guy"
      const special_instructions = this.props.special_instructions

      this.setState({loading: true})
      this.props.submitOrder(selected_courier, special_instructions).then(res => {
          window.location.replace(res.redirect_url);
      }).catch(err => {
         console.log(err)
         NotificationManager.warning(err, 'Error...', 3000)
         this.setState({loading: false})
      })
  }

  // handleSubmitOrder(yoco_token){

  //   const { cart } = this.props

  //   if(this.props.total < this.props.minimum_order_total) {
  //     NotificationManager.warning(`Your order total needs to be above R${this.props.minimum_order_total}.`, '', 3000);
  //     return
  //   }

  //   const selected_courier = "courier_guy"
  //   const special_instructions = this.props.special_instructions

  //   this.setState({loading: true})
  //   this.props.submitOrder(selected_courier, special_instructions, yoco_token).then(res => {
  //     // Submit Order Complete Facebook Pixel Event
  //     let total = 0
  //     let count = 0
  //     cart.party_packs.forEach(party_pack => {
  //       let ptotal = 0
  //       let pcount = 0
  //       party_pack.items.forEach(item => {
  //         ptotal += item.price
  //         pcount += 1
  //       })
  //       ptotal = ptotal * party_pack.amount
  //       pcount = pcount * party_pack.amount

  //       total += ptotal
  //       count += pcount
  //     })
  
  //     ReactPixel.trackCustom('Order Complete', {number: 1, value: total, currency: 'ZAR'})

  //     this.props.refreshCart()
  //     this.setState({loading: false})

  //     NotificationManager.success("Payment was successful.", 'Success!', 3000)
  //     this.props.submitGetOrdersRequest()
  //     this.props.history.push(`/order/resolve/${res.order.id}`)
  //   }).catch(err => {
  //     console.log(err)
  //     NotificationManager.warning(err, 'Error...', 3000)
  //     this.setState({loading: false})
  //   })
// }

  // handleSubmitOrderBelowZero(){

  //   const selected_courier = "courier_guy"
  //   const special_instructions = this.props.special_instructions
  //   this.setState({loading: true})
  //   this.props.submitOrder(selected_courier, special_instructions, "").then(res => {
  //     this.props.refreshCart()
  //     this.setState({loading: false})
  //     NotificationManager.success("Payment was successful.", 'Success!', 3000)
  //     this.props.submitGetOrdersRequest()
  //     this.props.history.push(`/order/resolve/${res.order.id}`)
  //   }).catch(err => {
  //     console.log(err)
  //     NotificationManager.warning(err, 'Error...', 3000)
  //     this.setState({loading: false})
  //   })
  // }


  componentDidMount(){
    
    console.log("YOCO KEY: ", process.env.REACT_APP_YOCO_PUBLIC_KEY)

    const { total, cart } = this.props
    
    const amountInCents = total*100
    // let that = this
    // var sdk = new window.YocoSDK({
    //   publicKey: process.env.REACT_APP_YOCO_PUBLIC_KEY
    // });

    // Create a new dropin form instance
    // var inline = sdk.inline({
    //   layout: 'basic',
    //   amountInCents: total*100,
    //   currency: 'ZAR'
    // });

    // this ID matches the id of the element we created earlier.
    // inline.mount('#card-frame');

    // var form = document.getElementById('payment-form');
    // var submitButton = document.getElementById('pay-button');
    // form.addEventListener('submit', function (event) {
    //   event.preventDefault()

    //   // Submit Initiate Payment Facebook Pixel Event
    //   let total = 0
    //   let count = 0
    //   cart.party_packs.forEach(party_pack => {
    //     let ptotal = 0
    //     let pcount = 0
    //     party_pack.items.forEach(item => {
    //       ptotal += item.price
    //       pcount += 1
    //     })
    //     ptotal = ptotal * party_pack.amount
    //     pcount = pcount * party_pack.amount

    //     total += ptotal
    //     count += pcount
    //   })
  
    //   ReactPixel.trackCustom('Initiate Payment', {number: 1, value: total, currency: 'ZAR'})

    //   // Disable the button to prevent multiple clicks while processing
    //   that.setState({loading: true})
    //   submitButton.disabled = true;
    //   // This is the inline object we created earlier with the sdk
    //   inline.createToken().then(function (result) {
    //     // Re-enable button now that request is complete
    //     // (i.e. on success, on error and when auth is cancelled)
    //     that.setState({loading: false})
    //     submitButton.disabled = false;
    //     if (result.error) {
    //       const errorMessage = result.error.message;
    //       // errorMessage && alert("error occured: " + errorMessage);
    //       errorMessage && NotificationManager.warning(errorMessage, 'Error...', 3000)
    //     } else {
    //       const token = result;
    //       // alert("card successfully tokenised: " + token.id);
          
    //       that.handleSubmitOrder(token.id)
    //     }
    //   }).catch(function (error) {
    //     // Re-enable button now that request is complete
    //     that.setState({loading: false})
    //     submitButton.disabled = false;
    //     alert("error occured: " + error);
    //   });
    // });
  }


  render() {
    const { loading } = this.state

    return(

      <div className="yoco">
        <div className="payment-info">
          <h4>
            Pay with Yoco
          </h4>
          <div className="row">
              <div className="one-liner">
                <div id="card-frame" className="card-frame">
                </div>
                <div className="button-container">
                  { loading ? (
                    <>
                      <Loader />
                    </>
                  )
                :
                (
                  <div>
                    <div class="label">
                      Upon clicking the "Pay" button, you will be redirected to Yoco for the payment completion process.
                      <br/><br/>
                    </div>
                    <div>
                      <Link className="pay-now-button" to="/cart/information">
                        Go Back
                      </Link>
                      <button onClick={() => this.handleSubmitOrder()} type="button" className="pay-now-button">
                      Pay R{this.props.total}
                      </button>
                    </div>
                  </div>
                )}
                </div>

              </div>
              <p className="success-payment-message" />
          </div>
      </div>
    </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    special_instructions: state.order_data.special_instructions,
    cart: state.party_data.cart,
  };
};
export default withRouter(connect(mapStateToProps, { submitOrder, refreshCart, submitGetOrdersRequest })(YocoPayment));
