export const SUBMITTING = "SUBMITTING"
export const SUBMIT_FAILURE = "SUBMIT_FAILURE"

export const REFRESH_CART = "REFRESH_CART";

export const SUBMIT_GET_CART_SUCCESS = "SUBMIT_GET_CART_SUCCESS";

export const ADD_PARTY_PACK_LOCAL = "ADD_PARTY_PACK_LOCAL";
export const ADD_PARTY_PACK_SERVER = "ADD_PARTY_PACK_SERVER";

export const SUBMIT_ADD_PARTY_PACK_SUCCESS = "SUBMIT_ADD_PARTY_PACK_SUCCESS";

export const SUBMIT_DELETE_PARTY_PACK_SUCCESS = "SUBMIT_DELETE_PARTY_PACK_SUCCESS"

export const SUBMIT_QUANTITY_UPDATE_SUCCESS = "SUBMIT_QUANTITY_UPDATE_SUCCESS"

export const UPDATE_QUANTITY = "UPDATE_QUANTITY"

export const DELETE_PARTY_PACK = "DELETE_PARTY_PACK";

export const SUBMIT_MERGE_CART="SUBMIT_MERGE_CART"
export const SUBMIT_MERGE_CART_SUCCESS="SUBMIT_MERGE_CART_SUCCESS"
export const SUBMIT_MERGE_CART_FAILURE="SUBMIT_MERGE_CART_FAILURE"

export const SUBMIT_CLEAR_LOCAL_CART="SUBMIT_CLEAR_LOCAL_CART"

export const ADD_CURRENT_ITEM="ADD_CURRENT_ITEM"
export const REMOVE_CURRENT_ITEM="REMOVE_CURRENT_ITEM"

export const ADD_CURRENT_PACK="ADD_CURRENT_PACK"

export const UPDATE_PARTY_DATE="UPDATE_PARTY_DATE"

export const SUBMIT_ADD_CURRENT_PACK_SUCCESS="SUBMIT_ADD_CURRENT_PACK_SUCCESS"

export const UPDATE_ACCESSORY_QUANTITY_LOCAL="UPDATE_ACCESSORY_QUANTITY_LOCAL"

export const SUBMIT_ACCESSORY_QUANTITY_UPDATE_SUCCESS="SUBMIT_ACCESSORY_QUANTITY_UPDATE_SUCCESS"

export const ADD_ACCESSORY_LOCAL="ADD_ACCESSORY_LOCAL"
export const SUBMIT_ADD_ACCESSORY_SUCCESS="SUBMIT_ADD_ACCESSORY_SUCCESS"

export const DELETE_ACCESSORY_LOCAL="DELETE_ACCESSORY_LOCAL"
export const SUBMIT_DELETE_ACCESSORY_SUCCESS="SUBMIT_DELETE_ACCESSORY_SUCCESS"

export const LOAD_ITEM="LOAD_ITEM"

export const DELETE_ITEM="DELETE_ITEM"
export const SUBMIT_DELETE_ITEM_SUCCESS="SUBMIT_DELETE_ITEM_SUCCESS"

export const CUSTOMISE_PREMADE_PACK="CUSTOMISE_PREMADE_PACK"

export const EDIT_PARTY_PACK="EDIT_PARTY_PACK"

export const UPDATE_EXISTING_PACK_LOCAL="UPDATE_EXISTING_PACK_LOCAL"

export const START_BUILDING_PACK="START_BUILDING_PACK"

export const SUBMIT_UPDATE_EXISTING_PACK_SUCCESS="SUBMIT_UPDATE_EXISTING_PACK_SUCCESS"

export const SUBMIT_REDEEM_VOUCHER="SUBMIT_REDEEM_VOUCHER"
export const SUBMIT_REDEEM_VOUCHER_SUCCESS="SUBMIT_REDEEM_VOUCHER_SUCCESS"
export const SUBMIT_REDEEM_VOUCHER_FAILURE="SUBMIT_REDEEM_VOUCHER_FAILURE"

export const SUBMIT_REMOVE_VOUCHER_SUCCESS="SUBMIT_REMOVE_VOUCHER_SUCCESS"



