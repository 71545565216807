import React from 'react'

import './styles.scss'

const colors = {
    'lightblue': '#9cc',
    'pink': '#e084a3',
    'green': '#396'
}

function ButtonSubmit({text, invert=false, color}) {

    let style
    if(invert){
        style={
            backgroundColor: 'transparent',
            border: `2px solid ${colors[color]}`,
            color: colors[color],
            boxShadow: 'none'
        }
    } else {
        style={ backgroundColor: colors[color] }
    }

    return (
        <input type="submit" value={text} className={`button-submit ${ invert ? 'invert' : '' }`} style={style} />
    )
}

export default ButtonSubmit