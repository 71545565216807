import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { withRouter } from 'react-router-dom'

import './styles.scss'

class VerifyEmail extends React.Component {

  static propTypes = {
    user: PropTypes.shape({})
  };

  render(){
    const email = this.props.user.email
    return(
      <div className="verify-email">
        <div className="container">
          <div className="title-block">
            <div className="title">
              <b>Done! :)</b>
            </div>
            <div className="description">
              An email has been sent to <b>{ email }</b> with a link to activate your account.
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    user: state.signup_data.user
  }
}

export default withRouter(connect(mapStateToProps, null)(VerifyEmail));
