import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { NOTIFICATIONS } from 'constants/notifications.js'
import { withRouter } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';

import FullPageHeader from 'components/FullPageHeader';
import Input from 'components/Input'

import { submitPasswordChangeID, submitValidatePasswordRecoveryID } from 'redux/actions.js'

import './styles.scss'

class PasswordResetID extends React.Component {

  state = {
    password: '',
    reenter_password: '',
  };

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.submitValidatePasswordRecoveryID(id).then( res => {
      //pass
    }).catch(err => {
      NotificationManager.warning("This password recovery ID is invalid.", '', 3000);

      this.props.history.push('/login')
    })
  }

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  async handle_submit (e, data) {
    e.preventDefault();
    const id = this.props.match.params.id;

    if (data.password.length < 8){
      NotificationManager.warning("Your password needs to be at least 8 characters long.", '', 3000);
      return
    }

    if (data.password === data.reenter_password){
      data = {
        id,
        password:data.password,
      }

      this.props.submitPasswordChangeID(data)
      .then((res) => {
        NotificationManager.success("Your password was successfully updated.", 'Success');
        this.props.history.push('/login')
      })
      .catch((err) => {
        NotificationManager.warning(err, '', 3000);
      })
    } else {
      NotificationManager.warning("Your passwords do not match.", '', 3000);
    }

  }


  render() {
    const { password, reenter_password } = this.state
    return (
      <div className="password-reset-id">
        <FullPageHeader headingText={"Password Recovery"} subHeadingText={"Please enter your new password below."} />
        <div className="container">
          <form className="password-reset-form" onSubmit={e => this.handle_submit(e, this.state)}>
            <div className="form-inputs">
              <div className="input-wrapper">
                <Input
                  type="password"
                  name="password"
                  value={password}
                  placeholder="Password*"
                  handle_change={this.handle_change}
                />
              </div>
              <div className="input-wrapper">
                <Input
                  type="password"
                  name="reenter_password"
                  placeholder="Confirm Password*"
                  value={reenter_password}
                  handle_change={this.handle_change}
                />
              </div>
            </div>
            <div className="form-submit">
              <input type="submit" />
            </div>
          </form>
        </div>
      </div>
    )
  }

}

export default withRouter(connect(null, {submitPasswordChangeID, submitValidatePasswordRecoveryID})(PasswordResetID));
