import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet'

import PremadePack from './PremadePack'
import BuildYourOwn from './BuildYourOwn'
import Filter from 'components/Filter'
import PickPackHeader from 'components/PickPackHeader'
import ItemNotificationModal from '../ItemNotificationModal'
import ImageModal from 'components/ImageModal'

import {
  addPartyPack,
  submitGetPremadePacks,
  updateFiltersPremade,
  deletePartyPack,
  loadItemID,
  resetFilters
} from 'redux/actions.js'


import 'react-multi-carousel/lib/styles.css';
import 'rc-slider/assets/index.css';
import './styles.scss'

class PremadePackMin extends React.Component {
  static propTypes = {
    handle_form: PropTypes.func,
    premade_packs: PropTypes.array.isRequired,
    error_message: PropTypes.string,
    filters: PropTypes.shape({}).isRequired,
    createNotification: PropTypes.func,
    party_packs: PropTypes.arrayOf(PropTypes.shape({})),
  }

  state = {
    premade_packs: this.props.premade_packs,
    modal_item: null,
    modal_pack: null,
    selected_packs: [],
    slider_value: 120,
    activeItemIndexes: {},
    filter_active: false,
    building: false,
    notification_item: null
  }

  componentDidMount() {
    this.props.submitGetPremadePacks()
  }


  openItemNotificationModal() {
    this.setState({notification_item: true})
  }

  closeItemNotificationModal() {
    this.setState({notification_item: null})
  }



  selectPack(){      
    const { random_items_checked } = this.props

    if(!random_items_checked) {
      this.openItemNotificationModal()
    }
  }

  deselectPack(pack){
    this.setState({ selected_packs: this.state.selected_packs.filter( item => item !== pack)})
    this.props.deletePartyPack(pack.id)
    this.props.createNotification("warning", pack.name + " has been removed from your cart.")
  }

  clickPack(pack){

    const { party_packs } = this.props

    const selected = party_packs.find(item => {
      if(item.id === pack.id) {
        return true
      }
      else if(item.original_pack_id === pack.id) {
        pack = item
        return true
      }
      return false
    })

    this.props.loadItemID(pack.id)

    if(selected){
      this.deselectPack(pack)
    } else {
      this.selectPack(pack)
    }
  }


  updateFilters = e => {
    let filters = Object.assign({}, this.props.filters)
    const name = e.target.name
    const filter_name = name.split('.')[2]
    filters.other[filter_name] = !filters.other[filter_name]
    this.props.updateFiltersPremade(filters)
  }

  updateSearch = e => {
    let filters = Object.assign({}, this.props.filters)
    filters.search_term = e.target.value
    
    this.props.updateFiltersPremade(filters)
  }

  goBack(){
    this.props.history.push('/create')
  }

  handle_slider_change = new_value => {
    let filters = Object.assign({}, this.props.filters)
    filters.price_range = new_value
    this.props.updateFiltersPremade(filters)
  }


  handle_form(pack) {
    this.props.addPartyPack(pack, this.props.current_party).then(res => {
      window.scroll({top: 0, left: 0, behavior: 'smooth' })
      this.props.history.push('/cart')
    })
  }

  toggleFilterActive() {
    this.setState({filter_active: !this.state.filter_active})
  }

  onValueChange = event => {
    let filters = Object.assign({}, this.props.filters)
    filters.gender = event.target.value
    this.props.updateFiltersPremade(filters)
  }

  startBuild() {
    this.setState({ building:true})
  }

  stopBuild() {
    this.setState({ building: false })
  }

  closeImageModal(){
    this.setState({ modal_item: null, modal_pack: null })
  }

  openImageModal = (item, pack) => {
    this.setState({modal_item: item, modal_pack: pack})
  }

  clickModalItem = () => {
    const { modal_pack } = this.state

    this.clickPack(modal_pack)
  }

  render() {

    const { modal_item, modal_pack, notification_item } = this.state

    const { filters, party_packs, premade_packs, error_message } = this.props


    let filtered_packs = premade_packs

    // PREMADE FILTER START

    // // Filter by price
    // filtered_packs = premade_packs.filter(pack => (
    //   pack.price >= filters.price_range[0] && pack.price <= filters.price_range[1])
    // )

    // //Gender array
    // let gender_array = filters.gender
    // if(typeof gender_array === 'string'){
    //   gender_array = ['G', 'B', 'E']
    // }
    // if (gender_array.length > 0){
    //   filtered_packs = filtered_packs.filter(pack => {
    //     let found = false
    //     for (let i = 0; i < pack.items.length; i++) {
    //       if (!gender_array.includes(pack.items[i].gender)) {
    //         found = true
    //         break;
    //       }
    //     }
    //     return !found
    //   })
    // } else {
    //   filtered_packs = []
    // }

    // // Filter by sweets
    // if (!filters.other.sweets) {
    //   filtered_packs = filtered_packs.filter(pack => {
    //     let found = false
    //     for (let i = 0; i < pack.items.length; i++) {
    //       if (pack.items[i].sweets === 'Y') {
    //         found = true
    //         break;
    //       }
    //     }
    //     if (found) {
    //       return false
    //     } else {
    //       return true
    //     }
    //   })
    // }

    // // Filter by guns
    // if (!filters.other.guns) {
    //   filtered_packs = filtered_packs.filter(pack => {
    //     let found = false
    //     for (let i = 0; i < pack.items.length; i++) {
    //       if (pack.items[i].guns === 'Y') {
    //         found = true
    //         break;
    //       }
    //     }
    //     if (found) {
    //       return false
    //     } else {
    //       return true
    //     }
    //   })
    // }


    //   //Filter by tags
    //   if(filters.tags.length > 0) {
    //     filtered_packs = filtered_packs.filter(pack => {
    //       let found = false
    //       for (let i = 0; i < pack.items.length; i++) {
    //         const item = pack.items[i]
    //         const search_array = [item.item_description, item.item_ext_attribute_1, item.item_ext_attribute_2, item.tag_1, item.tag_2, item.tag_3, item.tag_4, item.tag_5, item.tag_6, item.tag_7, item.tag_8, item.tag_9, item.tag_10]
    //         const tag_array = filters.tags
    //         const intersection = search_array.filter(element => tag_array.includes(element))
    //         if (intersection.length > 0) {
    //           found =  true
    //         }
    //       }
    //       if (found) {
    //         return true
    //       }
    //     })
    //   }

    // // Filter by age range
    // filtered_packs = filtered_packs.filter(pack => {
    //   let found = false
    //   for (let index = 0; index < pack.items.length; index++) {
    //     const item = pack.items[index]
    //     const age_keys = Object.keys(item).filter(v => v.startsWith("age_"))
    //     for (let i = filters.age_range[0]; i <= filters.age_range[1]; i++) {
    //        if(item[age_keys[i]] === "Y"){
    //         found = true
    //        }
    //     }
    //   }
    //   if (found) {
    //     return true
    //   } else {
    //     return false
    //   }
    // })



    //   //Filter by search
    //   if(filters.search_term.length > 0){
    //     filtered_packs = filtered_packs.filter(pack => {
    //         if (pack.name.toLowerCase().match(new RegExp(filters.search_term.toLowerCase(), 'g'))){
    //           return true
    //         } else {
    //           let found = false
    //           for (let i = 0; i < pack.items.length; i++) {
    //             const item = pack.items[i]
    //             const search_array = [item.item_description, item.item_ext_attribute_1, item.item_ext_attribute_2, item.tag_1, item.tag_2, item.tag_3, item.tag_4, item.tag_5, item.tag_6, item.tag_7, item.tag_8, item.tag_9, item.tag_10]
    //             const search_string = search_array.join(':')
    //             if (search_string.toLowerCase().match(new RegExp(filters.search_term.toLowerCase(), 'g'))) {
    //               found = true
    //               break;
    //             }
    //           }
    //           if (found) {
    //             return true
    //           }
    //         }
    //     })
    //   }

    // //Sort by Price
    // if(filters.sort_by === 'price_ascending') {
    //   filtered_packs = [...filtered_packs].sort((a, b) => a.price - b.price);
    // } else if (filters.sort_by === 'price_descending') {
    //   filtered_packs = [...filtered_packs].sort((a, b) => b.price - a.price);
    // }

    //PREMADE FILTER END


    const premade_party_packs = filtered_packs.filter(pack => pack.type === 0)
    const custom_party_packs = filtered_packs.filter(pack => pack.type === 1)

    let selected = false

    if (modal_pack !== null) {
      selected = party_packs.find(item => {
        if(item.id === modal_pack.id) {
          return true
        }
        else if(item.original_pack_id === modal_pack.id) {
          return true
        }
        return false
      })
    }

    if (selected) {
      selected = true
    }

    const pack_count = premade_party_packs.length + custom_party_packs.length

    return (
      <div className="premade-pack-min">
        <Helmet>
          <title>Pick a Pack</title>
          <meta name="description" content="Pick a Pre-made Party Pack. Use these party packs as inspiration. Buy these pre-packed party boxes as is, or edit them to better suit your needs." />
          <meta name="keywords" content="pick pack, edit pack, choose, modify, build your own pack, empty pack, customise pack, gift bag, party packet, surprise packet, gift packet" />
        </Helmet>
        {/* <Link to="/cart">
          <div className="go-to-cart">
            <div className="text">
              To Checkout
            </div>
            <img className="icon" src={cartIcon} />
          </div>
        </Link> */}
        { error_message ? (
          <div className="error">
            { error_message }
          </div>
        )
      :
      (
        <React.Fragment>
          { notification_item && (
            <ItemNotificationModal item={notification_item} closeFunc={() => this.closeItemNotificationModal()} text={"For each of the goodies pictured in this pack, you are ordering just one of the items pictured and the colour of the item will be randomly selected by our Party Packer Elves."} />
          )}
          { modal_item && (
            <ImageModal item={modal_item} closeFunc={() => this.closeImageModal()} clickItem={this.clickModalItem} premade />
          )}
          {/* <div className="heading">
            <h2>
              Pick a Pre-made Pack
            </h2>
            <p>
              Use these packs as inspiration.<br/>
              Buy them as is, or edit them to better suit your party needs.
            </p>
          </div> */}
          <PickPackHeader
            headingText="Pick a Pre-made Party Pack"
            subHeadingText="Use these party packs as inspiration.\nBuy these pre-packed party boxes as is, or edit them to better suit your needs."
          />
          {/* <div className="item-count">
            <b>{ pack_count }</b> packs currently displayed
          </div> */}
          {/* <div>
            <Filter />
          </div> */}
          { premade_party_packs.length > 0 && (
            <div className="content">
              <div className="premade-packs">
                <div className="item-carousels">
                  <BuildYourOwn />
                    { premade_party_packs.map(party_pack  => {
                      if(party_pack.items.length > 0) {
                        return (
                          <PremadePack party_pack={party_pack} selectPack={(pack) => this.selectPack(pack)} key={party_pack.id} createNotification={ this.props.createNotification } openImageModal={this.openImageModal} />
                        )
                      }
                    })
                    }
                </div>
              </div>
            </div>
          )}
          { custom_party_packs.length > 0 && (
            <div className="content">
              <div className="heading">
                <div className="title">
                  <b>Custom</b> Party Packs
                </div>
                <div className="sub-title">
                  Party Packs made by you
                </div>
              </div>
              <div className="premade-packs">
                <div className="item-carousels">
                    { custom_party_packs.map(party_pack => {
                      if(party_pack.items.length > 0){
                        return (
                          <PremadePack party_pack={party_pack} key={party_pack.id} createNotification={ this.props.createNotification } />
                        )
                      }
                    })}
                </div>
              </div>
            </div>
          )}
          { (custom_party_packs.length === 0 && premade_party_packs.length === 0) && (
            <div className="no-packs">
              <h3>No results</h3>
              <p>
                Try adjusting your filters or requirements.<br/>
                You can also reset your filters and start over.
              </p>
              <div onClick={() => this.props.resetFilters()} className="reset-button">
                Reset Filters
              </div>
            </div>
          )
          }
        </React.Fragment>
      )}


      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    current_party: state.party_data.current_party,
    premade_packs: state.premade_data.premade_packs,
    party_packs: state.party_data.cart.party_packs,
    filters: state.filter_data.filters,
    error_message: state.premade_data.error_message,
    is_loading: state.party_data.is_loading,
    loading_item_id: state.party_data.loading_item_id,
    random_items_checked: state.meta_data.random_items_checked,
  };
};

export default withRouter(connect(mapStateToProps, {resetFilters,addPartyPack, submitGetPremadePacks, updateFiltersPremade, deletePartyPack, loadItemID})(PremadePackMin));
