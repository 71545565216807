import axios from 'axios'
import store from './store.js'
import { SUBMIT_LOGOUT } from './auth/types'


function sleeper(ms) {
  return function(x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}

export function submitAuthorizedPost(url, postData, submitStart, submitSuccess, submitFailure){
  store.dispatch(submitStart())
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_API_URL+url, postData, { headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${localStorage.getItem('token')}` } } )
      // .then(sleeper(250))
      .then(res => {
        store.dispatch(submitSuccess(res.data));
        resolve(res.data)
      })
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          store.dispatch(submitFailure(err.response.data.message));
          if(err.response.status === 401){
            localStorage.clear()
            store.dispatch({
              type: SUBMIT_LOGOUT,
            })
          }
        } else {
          // client never received a response, or request never left
          store.dispatch(submitFailure("Oh no! We're struggling to connect to our servers. Are you connected to the internet?"));
        }
        reject(err.response.data.message)
      })
    })
}

export function submitAuthorizedGet(url, submitStart, submitSuccess, submitFailure){
  store.dispatch(submitStart())
  return new Promise((resolve, reject) => {
    axios
    .get(process.env.REACT_APP_API_URL+url, { headers: { 'Content-Type': 'application/json', "Authorization": `JWT ${localStorage.getItem('token')}` } } )
    // .then(sleeper(250))
    .then(res => {
      store.dispatch(submitSuccess(res.data));
      resolve(res.data)
    })
    .catch(err => {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        store.dispatch(submitFailure(err.response.data.message));
        if(err.response.status === 401){
          localStorage.clear()
          store.dispatch({
            type: SUBMIT_LOGOUT,
          })
        }
      } else {
        // client never received a response, or request never left
        store.dispatch(submitFailure("Oh no! We're struggling to connect to our servers. Are you connected to the internet?"));
      }
      reject(false)
    })
  })
}


export function submitUnauthorizedPost(url, postData, submitStart, submitSuccess, submitFailure){
  store.dispatch(submitStart())
  return new Promise((resolve, reject) => {
  axios
    .post(process.env.REACT_APP_API_URL+url, postData, { headers: { 'Content-Type': 'application/json'} } )
    // .then(sleeper(250))
    .then(res => {
      store.dispatch(submitSuccess(res.data));
      resolve()
    })
    .catch(err => {
      if (err.response) {
        // client received an error response (5xx, 4xx)
        store.dispatch(submitFailure(err.response.data.message));
      } else {
        // client never received a response, or request never left
        store.dispatch(submitFailure("Oh no! We're struggling to connect to our servers. Are you connected to the internet?"));
      }
      reject(err.response.data.message)
    })
  })
}

export function submitUnauthorizedGet(url, submitStart, submitSuccess, submitFailure){
  store.dispatch(submitStart())
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_API_URL+url, { headers: { 'Content-Type': 'application/json' } } )
      // .then(sleeper(250))
      .then(res => {
        store.dispatch(submitSuccess(res.data));
        resolve()
      })
      .catch(err => {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          store.dispatch(submitFailure(err.response.data.message));
        } else {
          // client never received a response, or request never left
          store.dispatch(submitFailure("Oh no! We're struggling to connect to our servers. Are you connected to the internet?"));
        }
        reject();
      })
  })
}


export function submitOrderRequest(cart_data) {
  axios.post(process.env.REACT_APP_API_URL+"/order/", cart_data, { headers: { 'Content-Type': 'application/json' } } )
    .then(res => res.data)
    .then(json =>  {
      //pass
    }).catch(
      (error) => {
        if (error.response){
          // Request made and server responded
          this.setState({error_message: error.response.data[Object.keys(error.response.data)[0]][0]});
        } else {
          this.setState({error_message: "An unexpected error occurred. Please try again later."})
        }
    })
  return
}

export function submitCheckStatusRequest(){
  return axios.get(process.env.REACT_APP_API_URL+"/status/", { headers: { 'Content-Type': 'application/json' } } )
}


export function submitSignupRequest(signup_data){
  return axios.post(process.env.REACT_APP_API_URL+"/users/", signup_data, { headers: { 'Content-Type': 'application/json' } } )
}
