import React from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import './styles.scss'
import Collapsible from 'react-collapsible';
import placeHolderIcon from "assets/images/image-coming-soon.jpg"

class OrderedPack extends React.Component {

  static propTypes = {
    party_pack: PropTypes.object.isRequired, 
  }

  state = {
    expanded: true,
  }

  togglePackExpand() {
    this.setState({ expanded: !this.state.expanded })
  }

  getPackRealValue(pack){
    let total = 0
    pack.items.map((item) => (total += parseFloat(item.price)))
    return total.toFixed(2)
  }
  
  render() {
    const { expanded } = this.state
    const { party_pack } = this.props

    return(
      <div className="ordered-pack">
        <div className="pack-details">
          <div className="name">
              { party_pack['name'] }
          </div>
        </div>
        <Collapsible
          open={ expanded }
          transitionTime={350}
          easing="cubic-bezier(.25,.1,.25,1)"
          >
          <div className="items">
            <div className="card-container">
              {
                party_pack.items.map((item,item_index) => {
                  const image_url = process.env.REACT_APP_STATIC_URL+'/img/items/'+item['item_code']+'.jpg'
                  return (
                    <div className="item-info" key={item.item_code}>
                      <div className="item-image-container">
                        <img alt="Party Item" className="item-image" src={image_url} onError={(e)=>{e.target.onerror = null; e.target.src=placeHolderIcon}} />
                      </div>
                      <div className="text-box">
                        <div className="description-container">
                          <div className="description">
                            {item['item_description']}
                          </div>
                        </div>
                        <div className="price-container">
                          <div className="label">
                            Item price
                          </div>
                          <div className="price">
                            R{item['price']}
                          </div>
                        </div>
                      </div>
                    </div>
                )})
              }
            </div>
          </div>
          
          <div className="pack-price-container">
            <p className="label">
              Price per pack
            </p>
            <div className="pack-price">
              R{ this.getPackRealValue(party_pack) }
            </div>
          </div>
        </Collapsible>
      </div>
    )
  }
}

export default withRouter(OrderedPack);
