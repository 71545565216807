import {
  SUBMITTING,
  SUBMIT_FAILURE,
  SUBMIT_GET_EMAILS_SUCCESS,
  SUBMIT_EMAIL_UPDATE,
  SUBMIT_EMAIL_UPDATE_SUCCESS,
  SUBMIT_EMAIL_UPDATE_FAILURE,
  SUBMIT_CONFIRM_EMAIL_CHANGE,
  SUBMIT_CONFIRM_EMAIL_CHANGE_SUCCESS,
  SUBMIT_CONFIRM_EMAIL_CHANGE_FAILURE
} from './types.js'

export const initialState = {
  emails: [],
  is_loading: false,
  error_message: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMITTING: {
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }
    case SUBMIT_FAILURE:{
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading: false,
      }
    }
    case SUBMIT_GET_EMAILS_SUCCESS: {
      const { emails } = action.payload
      return {
        ...state,
        emails,
        is_loading:false,
        error_message: null,
      }
    }
    case SUBMIT_EMAIL_UPDATE: {
      return {
        ...state,
        is_loading:true,
      }
    }
    case SUBMIT_EMAIL_UPDATE_SUCCESS: {
      return {
        ...state,
        is_loading:false,
      }
    }
    case SUBMIT_EMAIL_UPDATE_FAILURE: {
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading:false,
      }
    }
    case SUBMIT_CONFIRM_EMAIL_CHANGE: {
      return {
        ...state,
        is_loading:true,
      }
    }
    case SUBMIT_CONFIRM_EMAIL_CHANGE_SUCCESS: {
      return {
        ...state,
        is_loading:false,
      }
    }
    case SUBMIT_CONFIRM_EMAIL_CHANGE_FAILURE: {
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading:false,
      }
    }
    
    default:
      return state;
  }
}
