import {
  RESET_FILTERS,
  UPDATE_FILTERS,
  UPDATE_FILTERS_PREMADE,
  UPDATE_SELECTION_TYPE,
  SUBMIT_GET_TAGS,
  SUBMIT_GET_TAGS_SUCCESS,
  SUBMIT_GET_TAGS_FAILURE
} from './types'

import { submitUnauthorizedGet } from 'redux/api.js'


export const resetFilters = () => {
  return ({
    type:  RESET_FILTERS,
  })
}

export const updateFilters = (filters) => {
  return ({
    type: UPDATE_FILTERS,
    payload: { filters }
  });
}

export const updateFiltersPremade = (filters) => {
  return ({
    type: UPDATE_FILTERS_PREMADE,
    payload: { filters }
  });
}

export const updateSelectionType = (selection_type) => {
  return({
    type: UPDATE_SELECTION_TYPE,
    payload: { selection_type }
  })
}

export const getTags = () => async dispatch => {
  return submitUnauthorizedGet('/tags/', submitGetTags, submitGetTagsSuccess, submitGetTagsFailure)
}

const submitGetTags = () => {
  return ({
    type: SUBMIT_GET_TAGS,
  });
}

const submitGetTagsSuccess = (tags) => {
  return ({
    type: SUBMIT_GET_TAGS_SUCCESS,
    payload: { tags }
  });
}

const submitGetTagsFailure = (error) => {
  return ({
    type: SUBMIT_GET_TAGS_FAILURE,
    payload: { error }
  });
}