import React from 'react';
import './styles.scss'
import FullPageHeader from 'components/FullPageHeader';

class PasswordResetInfo extends React.Component {
  render() {
    return (
      <div className="password-reset-info">
        <FullPageHeader headingText={"Password Recovery"} subHeadingText={"We have sent you an email with steps to change your password."} />
      </div>
    )
  }
}

export default PasswordResetInfo;
