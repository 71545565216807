import {
  SUBMITTING,
  SUBMIT_FAILURE, 
  SUBMIT_PASSWORD_RESET,
  SUBMIT_PASSWORD_RESET_SUCCESS,
  SUBMIT_PASSWORD_RESET_FAILURE,
  SUBMIT_PASSWORD_CHANGE_ID,
  SUBMIT_PASSWORD_CHANGE_ID_SUCCESS,
  SUBMIT_PASSWORD_CHANGE_ID_FAILURE,
  SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID,
  SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_SUCCESS,
  SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_FAILURE,
  SUBMIT_PASSWORD_UPDATE,
  SUBMIT_PASSWORD_UPDATE_SUCCESS,
  SUBMIT_PASSWORD_UPDATE_FAILURE
} from './types.js'

export const initialState = {
  is_loading: false,
  error_message: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMITTING: {
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }
    case SUBMIT_FAILURE:{
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading: false,
      }
    }
    case SUBMIT_PASSWORD_RESET:{
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }
    case SUBMIT_PASSWORD_RESET_SUCCESS:{
      return {
        ...state,
        is_loading:false,
      }
    }
    case SUBMIT_PASSWORD_RESET_FAILURE:{
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading:false,
      }
    }

    case SUBMIT_PASSWORD_CHANGE_ID: {
      return {
        ...state,
        is_loading:true,
      }
    }
    case SUBMIT_PASSWORD_CHANGE_ID_SUCCESS: {
      return {
        ...state,
        is_loading:false,
      }
    }
    case SUBMIT_PASSWORD_CHANGE_ID_FAILURE: {
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading:false,
      }
    }
    case SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID: {
      return {
        ...state,
        is_loading:true,
      }
    }
    case SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_SUCCESS: {
      return {
        ...state,
        is_loading:false,
      }
    }
    case SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_FAILURE: {
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading:false,
      }
    }
    case SUBMIT_PASSWORD_UPDATE: {
      return {
        ...state,
        is_loading:true,
      }
    }
    case SUBMIT_PASSWORD_UPDATE_SUCCESS: {
      return {
        ...state,
        is_loading:false,
      }
    }
    case SUBMIT_PASSWORD_UPDATE_FAILURE: {
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading:false,
      }
    }
    
    default:
      return state;
  }
}
