import React from 'react'

import './styles.scss'

function Button({text, invert=false, textSize='small', style={}, onClick}) {
    return (
        <div
            className={`button ${textSize} ${invert ? 'invert' : ''}`}
            onClick={onClick}
            style={style}
        >
            { text }
        </div>
    )
}

export default Button