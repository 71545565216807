import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {
  resetFilters
} from 'redux/actions.js'
import './styles.scss'

class Reset extends React.Component {
  static propTypes = {
    resetFilters: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contentOnly: false
  }


  render() {

    const { contentOnly } = this.props    

    return (
      contentOnly ?
      (
        <></>
      )
      :
      (
        <div className="reset-filters">
          <div className={'filter-heading active'} onClick={() => this.props.resetFilters()}>
            Reset Filters
          </div>
        </div>
      )
    )
  }
}

export default withRouter(connect(null, { resetFilters })(Reset));
