import React from 'react'
import PropTypes from 'prop-types';
import './styles.scss'
import deleteIcon from "assets/images/cross-icon.svg"
import { connect } from 'react-redux'

import { toggleRandomItemsCheck } from 'redux/actions'

class ItemNotificationModal extends React.Component {

  static propTypes = {
    closeFunc: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
  }

  close() {
    this.props.closeFunc()
  }

  handleCheckboxChange = (event) => {
    this.props.toggleRandomItemsCheck()
  }

  render() {
    const { random_items_checked, text } = this.props
    return(
      <div className="item-notification-modal">
        <div className="underlay" onClick={() => this.close()}></div>
        <div className="main">
          <div className="close-container" onClick={() => this.close()}>
            <img alt="Close" src={deleteIcon} className="close-image"/>
          </div>
          <div className="heading">
            Please note
          </div>
          <div className="text-box">
              {text}
          </div>

          <div className="checkbox-container">
            <div className="tag-element-container">
              <div className="checkbox-container">
                <div className="checkbox">
                  <input
                    id="dont-show-again"
                    name="dont-show-again"
                    type="checkbox"
                    checked={ random_items_checked }
                    value={ random_items_checked }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label htmlFor="dont-show-again"></label>
                </div>
              </div>
              <label className="text-label" htmlFor="dont-show-again">Don't show this again</label>
            </div>
          </div>
          <div className="button-container">
            <div className="add-to-pack-button" onClick={ () => this.close() }>
              Close
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    random_items_checked: state.meta_data.random_items_checked,
  };
};

export default connect(mapStateToProps, { toggleRandomItemsCheck } )(ItemNotificationModal);
  