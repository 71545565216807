import {
  ADD_ID_ACCOUNT,
  REMOVE_ID_ACCOUNT,
  ADD_ID_CART,
  REMOVE_ID_CART
} from "./types";


export const initialState = {
  collapse_account:[],
  collapse_cart: [],
  is_loading:false,
  error_message: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_ID_ACCOUNT: {
      const id = action.payload.id;
      return{
        ...state,
        collapse_account: [...state.collapse_account, id],
        is_loading: true,
        error_message: null,
      }
    }
    case REMOVE_ID_ACCOUNT: {
      const id = action.payload.id;
      return{
        ...state,
        collapse_account: state.collapse_account.filter(collapse_id => {
          if(collapse_id !== id){
            return collapse_id
          }
        }),
        is_loading: false,
        error_message: null,
      }
    }
    case ADD_ID_CART: {
      const id = action.payload.id;
      return{
        ...state,
        collapse_cart: [...state.collapse_cart, id],
        is_loading: true,
        error_message: null,
      }
    }
    case REMOVE_ID_CART: {
      const id = action.payload.id;
      return{
        ...state,
        collapse_cart: state.collapse_cart.filter(collapse_id => {
          if(collapse_id !== id){
            return collapse_id
          }
        }),
        is_loading: false,
        error_message: null,
      }
    }
    default:
      return state;
  }
}
