import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import { acceptCookies } from 'redux/actions.js'


import './styles.scss'


class CookiesConsent extends React.Component {

  static propTypes = {
    acceptCookies: PropTypes.func.isRequired,
  }

  render() {
    return (
      <div data-nosnippet className="cookies-consent">
        <div className="consent-form">
          <div className="text">
            <b>Notice. </b>This website uses cookies and similar technologies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our Privacy Policy and our cookies and/or similar technologies usage.
          </div>
          <div className="button-container">
            <div className="button" onClick={() => this.props.acceptCookies()}>
              Okay
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, { acceptCookies })(CookiesConsent);
