import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import rootReducer from "./reducer.js";
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
}
// persistStore(this.props).purge();


const persistedReducer = persistReducer(persistConfig, rootReducer)


export default createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
