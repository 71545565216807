
export const ORDER_STATUS = {
    PENDING: 'Pending',
    SUCCEED: 'Succeed',
    FAILED: 'Failed',
}

export const ORDER_STATUS_MAP = {
  0: ORDER_STATUS.PROCESSING,
  1: ORDER_STATUS.COMPLETED,
  2: ORDER_STATUS.FAILED
}

export const DELIVERY_STATUS = {
    INFORMATION_RECEIVED: 'Information Received',
    IN_TRANSIT: 'In Transit',
    OUT_FOR_DELIVERY: 'Out for Delivery',
    FAILED_ATTEMPT: 'Failed Attempt',
    DELIVERED: 'Delivered',
    AVAILABLE_FOR_PICKUP: 'Available for Pickup',
    EXCEPTION: 'Exception',
    EXPIRED: 'Expired',
    PENDING: 'Pending',
}

export const DELIVERY_STATUS_MAP = {
  0: DELIVERY_STATUS.INFORMATION_RECEIVED,
  1: DELIVERY_STATUS.IN_TRANSIT,
  2: DELIVERY_STATUS.OUT_FOR_DELIVERY,
  3: DELIVERY_STATUS.FAILED_ATTEMPT,
  4: DELIVERY_STATUS.DELIVERED,
  5: DELIVERY_STATUS.AVAILABLE_FOR_PICKUP,
  6: DELIVERY_STATUS.EXCEPTION,
  7: DELIVERY_STATUS.EXPIRED,
  8: DELIVERY_STATUS.PENDING,
}

export const PAYMENT_STATUS = {
  NEW: "NEW",
  PROCESSING: "PROCESSING",
  SUCCESSFUL: "succeeded",
  FAILED: "FAILED",
  TIMEOUT: "TIMEOUT",
  EXPIRED: "EXPIRED",
  AWAITING_PAYMENT: "AWAITING_PAYMENT",
}
