import React from 'react';
import { withRouter } from 'react-router-dom'
import OrdersTable from 'components/OrdersTable'
import AccountPageWrapper from '../AccountPageWrapper'

import './styles.scss'

class Orders extends React.Component {
  render() {
    return (
      <AccountPageWrapper heading='My Orders'>
        <div className="orders">
          <div className="title">Orders</div>
          <OrdersTable />
        </div>
      </AccountPageWrapper>
    );
  }
}
export default withRouter(Orders);
