import React from 'react'
import PropTypes from 'prop-types';
import './styles.scss'
import placeHolderIcon from "assets/images/image-coming-soon.jpg"
import tickIcon from "assets/images/tick.svg"
import crossIcon from "assets/images/cross-icon.svg"
import removeIcon from "assets/images/cross-icon-white.svg"

class ImageModal extends React.Component {

  static propTypes = {
    item: PropTypes.shape({}).isRequired,
    closeFunc: PropTypes.func.isRequired,
    clickItem: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    premade: PropTypes.bool
  }

  static defaultProps = {
    premade: false,
  }

  close() {
    this.props.closeFunc()
  }

  add = (item) => {
    this.props.clickItem(item)
    this.close()
  }

  render() {
    const { item, selected, premade } = this.props
    const item_image_url = process.env.REACT_APP_STATIC_URL+'/img/items/'+item['item_code']+'.jpg'

    return(
      <div className="image-modal">
        <div className="underlay" onClick={() => this.close()}></div>
        <div className="main">
          <div className="close-container" onClick={() => this.close()}>
            <img src={crossIcon} className="close-image"/>
          </div>
          <div className="image-container">
            <img className="item-image" src={item_image_url} onError={(e)=>{e.target.onerror = null; e.target.src=placeHolderIcon}} />
          </div>
          <div className="modal-footer">
            <div className="text-box">
              <h5 className="description">
                {item['item_description']}
              </h5>
              <p className="price">
                R{item['price']} <span className="vat-text">(incl. VAT)</span>
              </p>
            </div>
            { !premade && (
              <div className="button-container">
                { selected ? (
                  <div className="added-to-pack-button" onClick={() => this.add(item)}>
                    <img alt="Remove" className="tick" src={ removeIcon } /> Remove from Pack
                  </div>
                )
                :
                (
                  <div className="add-to-pack-button" onClick={ () => this.add(item)} >
                    + Add to Pack
                  </div>
                )
                }
              </div>
            )}

          </div>
        </div>
      </div>
    )
  }
}

export default ImageModal;
  