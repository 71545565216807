import React from 'react'
import starSquiggle from 'assets/images/star-squiggle-dot.svg'
import './styles.scss'
import ButtonLink from 'components/ButtonLink'

function Planning() {
  return (
    <div className="planning">
      <div className="planning-content">
        <img alt="Squiggle" src={starSquiggle} />
        <h2 className="title">Party Packs<br/>made easy</h2>
        <p className="subtitle">
            We're here to help make your kids party the easiest, funnest and smoothest-running event imaginable.
            Offering the widest range of customisable, pre-packed or hand-picked party packs, to make yours the most original party in town.
         </p>
         <ButtonLink to="/about" text="About Us" color="green" invert />
      </div>
    </div>
  );
}

export default Planning;
