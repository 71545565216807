import {
  SUBMIT_GET_ITEMS,
  SUBMIT_GET_ITEMS_SUCCESS,
  SUBMIT_GET_ITEMS_FAILURE
} from './types'
import { submitUnauthorizedGet } from 'redux/api.js'

export const getItems = () => async dispatch => {
  return submitUnauthorizedGet('/items/', submitGetItems, submitGetItemsSuccess, submitGetItemsFailure)
}

const submitGetItems = () => {
  return ({
    type: SUBMIT_GET_ITEMS,
  });
}

const submitGetItemsSuccess = (items) => {
  return ({
    type: SUBMIT_GET_ITEMS_SUCCESS,
    payload: { items }
  });
}

const submitGetItemsFailure = (error) => {
  return ({
    type: SUBMIT_GET_ITEMS_FAILURE,
    payload: { error }
  });
}
