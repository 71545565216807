import './styles.scss'
import React from 'react';
import Login from 'containers/Auth/Login'

const Success = () => {
   return (
     <Login subtitle_overide="Your account has been activated! Please login below."/>
   );
  }

export default Success;
