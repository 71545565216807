import {
  SUBMITTING,
  SUBMIT_FAILURE,
  SUBMIT_GET_PREMADE_PACKS_SUCCESS,
} from "./types";

export const initialState = {
  premade_packs:[],
  is_loading:false,
  error_message: null,
}


export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMITTING: {
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }
    case SUBMIT_FAILURE:{
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading: false,
      }
    }
    case SUBMIT_GET_PREMADE_PACKS_SUCCESS: {
      const premade_packs = action.payload.premade_packs;
      return{
        ...state,
        premade_packs,
        is_loading: false,
        error_message: null,
      }
    }
    default:
      return state;
  }
}
