import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { submitRetryPayment, submitGetOrdersRequest } from 'redux/actions.js'
import { Link, withRouter } from 'react-router-dom'
import { ORDER_STATUS_MAP, DELIVERY_STATUS_MAP, PAYMENT_STATUS_MAP, PAYMENT_STATUS, ORDER_STATUS, DELIVERY_STATUS } from 'containers/Account/constants.js'
import { NotificationManager } from 'react-notifications';

import './styles.scss'

class OrdersTable extends React.Component {
  componentDidMount() {
    this.props.submitGetOrdersRequest()
  }

  retry_payment(id){
    const data = {
      order_id: id,
    }
    this.props.submitRetryPayment(data)
    .then(res => {
      console.log("RESPONSE", res)
      window.location.replace(res.redirect_url);
    })
    .catch(error => {
      NotificationManager.info(error);
    })
  }

  render() {
    const { orders } = this.props

    return (
        <div className="orders-table">
          <div className="orders">
            <div className="order-table">
              <div className="headings">
                <div className="cell order-no-cell">Order no.</div>
                <div className="cell date-cell">Date</div>
                <div className="cell total-cell">Total</div>
                <div className="cell status-cell">Status</div>
                <div className="cell options-cell"></div>
              </div>
              { orders.map(order => {
                return (
                  <div className="row" key={order.id}>
                    <div className="cell order-no-cell">
                      <div className='mobile-label'>
                        Order no.
                      </div>
                      <div className="content">
                        {order.id}
                      </div>
                    </div>
                    <div className="cell date-cell">
                      <div className='mobile-label'>
                        Date
                      </div>
                      <div className="content">
                        {order.created_at}
                      </div>
                    </div>
                    <div className="cell total-cell">
                      <div className='mobile-label'>
                        Total
                      </div>
                      <div className="content">
                        R{order.amount_due}
                      </div>
                    </div>
                    <div className="cell status-cell">
                      <div className='mobile-label'>
                        Status
                      </div>
                      <div className="content">
                        {order.status_string}
                      </div>
                    </div>
                    <div className="cell options-cell">
                      <div className='mobile-label'>
                      </div>
                      <div className="content">
                        <div className="order-links">
                          <Link className="order-link" to={`/account/orders/${order.id}`}>View Order</Link>
                          { order.courier_guy_shipment && (
                            <a target="_blank" className="order-link" href={`https://portal.thecourierguy.co.za/track?ref=${order.courier_guy_shipment.short_tracking_reference}`}>Track Order</a>
                          )}
                          { !['successful', 'SUCCESSFUL', 'succeeded'].includes(order.payment_status) && (
                            <Link className="order-link" to='#' onClick={() => this.retry_payment(order.id)}>Retry Payment</Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>

        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.order_data.orders
  };
};

export default withRouter(connect(mapStateToProps, { submitRetryPayment, submitGetOrdersRequest })(OrdersTable));
