import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  addCurrentItem,
  removeCurrentItem,
  addPartyPack,
  submitGetPremadePacks,
  updateFiltersPremade,
  resetFilters
} from 'redux/actions.js'

import { connect } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import ImageModal from 'components/ImageModal'
import ItemNotificationModal from '../ItemNotificationModal'
import CreatePreview from './CreatePreview'
import Filter from 'components/Filter'

import 'react-multi-carousel/lib/styles.css';
import 'rc-slider/assets/index.css';
import './styles.scss'

import crossIcon from "assets/images/cross-icon-white.svg"
import placeHolderIcon from "assets/images/image-coming-soon.jpg"
import PickPackHeader from 'components/PickPackHeader';

import { Helmet } from 'react-helmet'

class BuildPack extends React.Component {
  static propTypes = {
    createNotification: PropTypes.func.isRequired,
    error_message: PropTypes.string,
    current_pack: PropTypes.arrayOf(PropTypes.shape({})),
    addCurrentItem: PropTypes.func.isRequired,
    removeCurrentItem: PropTypes.func.isRequired,
    filters: PropTypes.shape({}),
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }

  state = {
    activeItemIndexes: {},
    current_items: [],
    has_more_items: true,
    prev: 0,
    next: 24,
    modal_item: null,
    modal_item_selected: false,
    length:50,
    notification_item: null,
    item_notification_modal: false,
  }

  componentDidMount() {
    const { prev, next } = this.state
    this.setState({ current_items: this.props.items.slice(prev, next) })
  }


  getMoreItems = () => {
    this.setState({length: this.state.length + 50})
  }

  selectItem(item){
    const { random_items_checked } = this.props

    if(!random_items_checked) {
      this.setState({notification_item: item, item_notification_modal: true})
    }

    this.props.addCurrentItem(item).then(res => {
    })
  }

  deselectItem(item){
    this.props.removeCurrentItem(item).then(res => {
    })
  }

  clickItem = (item) => {
    if( item !== null) {
      const { current_pack } = this.props

      if( current_pack.find(i => i.item_code === item.item_code)) {
        this.deselectItem(item)
      } else {
        this.selectItem(item)
      }
    }
  }

  openItemNotificationModal() {
    this.setState({notification_item: true})
  }

  closeItemNotificationModal() {
    this.setState({notification_item: null})
  }


  closeImageModal(){
    this.setState({modal_item: null})
  }


  openImageModal(e, item){
    e.stopPropagation();
    const { current_pack } = this.props
    const selected = current_pack.find(o => o.item_code === item.item_code)
    this.setState({modal_item: item, modal_item_selected: selected ? true : false})
  }

  filterItems = () => {
    const { items, filters } = this.props
    let filtered_items = items

    //Gender array
    let gender_array = filters.gender
    if(typeof gender_array === 'string'){
      gender_array = ['G', 'B', 'E']
    }

    //Main Filter Loop
    filtered_items = filtered_items.filter(item => {
      // Filter by guns
      if (!filters.other.guns) {
        if(item.guns!=='N'){
          return false
        }
      }

      // Filter by sweets
      if (!filters.other.sweets) {
        if(item.sweets!=='N'){
          return false
        }
      }

      // Filter by gender
      if (gender_array.length > 0){
          if(!gender_array.includes(item.gender)){
            return false
          }
      } else {
        return false
      }

      // Filter by age range
      const age_keys = Object.keys(item).filter(v => v.startsWith("age_"))
      let age_check = false
      for (let i = filters.age_range[0]; i <= filters.age_range[1]; i++) {
         if(item[age_keys[i]] === "Y"){
           age_check = true
         }
      }
      if(age_check === false){
        return false
      }

      // Filter by price range
      if(item.price < filters.price_range[0] | item.price > filters.price_range[1]){
        return false
      }

      const search_array = [item.item_description, item.item_ext_attribute_1, item.item_ext_attribute_2, item.tag_1, item.tag_2, item.tag_3, item.tag_4, item.tag_5, item.tag_6, item.tag_7, item.tag_8, item.tag_9, item.tag_10]
      const search_string = search_array.join(':')


      //Filter by tags
      // if(filters.tags.length > 0) {
      //   const search_array_lower = search_array.map(element => {
      //     if (typeof element === 'string' || element instanceof String){
      //       return element.toLowerCase();
      //     }
      //   });
      //   const tag_array = filters.tags.map(tag => tag.toLowerCase())

      //   // if(!search_string.toLowerCase().match(tag_array)){
      //   //   return false
      //   // }
      //   const tag_match = tag_array.some(word => {
      //     return search_string.toLowerCase().match(word)
      //   })

      //   if(!tag_match){
      //     return false
      //   }
      // }


      //Filter by search
    if((filters.search_term.length > 0) || (filters.tags.length > 0)){
        const search_term_clean = filters.search_term.trim().toLowerCase()
        const search_term_array = search_term_clean === '' ? [] : search_term_clean.split(' ')
        const tag_array = filters.tags.map(tag => tag.toLowerCase())
        const cat_array = tag_array.concat(search_term_array)
        const regexListString = cat_array.join('|')

        const reg = new RegExp(regexListString, 'gi')
        const match = search_string.toLowerCase().match(reg)
        const count = (match || []).length
        if(!match){
          return false
        } else {
          item.rank = count
        }
    }
      return true
    })
    return filtered_items
  }

  render() {

    const { current_pack, filters, error_message } = this.props
    const { has_more_items, modal_item, length, notification_item, modal_item_selected } = this.state

    let filtered_items = this.filterItems()

    const item_count = filtered_items.length

    //Sort by Price
    let current_pack_list = current_pack.map(item => item.item_code)
    filtered_items = [...filtered_items].sort((a, b) => {
      if(current_pack_list.includes(a.item_code)){
        return -1
      } else if (current_pack_list.includes(b.item_code)) {
        return 0
      }else {
        if(filters.sort_by === 'price_ascending'){
          return(a.price - b.price)
        } else if (filters.sort_by === 'price_descending') {
          return(b.price - a.price)
        } else if (filters.sort_by === 'random'){
          return(b.rank - a.rank)
        }
      }
    });

    filtered_items = filtered_items.slice(0, length)

    return (
      <div className="create-pack-min">
        <Helmet>
          <title>Build a Pack</title>
          <meta name="description" content="We know how much time it takes to put together the perfect party pack. Let us take the hassle out of putting together the perfect gift bag for you." />
          <meta name="keywords" content="build pack, customise pack, own pack, edit pack, filter, choose, select, search, price, tags, age, gender, sort, other, party pack, modify, make" />
        </Helmet>
        { notification_item && (
          <ItemNotificationModal item={notification_item} closeFunc={() => this.closeItemNotificationModal()} text={'You are ordering just one of the items pictured, and the colour of the item will be randomly selected by our Party Picker Elves.'}/>
        )}
        { modal_item && (
          <ImageModal item={modal_item} closeFunc={() => this.closeImageModal()} selected={modal_item_selected} clickItem={this.clickItem}/>
        )}
        <PickPackHeader 
          headingText="Build/Customise your Party Pack"
          subHeadingText="We know how much time it takes to put together the perfect party pack. Let us take the hassle out of putting together the perfect gift bag for you. With plenty of choices available, your guests are guaranteed to receive a pack full of surprises to put smiles on their faces."
          
        />
        <div className="item-count">
          <b>{ item_count }</b> items currently displayed
        </div>
        <div>
          <Filter type='build' />
        </div>
        <div className="content">
          <div className="create-pack-container">
          { filtered_items.length > 0 ? (

            <React.Fragment>
              <div className="item-container">
              { error_message ? (
                <div className="error">{ error_message }</div>
              )
              :
              ( <React.Fragment>
                    <InfiniteScroll
                      dataLength = {filtered_items.length}
                      next = {this.getMoreItems}
                      hasMore={has_more_items}
                      className="infinite-scroll"
                    >
                      { filtered_items.map(item => {

                      const selected = current_pack.find(o => o.item_code === item.item_code)
                      const item_image_url = process.env.REACT_APP_STATIC_URL+'/img/items/'+item['item_code']+'.jpg'

                      return (
                        <div className="card" key={item.item_code}>
                          <div className={`carousel-wrapper ${ selected ? 'selected' : ''}`} key={item.item_code}>
                              <div className="item-image-container" > { /* onClick={(e) => this.openImageModal(e, item) } */}
                                <img alt="Party Item" className="item-image" src={item_image_url} onError={(e)=>{e.target.onerror = null; e.target.src=placeHolderIcon}} onClick={(e) => this.openImageModal(e, item) } />
                                <div className="item-info">
                                  <div className="divider" />
                                  <div className="text-box">
                                    <h5 className="description">
                                      {item['item_description']}
                                    </h5>
                                    <p className="price">
                                      R{item['price']} <span className="vat-text">(incl. VAT)</span>
                                    </p>
                                  </div>
                                </div>
                                { selected ? (
                                  <div className="added-to-pack-button" onClick={ () => this.clickItem(item) }>
                                    <div className="tick-container"><img alt="Remove" className="tick" src={ crossIcon } /></div> 
                                    <span className="remove-long-text">Remove from Pack</span><span className="remove-short-text">Remove</span>
                                  </div>
                                )
                                :
                                (
                                  <div className="add-to-pack-button" onClick={ () => this.clickItem(item) }>
                                    <div className="plus-container"><span className="plus">+</span></div> 
                                    <span className="add-long-text">Add to Pack</span><span className="add-short-text">Add</span>
                                  </div>
                                )
                                }
                              </div>
                          </div>

                        </div>

                      )
                      })}
                    </InfiniteScroll>

                </React.Fragment>
              )}
              </div>
              <div className="create-preview-container">
                  <CreatePreview items={current_pack} createNotification={this.props.createNotification} clickItem={this.clickItem} />
              </div>
            </React.Fragment>
          ):
          (
            <div className="no-packs">
              <h3>No results</h3>
              <p>
                Try adjusting your filters or requirements.<br/>
                You can also reset your filters and start over.
              </p>
              <div onClick={() => this.props.resetFilters()} className="reset-button">
                Reset Filters
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    current_pack: state.party_data.current_pack,
    error_message: state.item_data.error_message,
    items: state.item_data.items,
    filters: state.filter_data.filters,
    random_items_checked: state.meta_data.random_items_checked,
  };
};

export default withRouter(connect(mapStateToProps, {addCurrentItem, removeCurrentItem, addPartyPack, submitGetPremadePacks, updateFiltersPremade, resetFilters })(BuildPack));
