import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom'

import './styles.scss'

class AccountHeading extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    link_text: PropTypes.string,
    link_to: PropTypes.string,
    handle_on_click: PropTypes.func,
  }

  static defaultProps = {
    link_text: null,
    link_to: null,
    handle_on_click: null,
  }

  render() {

    const { text, link_text, link_to, handle_on_click  } = this.props

    return (
      <div className="account-heading">
        <div className="title">{ text }</div>
        { link_text !== null && (
          <div className="link-container">
            <Link className="link" to={ link_to } onClick={() => handle_on_click()}>
              <u>{ link_text }</u>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(AccountHeading);
