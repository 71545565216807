import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

const colors = {
    'lightblue': '#9cc',
    'pink': '#e084a3',
    'green': '#396'
}

function ButtonLink({text, invert=false, to, color}) {

    let style
    if(invert){
        style={
            backgroundColor: 'transparent',
            border: `2px solid ${colors[color]}`,
            color: colors[color],
            boxShadow: 'none'
        }
    } else {
        style={ backgroundColor: colors[color] }
    }

    return (
        <Link
            className="button-link"
            to={to}
        >
            <div className={`content ${ invert ? 'invert' : '' }`} style={style}>
                { text }
            </div>
        </Link>
    )
}

export default ButtonLink