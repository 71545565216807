import {
  SUBMITTING,
  SUBMIT_FAILURE,
  SUBMIT_GET_PREMADE_PACKS_SUCCESS
} from './types'

import { submitUnauthorizedGet, submitAuthorizedGet } from 'redux/api.js'

const submitting = () => ({
  type: SUBMITTING
});

const submitFailure = error_message => ({
  type: SUBMIT_FAILURE,
  payload: {
    error_message
  }
});

export const submitGetPremadePacks = () => async dispatch => {
  let getFunction
  if(localStorage.getItem('token')){
    getFunction = submitAuthorizedGet
  } else {
    getFunction = submitUnauthorizedGet
  }
  return getFunction('/partypacks/premade/', submitting, submitGetPremadeSuccess, submitFailure)

}

const submitGetPremadeSuccess = (premade_packs) => {
  return ({
    type: SUBMIT_GET_PREMADE_PACKS_SUCCESS,
    payload: { premade_packs }
  });
}


