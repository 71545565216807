import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { authenticated } from 'redux/auth/action.js'
import './styles.scss'
import { submitGetCartServer, startCheckout, updateQuantity, removeVoucher } from 'redux/actions.js'
import Collapsible from 'react-collapsible';
import cartIcon from "assets/images/cart-icon.svg"
import dropdownIcon from "assets/images/dropdown-icon.svg"
import { NotificationManager } from 'react-notifications';
import SmallIntegerSelect from 'components/SmallIntegerSelect';
import crossIcon from "assets/images/cross-icon.svg"

import { getCartValueInclVat, getTotal, getVat, getSubtotal, getShippingPriceExclVAT, getShippingPriceInclVAT } from 'utils/cart'

import Input from 'components/Input'

class CartSummary extends React.Component {

  static propTypes = {
    cart: PropTypes.shape({}).isRequired,
    pageIsCart: PropTypes.bool,
    mobileEnabled: PropTypes.bool,
    displayItems: PropTypes.bool,
  }

  static defaultProps = {
    pageIsCart: false,
    displayItems: true
  }

  state = {
    expanded: false,
    width: window.innerWidth
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };



  componentDidMount() {
    const { cart } = this.props

    window.addEventListener('resize', this.updateDimensions);

    if (localStorage.getItem("token")) {
      this.props.submitGetCartServer()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  // getTotal(){
  //   const { cart } = this.props

  //   let total = 0

  //   cart.party_packs.map(party_pack => {
  //     let party_pack_value = 0
  //     party_pack.items.map(item => {
  //       party_pack_value += parseFloat(item.price)
  //     })
  //     total += parseFloat(party_pack_value)*party_pack.amount
  //   })

  //   cart.accessories.map(accessory => {
  //     total += parseFloat(accessory.item.price) * accessory.quantity
  //   })

  //   return total.toFixed(2)
  // }

  // getVat(){
  //   return parseFloat(this.getSubtotal()*0.15).toFixed(2)
  // }

  // getSubtotal(){
  //   return (parseFloat(this.getTotal()/1.15)).toFixed(2)
  // }

  componentDidUpdate(prevProps) {

  }

  handleUpdateQuantity = (amount, index) => {
    this.props.updateQuantity(index, amount)
  }

  handleQuantityInputChange = (event) => {
    let num = +event.target.value
    if(!isNaN(num)){
      if(num <= 100 && num > 0){
        this.setState({ quantity: num })
      }
    }
  }

  onFocus = () => {
    this.setState({ focusing: true })
  }

  onBlur = (quantity) => {
    this.submitQuantityUpdate(quantity)
  }

  removeVoucher = (id) => {
    console.log("CLICK>?")
    this.props.removeVoucher(id).then((response) => {
      NotificationManager.success(response.message, 'Success!', 3000)
    }).catch(err => {
      NotificationManager.warning(err, 'Error...', 3000)
    })
  }



  handle_checkout = () => {
    const { cart } = this.props


    if(getTotal(cart) < this.props.minimum_order_total) {
      NotificationManager.warning(`Your order total needs to be above R${this.props.minimum_order_total}.`, '', 3000);
      return
    }

    this.props.startCheckout()

    if(authenticated()){
      this.props.history.push('/cart/information')
    } else {
      this.props.history.push({pathname: '/cart/signin', state: {
        from: this.props.location.pathname
      }})
    }
  }

  renderCartSummary() {
    const { cart, pageIsCart, displayItems } = this.props

    const { voucher_number } = this.state

    const isPaidShipping = getShippingPriceExclVAT(cart) > 0

    return (
        <div className="card">
          <div className="header">
            <span><b>Cart Summary</b></span>
          </div>
          { isPaidShipping && (
            <div className="charge-shipping">Get free shipping with a Subtotal greater than R500!</div>
          )}
          <div className="cart-summary-component">
            { displayItems && (
              <div className="party-packs">
                {cart.party_packs.map((party_pack, index) => {
                  let pack_value = 0 
                  party_pack.items.map(item => {
                    pack_value += parseFloat(item.price)
                  })
                  return(
                    <div className="party-pack" key={index}>
                      <div className="name">{ party_pack['name'] }</div>
                      {/* <div className="quantity">QTY: { party_pack['amount'] }</div> */}
                      <SmallIntegerSelect
                        handleQuantityInputChange = { this.handleQuantityInputChange }
                        handleUpdateQuantity = { this.handleUpdateQuantity }
                        onBlur = { this.onBlur }
                        onFocus = { this.onFocus }
                        quantity = { party_pack['amount'] }
                        partyIndex = {index}
                      />
                      <div className="price">R{parseFloat(pack_value*party_pack['amount']).toFixed(2)}</div>
                    </div>
                  )
                })}
                {cart.accessories.map((accessory, index) => {
                  let accessory_value = accessory.item.price * accessory.quantity
                  return(
                    <div key={index}>
                      <div>{ accessory.item.item_description }</div>
                      <div>{ accessory.quantity }</div>
                      <div className="bold">R{parseFloat(accessory_value).toFixed(2)}</div>
                    </div>
                  )
                })}
              </div>
            )}
            
            
            <div className="breakdown">
              <div className="subtotal">
                <div className="label">Subtotal:</div>
                <div className="bold">R{getCartValueInclVat(cart)}</div>
              </div>
              <div className="shipping-cost">
                <div className="label">Shipping:</div>
                <div className="bold">R{getShippingPriceInclVAT(cart).toFixed(2)}</div>
              </div>
              { cart.vouchers && cart.vouchers.map((voucher, i) => {
                return (
                  <div className="shipping-cost voucher-deductions">
                    <div className="label">
                      <div class="delete-voucher-icon" onClick={() => this.removeVoucher(voucher.id)}>
                        <img src={crossIcon} />
                      </div>
                      Voucher ({voucher.voucher_code}):
                    </div>
                    <div className="bold"> - R{(voucher.amount_in_cents / 100).toFixed(2)}</div>
                  </div>
                )
              })}
              <div className="total">
                <div className="label">Total{isPaidShipping? ' with shipping' : ''}:</div>
                <div className="bold">R{getTotal(cart).toFixed(2)}</div>
              </div>
              <div className="vat">
                <div className="label">Vat:</div>
                <div className="bold">R{parseFloat(getVat(cart))}</div>
              </div>
            </div>
         </div>
        { pageIsCart && (
          <div className="proceed-button" type="submit" onClick={ () => this.handle_checkout() }>Proceed to Checkout</div>
        )}
     </div>
    )
  }



  render() {
    const { cart, mobileEnabled } = this.props

    const { expanded, width } = this.state

    const mobileView = width <= 1000 && mobileEnabled

    return(
      <div className={`cart-summary-container ${mobileView ? 'mobile-view' : ''}`}>
        { mobileView ? (
          <div>
            <div className="mobile-banner" onClick={() => this.setState({expanded: !expanded})}>
              <div className="cart-icon">
                <img src={cartIcon} />
              </div>
              <div className="text">
                { expanded ? 
                  (
                    <span>Hide order summary</span>
                  )
                  :
                  (
                    <span>Show order summary</span>
                  )
                }
                <span className={`dropdown-arrow ${ expanded ? 'rotated':'' }`}>
                  <img src={dropdownIcon} />
                </span>
              </div>

              <div className="total">
                R{ getTotal(cart).toFixed(2) }
              </div>

            </div>
            <Collapsible
              open={ expanded }
              transitionTime={350}
              width='100vw'
              easing="cubic-bezier(.25,.1,.25,1)"
            >
              {this.renderCartSummary()}
            </Collapsible>
          </div>
        )
        :
        (
          this.renderCartSummary()
        )
        }
        
      </div>
      
    )
  }
}

const mapStateToProps = state => {
    return {
      cart: state.party_data.cart,
      minimum_order_total: state.meta_data.global_settings.minimum_order_total
    };
};

export default withRouter(connect(mapStateToProps, { submitGetCartServer, startCheckout, updateQuantity, removeVoucher })(CartSummary));
  