import React from 'react'
import './styles.scss'
import squiggle from 'assets/images/about-us-squiggle.svg'

function AuthHeading({headingText, subHeadingText, image}) {
  return (
    <div className="auth-heading">
      <div className="heading-container">
        <img alt="Squiggle" src={image ? image : squiggle} />
        <div className="heading">{headingText}</div>
        { subHeadingText && (
          <div className="sub-heading">{subHeadingText}</div>
        )}
      </div>
    </div>
  );
}

export default AuthHeading;
