export const SUBMITTING = "SUBMITTING"
export const SUBMIT_FAILURE = "SUBMIT_FAILURE"

export const SUBMIT_ORDER_SUCCESS = "SUBMIT_ORDER_SUCCESS";

export const SUBMIT_CANCEL_ORDER_SUCCESS = "SUBMIT_CANCEL_ORDER_SUCCESS";

export const SUBMIT_GET_ORDERS_SUCCESS="SUBMIT_GET_ORDERS_SUCCESS"

export const SUBMIT_GET_ORDER_SUCCESS="SUBMIT_GET_ORDER_SUCCESS"

export const SUBMIT_RETRY_PAYMENT_SUCCESS="SUBMIT_RETRY_PAYMENT_SUCCESS"

export const SAVE_SPECIAL_INSTRUCTIONS="SAVE_SPECIAL_INSTRUCTIONS"

