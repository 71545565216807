import {
  SUBMIT_STATUS_CHECK,
  SUBMIT_STATUS_CHECK_SUCCESS,
  SUBMIT_STATUS_CHECK_FAILURE,
  TEMP_AUTHENTICATE,
  ACCEPT_COOKIES,
  TOGGLE_RANDOM_ITEMS_CHECK,
  SUBMIT_GET_GLOBAL_SETTINGS,
  SUBMIT_GET_GLOBAL_SETTINGS_SUCCESS,
  SUBMIT_GET_GLOBAL_SETTINGS_FAILURE,
  SUBMIT_LOCATION_TRACKING,
  SUBMIT_LOCATION_TRACKING_SUCCESS,
  SUBMIT_LOCATION_TRACKING_FAILURE,
} from "./types";

export const initialState = {
  redux_structure_ok: true, 
  backend_status: {},
  is_loading: false,
  temp_authenticated: false,
  cookies_consent: false,
  random_items_checked: false,
  global_settings: {
    maintenance_mode: false,
    cg_delivery_time: 5,
    minimum_pack_value: 40,
    minimum_order_total: 500,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_STATUS_CHECK: {
      return {
        ...state,
        is_loading: true
      }
    }
    case SUBMIT_STATUS_CHECK_SUCCESS:{
      return {
        ...state,
        is_loading: false,
        backend_status: true,
      }
    }

    case SUBMIT_STATUS_CHECK_FAILURE:{
      return {
        ...state,
        is_loading: false,
        backend_status: false,
      }
    }

    case TEMP_AUTHENTICATE:{
      return {
        ...state,
        temp_authenticated: true,
      }
    }

    case ACCEPT_COOKIES: {
      return {
        ...state,
        cookies_consent: true,
      }
    }

    case TOGGLE_RANDOM_ITEMS_CHECK: {
      return {
        ...state,
        random_items_checked: !state.random_items_checked,
      }
    }

    case SUBMIT_GET_GLOBAL_SETTINGS: {
      return {
        ...state,
      }
    }

    case SUBMIT_GET_GLOBAL_SETTINGS_SUCCESS: {
      const global_settings = action.payload.data;
      return {
        ...state,
        global_settings: global_settings,
      }
    }

    case SUBMIT_GET_GLOBAL_SETTINGS_FAILURE: {
      return {
        ...state,
        global_settings: {
          maintenance_mode: false,
          cg_delivery_time: 5,
          minimum_pack_value: 40,
          minimum_order_total: 480,
        },
      }
    }
    
    default:
      return state;
  }
}
