import React from 'react'

import FAQCollapsible from './FAQCollapsible'
import FullPageHeader from 'components/FullPageHeader'

import { Helmet } from 'react-helmet'

import './styles.scss'

function FAQ() {
  return (
    <div className="faq">
      <Helmet>
        <title>FAQ's</title>
        <meta name="description" content="Have a look below to see if we can answer your question." />
        <meta name="keywords" content="faq, delivery options, when, change delivery address, damaged, international, return, different colour, different style, wrong item, missing" />
      </Helmet>
      <FullPageHeader headingText={"FAQs"} subHeadingText={"Have a look below to see if we can answer your question."}/>
      <div className="container">
        <div className="text">
          <FAQCollapsible
            question={'What are my delivery options?'}
            answer={'Standard delivery is approximately 5 working days. All of our delivery options, and how long each option takes, is viewable when checking out.'}
          />

          <FAQCollapsible
            question={'When will my order be delivered?'}
            answer={"We'll deliver your order by the estimated delivery date provided in your Payment Confirmation email. You can also track your delivery at any time by selecting the Track My Order option on your Account profile. This is the most current tracking information we have for your order. If this date changes, we’ll let you know straight away by email or SMS."}
          />

          <FAQCollapsible
            question={'Can I change my delivery address after I have ordered?'}
            answer={"It’s unfortunately not possible to change your delivery address after confirming your order. Please double check at the time of placing your order that your address details are correct. To change your delivery address permanently for future orders, you can update your address in your Account profile."}
          />

          <FAQCollapsible
            question={'What happens if an item is missing from my delivery or arrives damaged?'}
            answer={"In the unfortunate event that something is missing from your order, please contact our sales team so that we can assist you. If an item arrives damaged, please refer to our Returns and Refunds page."}
          />
          
          <FAQCollapsible
            question={'Do you ship internationally?'}
            answer={"We currently do not ship outside of our borders."}
          />

          <FAQCollapsible
            question={'How do I log a return?'}
            answer={"Please log a return by contacting our sales team."}
          />

          <FAQCollapsible
            question={'Can I exchange a product?'}
            answer={"Once your order has been placed, you unfortunately can not exchange any items. When putting together the Party Packs, there are many opportunities to select alternative items should you wish to change them. In the event that you received an order and an item is damaged, please contact our sales team for assistance to replace the damaged item."}
          />
          <FAQCollapsible
            question={'Can I exchange a product for a different style or colour?'}
            answer={"Once your order has been placed, you unfortunately can not exchange any items for different style or colour variations. Please ensure at the time that you place your order that you double check your selections in terms of gender preference, and other customising options."}
          />
          

          <FAQCollapsible
            question={'What do I do if the item I received is not what I ordered?'}
            answer={"If we accidentally delivered the wrong product to you, or if the product is not as described on the website, please notify us immediately and we will gladly assist in rectifying the situation. "}
          />
           

           <FAQCollapsible
            question={'I received a damaged item.'}
            answer={"At Party Packers, we do our best to make sure that all the products we deliver are in a good condition; we are sorry if this was not your experience with the order you received. Please contact our sales team so that we can assist you and make things right."}
          />
          
          <FAQCollapsible
            question={'I have a question or concern not listed here. Can you help me?'}
            answer={"Absolutely! Please get in touch with our sales team by phone or email and we will be happy to assist you."}
          />
                    
        </div>
      </div>
    </div>
  );
}

export default FAQ;
