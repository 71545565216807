import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Select from 'react-select';
import dropdownIcon from "assets/images/dropdown-icon.svg"

import './styles.scss'

class SortBy extends React.Component {
  static propTypes = {
    filters: PropTypes.shape({}).isRequired,
    toggled: PropTypes.bool.isRequired,
    toggleFilter: PropTypes.func.isRequired,
    contentOnly: PropTypes.bool,
    updateFilters: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contentOnly: false
  }

  onValueChange = event => {
    let filters  = Object.assign({}, this.props.filters)
    filters.sort_by = event.target.value
    this.props.updateFilters(filters)
  }

  renderContent(filters) {
    return (
      <div className="sort-by">
        <div className="clickables">
          <div className="preferred-gender">
            <ul className="filter-list">
              <li>
                <input
                  name="sort_by"
                  value="price_ascending"
                  type="radio"
                  checked={filters.sort_by === 'price_ascending'}
                  onChange={this.onValueChange}
                />
                <label>Price (Low to High)</label>
              </li>
              <li>
                <input
                  name="sort_by"
                  value="price_descending"
                  type="radio"
                  checked={filters.sort_by === 'price_descending'}
                  onChange={this.onValueChange}
                />
                <label>Price (High to Low)</label>
              </li>
              <li>
                <input
                  name="sort_by"
                  value="random"
                  type="radio"
                  checked={filters.sort_by === 'random'}
                  onChange={this.onValueChange}
                />
                <label>Randomise</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
    
  }

  renderContentDropdown(filters) {
    return (
      <div className="sort-by-content">
        { this.renderContent(filters) }
      </div>
    )
  }

  render() {

    const { toggled, filters, tags, toggleFilter, contentOnly } = this.props
    const toggledStyle= toggled ? {transform:'rotate(180deg)', paddingRight:'10px', paddingLeft:'0px'} : {}

    const highlighted = ( filters.sort_by !== 'random')

    const options = tags.map(tag => {
      if (tag){
        return { value: tag, label: tag.toLowerCase() }
      }
    })
    return (
      contentOnly ? (
        this.renderContent(filters)
      )
      :
      (
        <div className="sort-by-filter">
          <div className={`filter-heading ${highlighted ? 'active' : ''}`} onClick={ () => toggleFilter('sort_by') }>
            Sort By
            <img src={ dropdownIcon } style={toggledStyle} />
            { highlighted && (
              <div className="filter-number">1</div>
            )}
          </div>
          { toggled && (
            this.renderContentDropdown(filters)
          )}
        </div>
      )
    )
  }
}

const mapStateToProps = state => {
  return {
    tags: state.filter_data.tags,
  };
};

export default withRouter(connect(mapStateToProps, {  })(SortBy));
