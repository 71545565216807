import {
  SUBMITTING,
  SUBMIT_FAILURE,
  RESET_REDUX,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_LOGIN_FAILURE,
  SUBMIT_LOGOUT,
  SUBMIT_UPDATE_USER_SUCCESS,
  SUBMIT_FETCH_USER_SUCCESS,
} from './types.js'
import { submitUnauthorizedPost, submitAuthorizedPost, submitAuthorizedGet } from 'redux/api.js'

export const resetRedux = login_data => async dispatch => {
  dispatch({
    type: RESET_REDUX,
  })
}

export const submitLogin = login_data => async dispatch => {
  return submitUnauthorizedPost('/token-auth/', login_data, submitting, submitLoginSuccess, submitLoginFailure)
}

export const authenticated = () => {
  return localStorage.getItem('token') ? true : false
}

const submitLoginSuccess = (data) => dispatch => {
  localStorage.setItem('token', data.token);
  return dispatch({
    type: SUBMIT_LOGIN_SUCCESS,
    payload: {
      user: data
    }
  });
}

const submitting = () => ({
  type: SUBMITTING
});

const submitLoginFailure = error => ({
  type: SUBMIT_LOGIN_FAILURE,
  payload: {
    error
  }
});

export const submitLogout = () => {
  return ({
    type: SUBMIT_LOGOUT,
  })
}

export const submitUserUpdate = user => dispatch => {
  return submitAuthorizedPost("/users/"+user.id+"/", user, submitting, submitUserUpdateSuccess, submitFailure)
}

const submitUserUpdateSuccess = user => {
  return {
    type: SUBMIT_UPDATE_USER_SUCCESS,
    payload: {
      user
    }
  }
};

const submitFailure = error_message => ({
  type: SUBMIT_FAILURE,
  payload: {
    error_message
  }
});

export const submitFetchUser = () => dispatch => {
  return submitAuthorizedGet("/current_user/", submitting, submitFetchUserSuccess, submitFailure )
}

const submitFetchUserSuccess = user_data => {
  return{
    type: SUBMIT_FETCH_USER_SUCCESS,
    payload: {
      user_data
    }
  }
};
