import React from 'react';
import PropTypes from 'prop-types';
import isMobilePhone from 'validator/lib/isMobilePhone'
import { connect } from 'react-redux'
import { NOTIFICATIONS } from 'constants/notifications.js'
import { PROVINCES } from 'constants/provinces.js'
import { withRouter, Link } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';

import LocationSearchInput from 'components/LocationSearchInput'
import AuthHeading from '../AuthHeading'

import { submitSignup, submitLogin, submitMergeCart } from 'redux/actions.js'

import loadingGif from "assets/images/loading.gif"
import dropdownIcon from "assets/images/dropdown-icon.svg"
import Input from "components/Input"
import './styles.scss'

class Signup extends React.Component {

  static propTypes = {
    user: PropTypes.shape({}),
    is_checking_out: PropTypes.bool,
    createNotification: PropTypes.func,
  };

  state = {
    step: 'basic',
    email: '',
    retype_email: '',
    first_name: '',
    last_name: '',
    password: '',
    cell_number: '',
    shipping_address_1: '',
    shipping_address_2: '',
    shipping_city: '',
    shipping_province: PROVINCES.WC,
    shipping_postal_code: '',
    shipping_country: 'South Africa',
    billing_address_1: '',
    billing_address_2: '',
    billing_city: '',
    billing_province: PROVINCES.WC,
    billing_postal_code: '',
    billing_country: 'South Africa',
    is_loading: false,
    same_address: true,
  };

  componentDidUpdate(prevProps) {
    if (this.props.error_message !== prevProps.error_message) {
      this.setState({ error_message : this.props.error_message });
    }
  }

  async handle_signup (e, data) {
    e.preventDefault();

    if (data.email === data.retype_email){
      if (this.validateBasicForm() === false) {
        return
      }


      data = {
        first_name: data.first_name,
        last_name: data.last_name,
        password: data.password,
        email: data.email,
        profile: {
          shipping_address_1: data.shipping_address_1,
          shipping_address_2: data.shipping_address_2,
          shipping_city: data.shipping_city,
          shipping_province: data.shipping_province,
          shipping_postal_code: data.shipping_postal_code,
          shipping_country: data.shipping_country,
          billing_address_1: this.state.same_address ? data.shipping_address_1 : data.billing_address_1,
          billing_address_2: this.state.same_address ? data.shipping_address_2 : data.billing_address_2,
          billing_city: this.state.same_address ? data.shipping_city : data.billing_city,
          billing_province: this.state.same_address ? data.shipping_province : data.billing_province,
          billing_postal_code: this.state.same_address ? data.shipping_postal_code : data.billing_postal_code,
          billing_country: this.state.same_address ? data.shipping_country : data.billing_country,
          cell_number: data.cell_number
        }
      }

      this.props.submitSignup(data).then((res) => {
        NotificationManager.success("You have successfully signed up with Party Packers", "Success");
        const login_data = {username: data.email, password: data.password}
        this.props.submitLogin(login_data).then(() => {
          this.props.submitMergeCart().then(() => {
            if(this.props.location.state){
              if(this.props.location.state.from === "/cart"){
                this.props.history.push("/cart/information")
              } else {
                this.props.history.push(this.props.location.state.from)
              }
            } else {
              this.props.history.push('/')
            }
          })
        })
      }).catch((err)=>{
        NotificationManager.error(err, 'Error...', 5000);
      })
    } else {
      NotificationManager.error("Email addresses do not match.", 'Error...', 5000);
    }

  }


  handle_change = e => {
    if(e.target.name === "same_address"){
      this.setState({ same_address:e.target.checked })
    } else {
      const name = e.target.name;
      const value = e.target.value;
      this.setState(prevstate => {
        const newState = { ...prevstate };
        newState[name] = value;
        return newState;
      });
    }
  };

  render_complete(){
    const { email } = this.state
    return(
      <div className="container">
        <div className="title-block">
          <div className="title">
            <b>Registration Complete</b>
          </div>
          <div className="description">
            An email has been sent to { email } with a link to activate your account.
          </div>
          <div className="close-button-container">
            <button className="close-button">Okay</button>
          </div>
        </div>
      </div>
    )
  }

  autocompleteShippingAddress = (address) => {
    this.setState({
      shipping_address_1: address.streetAddress,
      shipping_address_2: address.suburb,
      shipping_city: address.city,
      shipping_province: address.province,
      shipping_postal_code: address.postalCode,
    })
  }

  handleShippingAddressChange = (address) => {
    this.setState({shipping_address_1: address})
  }

  autocompleteBillingAddress = (address) => {
    this.setState({
      billing_address_1: address.streetAddress,
      billing_address_2: address.suburb,
      billing_city: address.city,
      billing_province: address.province,
      billing_postal_code: address.postalCode,
      billing_country: address.country,
    })
  }

  handleBillingAddressChange = (address) => {
    this.setState({billing_address_1: address})
  }

  validateEmail(email) 
  {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
    {
      return true
    }
      return false
  }

  validateBasicForm = () => {
    const { email, retype_email, first_name, last_name, cell_number, password } = this.state

    const { createNotification } = this.props

    if(this.validateEmail(email)){
      if(email !== retype_email) {
        createNotification(NOTIFICATIONS.WARNING, "Your email addresses do not match.")
        return false
      }
    } else {
      createNotification(NOTIFICATIONS.WARNING, "Your email address is not valid.")
      return false
    }

    if(first_name.length < 1) {
      createNotification(NOTIFICATIONS.WARNING, "Your first name is required.")
      return false
    }

    if (last_name.length < 1){
      createNotification(NOTIFICATIONS.WARNING, "Your last first name is required.")
      return false
    }

    if (isMobilePhone(cell_number, 'en-ZA') === false){
      createNotification(NOTIFICATIONS.WARNING, "This phone number is not a valid South Africa cell phone number.")
      return false
    }

    if (password.length < 8)  {
      createNotification(NOTIFICATIONS.WARNING, "Your password needs to be at least 8 characters.")
      return false
    }

    return true

  }

  nextStep = () => {
    if(this.validateBasicForm()) {
      this.setState({step: 'shipping'})
    }
  }

  render_form() {
    const {
      step,
      email,
      same_address,
      retype_email,
      first_name,
      last_name,
      password,
      cell_number,
      shipping_address_1,
      shipping_address_2,
      shipping_city,
      shipping_province,
      shipping_postal_code,
      shipping_country,
      billing_address_1,
      billing_address_2,
      billing_city,
      billing_province,
      billing_postal_code,
      billing_country,
      is_loading,
      error_message
    } = this.state

    return (
      <div className="signup">
        <AuthHeading headingText={"Create an account"}/>
        <div className="container">
          <div className="title-block">
            <div className="description">
              Already have an account? <Link to={{ pathname: "/login", state: this.props.location.state }} >Sign in</Link>
            </div>
            <div className="status">
              <div className={`step ${step==='basic'? 'active':''}`}>
                <div className="number">1</div>
                <div className="text">
                  Basic information
                </div>
              </div>
              <div className="arrow">
                <img alt="Arrow" src={dropdownIcon} />
              </div>
              <div className={`step ${step==='shipping'? 'active':''}`}>
                <div className="number">2</div>
                <div className="text">
                  Shipping address
                </div>
              </div>
            </div>
            { error_message && (
              <div className="error-container">
                <div className="error-message">{error_message}</div>
              </div>
            )}
          </div>
          <form className="signup-form" onSubmit={e => this.handle_signup(e, this.state)}>
            <div className="form-input">
              { step==='basic' ? (
              <div className="form-section">
                <div className="column">
                  <div className="form-control">
                    <Input
                      type="text"
                      name="first_name"
                      value={first_name}
                      handle_change={this.handle_change}
                      required
                      placeholder="First name*"
                    />
                  </div>
                  <div className="form-control">
                    <Input
                      type="text"
                      name="last_name"
                      value={last_name}
                      handle_change={this.handle_change}
                      required
                      placeholder="Last name*"
                    />
                  </div>
                  <div className="form-control">
                    <Input
                      type="text"
                      name="cell_number"
                      value={cell_number}
                      handle_change={this.handle_change}
                      required
                      placeholder="Cellphone number*"
                    />
                  </div>
                </div>
                <div className="column">
                  <div className="form-control">
                    <Input
                      type="text"
                      name="email"
                      value={email}
                      handle_change={this.handle_change}
                      required
                      placeholder="Email address*"
                    />
                  </div>
                  <div className="form-control">
                    <Input
                      type="text"
                      name="retype_email"
                      value={retype_email}
                      handle_change={this.handle_change}
                      required
                      placeholder="Confirm email address*"
                    />
                  </div>
                  <div className="form-control">
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      handle_change={this.handle_change}
                      required
                      placeholder="Password*"
                    />
                  </div>
                </div>
              </div>
              )
              :
              (
                <React.Fragment>
                <div className="form-section">
                  <div className="column">
                    <div className="form-control">
                      <LocationSearchInput label="Address 1 (Street Address)" autocompleteCallback={this.autocompleteShippingAddress} handleChange={this.handleShippingAddressChange} address={shipping_address_1} />
                      {/* <input
                        type="text"
                        name="shipping_address_1"
                        value={shipping_address_1}
                        onChange={this.handle_change}
                        required
                      /> */}
                    </div>
                    <div className="form-control">
                      <Input
                        type="text"
                        name="shipping_address_2"
                        value={shipping_address_2}
                        handle_change={this.handle_change}
                        placeholder="Address 2 (Apt, suite or unit number)"
                      />
                    </div>
                    <div className="form-control">
                      <Input
                        type="text"
                        name="shipping_city"
                        value={shipping_city}
                        handle_change={this.handle_change}
                        required
                        placeholder="City"
                      />
                    </div>
                  </div>
                  <div className="column">
                    <div className="form-control">
                      <select
                        name="shipping_province"
                        className="select"
                        onChange={this.handle_change}
                        value={shipping_province}
                        required
                        >
                        { Object.keys(PROVINCES).map(key => {
                          return(
                            <option key={ key } value={PROVINCES[key]}>{PROVINCES[key]}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="form-control">
                      <Input
                        type="text"
                        name="shipping_country"
                        value={shipping_country}
                        handle_change={this.handle_change}
                        required
                        disabled
                        placeholder="Country"
                      />
                    </div>
                    <div className="form-control">
                      <Input
                        type="text"
                        name="shipping_postal_code"
                        value={shipping_postal_code}
                        handle_change={this.handle_change}
                        required
                        placeholder="Postal Code"
                      />
                    </div>
                  </div>
                </div>
                <div className="same-address-checkbox">
                    <input
                      type="checkbox"
                      name="same_address"
                      value={same_address}
                      checked={same_address}
                      onChange={this.handle_change}
                      className="checkbox"
                    />
                    <label htmlFor="same_address">Billing address same as shipping address? {same_address}</label>
                  </div>
                { !same_address && (
                  <div className="form-section">
                        <div className="column">

                          <div className="form-control">
                            <LocationSearchInput label="Address 1 (Street Address)" autocompleteCallback={this.autocompleteBillingAddress} handleChange={this.handleBillingAddressChange} address={billing_address_1} />
                          </div>
                          <div className="form-control">
                            <Input
                              type="text"
                              name="billing_address_2"
                              value={billing_address_2}
                              handle_change={this.handle_change}
                              placeholder="Address 2 (Apt, suite or unit number)"
                            />
                          </div>
                          <div className="form-control">
                            <Input
                              type="text"
                              name="billing_city"
                              value={billing_city}
                              handle_change={this.handle_change}
                              placeholder="City"
                            />
                          </div>
                        </div>
                        <div className="column">
                          <div className="form-control">
                            <select
                              name="billing_province"
                              className="select"
                              onChange={this.handle_change}
                              value={billing_province}
                              placeholder="Province"
                              >
                              { Object.keys(PROVINCES).map(key => {
                                return(
                                  <option value={PROVINCES[key]}>{PROVINCES[key]}</option>
                                )
                              })}
                            </select>
                          </div>
                          <div className="form-control">
                            <Input
                              type="text"
                              name="billing_country"
                              value={billing_country}
                              handle_change={this.handle_change}
                              required
                              disabled
                              placeholder="Country"
                            />
                          </div>
                          <div className="form-control">
                            <Input
                              type="text"
                              name="billing_postal_code"
                              value={billing_postal_code}
                              handle_change={this.handle_change}
                              placeholder="Postal Code"
                            />
                          </div>
                        </div>
                  </div>
                  )}
                </React.Fragment>
              )}
            </div>
            { is_loading && (
              <img className="loader" src={loadingGif} alt="Loader"/>
            )}

            { step === 'basic' && (
              <div className="button-container">
                <button type="button" className="next-button" onClick={() => this.nextStep()}>
                  Next Step
                </button>
              </div>
            ) }

            { step === 'shipping' && (
              <div className="form-submit">
                <button type="button" className="back-button" onClick={() => this.setState({step:'basic'})}>
                  Back
                </button>
                <input type="submit" value="Create Account" />
              </div>
              )
            }



          </form>
        </div>
      </div>
    )
  }

  reset_form = () => {
    this.setState({
      first_name: '',
      last_name: '',
      password: '',
      email: '',
      cell_number: '',
      address: '',
      billing_address:'',
      is_loading: false,
    })
  }


  render() {
    return (
      this.render_form()
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.signup_data.user,
    is_checking_out: state.checkout_data.is_checking_out,
  };
};

export default withRouter(connect(mapStateToProps, {submitSignup, submitLogin, submitMergeCart})(Signup));
