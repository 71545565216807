import React from 'react'

import './styles.scss'
import BreadCrumbs from 'containers/CreatePack/Cart/BreadCrumbs'


class Page extends React.Component {

  state = {
    width:  window.innerWidth,
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {

    const { width } = this.state

    const isMobile = width <= 1000

    return (
      <div className="page-wrapper">
        <div className="container">
          { isMobile && (
              <div className='child-1'>
                {this.props.cartSummary}
              </div>
            )}
          <div className="breadcrumbs">
              <BreadCrumbs />
            </div>
          <div className="children">
            <div className='child-0'>
              {this.props.component}
            </div>
            { !isMobile && (
              <div className='child-1'>
                {this.props.cartSummary}
              </div>
            )}

          </div>
        </div> 
      </div>
    )
  }
}

export default Page;
