export const PROVINCES = {
  WC: 'Western Cape',
  EC: 'Eastern Cape',
  NC: 'Northern Cape',
  GP: 'Gauteng',
  FS: 'Free State',
  KZN: 'KwaZulu-Natal',
  LP: 'Limpopo',
  MP: 'Mpumalanga',
  NW: 'North West',
}
