import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

class AccountPageWrapper extends React.Component {
  static propTypes = {
    heading: PropTypes.string,
  }

  static defaultProps = {
    heading: null,
  }

  render() {
    const { heading, children } = this.props

    return (
        <div className="account-page-wrapper">
          { (heading !== null) && (
            <div className="account-heading"> { heading } </div>
          )}
          <div className="content">
            { children }
          </div>
        </div>
    );
  }
}

export default AccountPageWrapper;