import React from 'react'
import './styles.scss'

function PickPackHeader({headingText, subHeadingText}) {
  return (
    <div className="pick-pack-header">
      <h2>
        { headingText }
      </h2>
      { subHeadingText.split("\\n").map((line, i) => (<p key={i} >{line}</p>)) }
  </div>
  );
}

export default PickPackHeader;
