import React from 'react'
import './styles.scss'
import { submitClearLocalCart, submitGetOrderRequest } from 'redux/actions.js'
import { connect } from 'react-redux'
import { PAYMENT_STATUS } from './constants.js'
import Lottie from 'lottie-react'
import aboutUsSquiggle from 'assets/images/about-us-squiggle.svg'

import funLoader from "assets/lottie-animations/fun-loader.json"


import { Link, withRouter } from 'react-router-dom'

import partyImage from "assets/images/party-cone.png"

class OrderResponse extends React.Component {

  state = {
    order: null
  }

  getOrderLoop(i) {
    const id = this.props.match.params.id;
    let that = this
    let order

    this.props.submitGetOrderRequest(id).then(updated_order => {
      order = updated_order
      this.setState({ order })
    })

    if(order){
      if(order.payment_status === (PAYMENT_STATUS.SUCCESSFUL )) {
        return
      }
    } else {
      setTimeout(function() {
        if (--i) that.getOrderLoop(i);
      }, 5000)
    }


  }

  componentDidMount(){
    this.props.submitClearLocalCart()
    this.getOrderLoop(50)
  }

  render() {
    const { order } = this.state
    return (
        <div className="order-success">
            <div className="container">
          { order && (
            <React.Fragment>
              { order.payment_status === PAYMENT_STATUS.SUCCESSFUL && (
                <React.Fragment>
                  <div className="heading-container">
                    <img alt="Squiggle" src={aboutUsSquiggle} />
                    <div className="heading">Your order has been placed!</div>
                    <div className="sub-heading">Your Party Packs will be delivered straight to your door. Check your inbox for updates.</div>
                    <div className="sub-heading">
                      You can view your order <Link to={`/account/orders/${order.id}`}>here</Link> for more details
                    </div>
                  </div>
                  <div className="party-images">
                    <img className="confetti" src={partyImage} alt="confetti"/>
                  </div>
                </React.Fragment>
 
              ) }
              { order.payment_status === PAYMENT_STATUS.FAILED && (
                <div className="party-images">
                  <div className="text-block">
                    <div className="title">
                      Payment failure...
                    </div>
                    <div className="sub-title">
                      Something went wrong during the payment process.
                    </div>
                    <div className="text">
                      Have a look at the <Link to={'/account/orders'}>order details</Link> for more information.
                    </div>
                  </div>
                </div>
              ) }
              { order.payment_status === PAYMENT_STATUS.PENDING && (
                <Lottie className="fun-loader" animationData={funLoader} loop={true} />
              ) }
            </React.Fragment>
          ) }
          { (!order || (order.payment_status !== PAYMENT_STATUS.FAILED && order.payment_status !== PAYMENT_STATUS.SUCCESSFUL)) && (
            <Lottie className="fun-loader" animationData={funLoader} loop={true} />
          )}            
          </div>
        </div>
    );
  }
}

export default withRouter(connect(null, { submitClearLocalCart, submitGetOrderRequest })(OrderResponse));