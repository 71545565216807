import {
  ADD_ID_ACCOUNT,
  REMOVE_ID_ACCOUNT,
  ADD_ID_CART,
  REMOVE_ID_CART
} from './types'

export const addIdAccount = (id) => {
  return ({
    type: ADD_ID_ACCOUNT,
    payload: { id },
  });
}

export const removeIdAccount = (id) => {
  return ({
    type: REMOVE_ID_ACCOUNT,
    payload: { id },
  });
}

export const addIdCart = (id) => {
  return ({
    type: ADD_ID_CART,
    payload: { id },
  });
}

export const removeIdCart = (id) => {
  return ({
    type: REMOVE_ID_CART,
    payload: { id },
  });
}
