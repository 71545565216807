import React from 'react'

import './styles.scss'

import partyPackersLogo from 'assets/images/party-packers-logo.svg'


function MaintenanceMode() {
  return (
    <div className="maintenance-mode">
      <img alt="Party Packers" className="logo" src={partyPackersLogo} />
      <h2 className="heading">
        The Party Packers website is currently down for maintenance.
      </h2>
      <h3 className="heading">We will be back up as soon as we can!</h3>
      <h4 className="heading">Sorry for the inconvience.</h4>
    </div>
  );

}

export default MaintenanceMode;
