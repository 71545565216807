import React from 'react'
import './styles.scss'
import FullPageHeader from 'components/FullPageHeader'
import { Link } from 'react-router-dom'

function PageNotFound() {
  return (
    <div className="page-not-found">
      <FullPageHeader headingText={"Page Not Found"} subHeadingText={"Either this page doesn't exist, or you don't have access to it."}/>
      <div className="container">
        <div className="text-block">
          <div className="button-container">
            <Link className="button" to={ '/' } >
              Go Back Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
