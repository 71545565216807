import React from 'react'
import PropTypes from 'prop-types';

import './styles.scss'

import minusIcon from "assets/images/minus-icon.svg"
import plusIcon from "assets/images/plus-icon.svg"


class SmallIntegerSelect extends React.Component {

  static propTypes = {
    handleUpdateQuantity: PropTypes.func.isRequired,
    handleQuantityInputChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired,
    premadeStyle: PropTypes.bool,
    partyIndex: PropTypes.number.isRequired,
  }

  static defaultProps = {
    premadeStyle: false
  }

  render() {

    const { handleUpdateQuantity, handleQuantityInputChange, onBlur, onFocus, quantity, premadeStyle } = this.props

    return(
      <div className={`small-integer-select ${premadeStyle ? 'small-premade-integer-select' : ''}`}>
        <div className="grey-container">
          <div onMouseDown={() => handleUpdateQuantity(-1, this.props.partyIndex)}>
            <img className="minus-icon" src={minusIcon} alt="minus"/>
          </div>
          <div className="input-container">
            <input className="quantity-input" value={ quantity } onChange={ handleQuantityInputChange } onFocus={ onFocus } onBlur={ () => onBlur(quantity) } />
          </div>
          <div onMouseDown={() => handleUpdateQuantity(1, this.props.partyIndex)}>
            <img className="plus-icon" src={plusIcon} alt="plus"/>
          </div>
        </div>
      </div>
    )

  }
}



export default SmallIntegerSelect;
