import {
  SUBMITTING,
  SUBMIT_FAILURE,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_LOGIN_FAILURE,
  SUBMIT_UPDATE_USER,
  SUBMIT_UPDATE_USER_SUCCESS,
  SUBMIT_FETCH_USER,
  SUBMIT_FETCH_USER_SUCCESS,
  SUBMIT_FETCH_USER_FAILURE
} from "./types";


export const initialState = {
  user_data: {},
  logged_in: false,
  is_loading: false,
  error_message: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMITTING: {
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }
    case SUBMIT_FAILURE:{
      const error_message = action.payload.error_message !== undefined ? action.payload.error_message : null;
      return {
        ...state,
        error_message,
        is_loading: false,
      }
    }
    case SUBMIT_LOGIN_SUCCESS:{
      const user_data = action.payload.user;
      return {
        ...state,
        user_data: user_data,
        logged_in: true,
        is_loading: false,
        error_message:null
      }
    }

    case SUBMIT_LOGIN_FAILURE:{
      const error_message = action.payload.error;
      return {
        ...state,
        logged_in: false,
        is_loading: false,
        error_message
      }
    }

    case SUBMIT_UPDATE_USER:{
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }

    case SUBMIT_UPDATE_USER_SUCCESS:{
      const user = action.payload.user;
      return {
        ...state,
        user_data: {
          ...state.user_data,
          user
        },
        is_loading: false,
        error_message: null
      }
    }
    case SUBMIT_FETCH_USER:{
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }

    case SUBMIT_FETCH_USER_SUCCESS:{
      const user = action.payload.user_data;
      return {
        ...state,
        user_data: {
          ...state.user_data,
          user
        },
        is_loading: false,
        error_message: null
      }
    }

    case SUBMIT_FETCH_USER_FAILURE:{
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading: false,
      }
    }

    default:
      return state;
  }
}
