import React from 'react'
import Lottie from 'lottie-react'
import './styles.scss'
import loader from 'assets/lottie-animations/loader-edited.json'

function Loader() {
    return (
        <div className="loader">
            <Lottie className="fun-loader" animationData={loader} loop={true} style={{width:50, height:50}}/>
        </div>
    )
}

export default Loader;