import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { NOTIFICATIONS } from 'constants/notifications.js'
import { withRouter, Link } from 'react-router-dom'

import { NotificationManager } from 'react-notifications'
import { submitLogin, submitMergeCart, submitGetCartServer } from 'redux/actions.js'

import loadingGif from "assets/images/loading.gif"

import Button from "components/Button"

import './styles.scss'

class Login extends React.Component {

  static propTypes = {
    user: PropTypes.shape({}),
    logged_in: PropTypes.bool,
    error_message: PropTypes.string,
    subtitle_overide: PropTypes.string,
    is_checking_out: PropTypes.bool,
    orientation: PropTypes.string,
  };

  static defaultProps = {
    subtitle_overide: null,
  }

  state = {
    email: '',
    password: '',
    error_message: '',
  };

  componentDidUpdate(prevProps) {
    if (this.props.logged_in !== prevProps.logged_in) {
      if(this.props.logged_in === true){
        if (this.props.is_checking_out) {
          this.props.history.push('/cart/information')
        } else {
          this.props.history.push('/cart')
        }
      }
    }
  }

  async handle_login(e, data) {
    e.preventDefault();

    data = {
      username: data.email,
      password: data.password,
    }

    const locationState = this.props.location.state

    this.props.submitLogin(data).then(()=> {
      this.props.submitMergeCart().then(() => {
        this.props.submitGetCartServer().then(()=>{
          NotificationManager.success("You have successfully logged in.", 'Success');
          if (locationState) {
            this.props.history.push('/cart/information')
          } else {
            this.props.history.push('/')
          }
        })
      })
    }).catch(err => {
      NotificationManager.error("Incorrect username or password.", 'Error...', 5000)
    })
  }


  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
    this.setState({ error_message:'' })
  };


  render() {
    const { is_loading, error_message, logged_in, subtitle_overide, orientation, title } = this.props

    const { email, password } = this.state

    return (
      <div className={`login-component ${orientation}`}>
        <div className="login-container">
          <div className="title-block">
            <div className="title">
              { title }{/*Sign in to your account*/}
            </div>
            <div className="register-cart">
              New user? <Link to={{ pathname: "/signup", state: this.props.location.state }} >Create an account</Link>
            </div>

            {/* <div className="description">
              { subtitle_overide !== null ? subtitle_overide : (
                <span>Please login using the fields below.</span>
              )}
            </div> */}
            { error_message && (
              <div className="error-container">
                <span className="error-message">{error_message}</span>
              </div>
            )}
          </div>
          <form className="signup-form" onSubmit={e => this.handle_login(e, this.state)}>
            <div className="form-input">
              <div className="form-control">
                {/* <label htmlFor="email">Email</label> */}
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={this.handle_change}
                  required
                  placeholder='Email address*'
                />
              </div>
              <div className="form-control">
                {/* <label htmlFor="password">Password</label> */}
                <input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handle_change}
                  required
                  placeholder='Password*'
                />
              </div>
            </div>
            <div className="forgot-password">
              <Link to="/reset/password">Forgot password?</Link>
            </div>
            { is_loading && (
              <img className="loader" src={loadingGif} alt="Loader"/>
            )}
            <div className="button-container">
              <input type="submit" value="Sign In" />
            </div>
          </form>
          <div className="register-login">
            New user? <Link to="/signup">Create an account</Link>
          </div>
        </div>
      </div>
    )
  }

  reset_form = () => {
    this.setState({
      email: '',
      password: '',
    })
  }

}

const mapStateToProps = state => {
  return {
    user: state.auth_data.user,
    logged_in: state.auth_data.logged_in,
    error_message: state.auth_data.error_message,
    is_checking_out: state.checkout_data.is_checking_out
  };
};


export default withRouter(connect(mapStateToProps, { submitLogin, submitMergeCart, submitGetCartServer })(Login));
