import {
  SUBMIT_STATUS_CHECK,
  SUBMIT_STATUS_CHECK_SUCCESS,
  SUBMIT_STATUS_CHECK_FAILURE,
  TEMP_AUTHENTICATE,
  ACCEPT_COOKIES,
  TOGGLE_RANDOM_ITEMS_CHECK,
  SUBMIT_GET_GLOBAL_SETTINGS,
  SUBMIT_GET_GLOBAL_SETTINGS_SUCCESS,
  SUBMIT_GET_GLOBAL_SETTINGS_FAILURE,
  SUBMIT_LOCATION_TRACKING,
  SUBMIT_LOCATION_TRACKING_SUCCESS,
  SUBMIT_LOCATION_TRACKING_FAILURE,
} from "./types";
import axios from 'axios'

import { submitUnauthorizedGet, submitUnauthorizedPost } from '../api.js'


export const checkBackendStatus = () => dispatch => {
  dispatch(submittingStatusCheck())
  axios
    .get(process.env.REACT_APP_API_URL+"/status/", { headers: { 'Content-Type': 'application/json' } } )
    .then(res => {
      dispatch(submitStatusCheckSuccess());
    })
    .catch(err => {
      dispatch(submitStatusCheckFailure());
    })
}

export const submitLocationTracking = (location) => dispatch => {
  submitUnauthorizedPost(
    "/track/location/",
    {location: location},
    () => { return {type:""}},
    () => { return {type:""}},
    () => { return {type:""}})
}




export function submittingStatusCheck() {
  return {
    type: SUBMIT_STATUS_CHECK
  }
}

export function submitStatusCheckSuccess() {
  return {
    type: SUBMIT_STATUS_CHECK_SUCCESS,
  }
}

export function submitStatusCheckFailure() {
  return {
    type: SUBMIT_STATUS_CHECK_FAILURE,
  }
}

export function tempAuthenticate(password) {
  if (password === "partytesters") {
    return {
      type: TEMP_AUTHENTICATE,
    }
  }
}

export function acceptCookies() {
  return {
    type: ACCEPT_COOKIES,
  }
}

export function toggleRandomItemsCheck() {
  return {
    type: TOGGLE_RANDOM_ITEMS_CHECK,
  }
}

export const getGlobalSettings = () => async dispatch => {
  return submitUnauthorizedGet('/global/settings/', submitGetGlobalSettings, submitGetGlobalSettingsSuccess, submitGetGlobalSettingsFailure)
}

const submitGetGlobalSettings = () => {
  return ({
    type: SUBMIT_GET_GLOBAL_SETTINGS,
  });
}

const submitGetGlobalSettingsSuccess = (data) => {
  return ({
    type: SUBMIT_GET_GLOBAL_SETTINGS_SUCCESS,
    payload: { data }
  });
}

const submitGetGlobalSettingsFailure = (error) => {
  return ({
    type: SUBMIT_GET_GLOBAL_SETTINGS_FAILURE,
    payload: { error }
  });
}

