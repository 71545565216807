import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import PartyPack from './PartyPack'

import './styles.scss'

import {
  getItems,
  submitGetCartServer,
} from 'redux/actions.js'

import { getCartValueInclVat, getTotal, getVat, getSubtotal, getShippingPriceExclVAT, getShippingPriceInclVAT } from 'utils/cart'

import crossIcon from "assets/images/cross-icon.svg"

class CartComponent extends React.Component {

  static propTypes = {
    cart: PropTypes.shape({}).isRequired,
    logged_in: PropTypes.bool,
    createNotification: PropTypes.func,
    cartOpen: PropTypes.bool,
    closeCart: PropTypes.func.isRequired,
  }

  static defaultProps = {
    cart: {
      party_packs:[],
    },
  }

  state = {
    cart: {
      party_packs: [],
    },
    pack: null,
    cartOpen: null,
  }


  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.submitGetCartServer()
    }
  }

  // getTotal(){
  //   const { cart } = this.props

  //   let subtotal = 0

  //   cart.party_packs.map(party_pack => {
  //     party_pack.items.map(item => {
  //       subtotal += parseFloat(item.price)*party_pack.amount
  //     })
  //   })

  //   return subtotal.toFixed(2)
  // }

  // getSubtotal(){
  //   return parseFloat(this.getTotal()/1.15).toFixed(2)
  // }

  // getVat(){
  //   return (parseFloat(parseFloat(this.getTotal()) - this.getSubtotal())).toFixed(2)
  // }

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  render() {
    const { cart, createNotification, closeCart, cartOpen } = this.props


    let slideAnimation = ''

    if (cartOpen === true) {
      slideAnimation = 'slide-in'
    } else if (cartOpen === false) {
      slideAnimation = 'slide-out'
    } else {
      slideAnimation='closed'
    }

    return (
      <div className="cart-summary">
        { cartOpen === true && (
          <div className="underlay" onClick={() => closeCart()} />
        )}
        <div className={`content ${slideAnimation}`}>
          <div className="heading">
            <h4 className="title">Cart</h4>
            <div className="cross-icon" onClick={() => closeCart()}>
              <img alt="Cross" src={crossIcon} />
            </div>
          </div>
          <div className="container">
          { cart.party_packs.length === 0 && (
            <div className="empty-cart">
              <p className="text">
                It looks like your cart is empty.<br/>
              </p>
              <div className="button-container">
                <Link
                  to={'/pick'}
                  onClick={() => closeCart()}
                >
                  <div className="landing-button pick">
                    Pick a Party Pack
                  </div>
                </Link>
              </div>
            </div>
          )}
          { cart.party_packs.length > 0 && (
            <div className="cart-container">
              <div className="cart-items">
                <div className="cart-card">
                  {cart.party_packs.map((party_pack, index) => {
                    return(
                      <PartyPack closeCart={ closeCart } createNotification={ createNotification } party_pack = { party_pack } index={ index } key={ this.uuidv4() }/>
                    )
                  })}
                </div>
              </div>
              <div className="fixed-container">
                <div className="total-price-container">
                  <p className="label">
                    Subtotal
                  </p>
                  <div className="total-price">
                    R{ getCartValueInclVat(cart) }
                  </div>
                </div>
                <div className="total-price-container">
                  <p className="label">
                    Shipping
                  </p>
                  <div className="total-price">
                    R{ getShippingPriceInclVAT(cart) }
                  </div>
                </div>
                <div className="total-price-container">
                  <p className="label">
                    VAT
                  </p>
                  <div className="total-price">
                    R{ getVat(cart) }
                  </div>
                </div>
                <div className="total-price-container">
                  <p className="label">
                    Total
                  </p>
                  <div className="total-price">
                    R{ getTotal(cart).toFixed(2) }
                  </div>
                </div>
                <div className="cart-button-container">
                  <Link to="/cart" onClick={() => closeCart()} className="cart-button">View Cart</Link>
                </div>
              </div>
              
            </div>
          )}
        </div>
        </div>
        
        
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    cart: state.party_data.cart,
    logged_in: state.auth_data.logged_in,
  };
};

export default withRouter(connect(mapStateToProps, { getItems, submitGetCartServer })(CartComponent));
