import { START_CHECKOUT } from "./types";


export const initialState = {
  is_checking_out: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case START_CHECKOUT:{
      return {
        ...state,
        is_checking_out: true,
      }
    }

    default:
      return state;
  }
}
