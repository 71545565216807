import React from 'react'
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { submitGetCartServer } from 'redux/actions.js'
import BasicInfoSummary from './BasicInfoSummary'
import loadingGif from "assets/images/loading.gif"

import './styles.scss'

class Checkout extends React.Component {

  static propTypes = {
    cart: PropTypes.shape({}).isRequired,
    user: PropTypes.shape({}),
    createNotification: PropTypes.func,
    is_loading: PropTypes.bool.isRequired,
  }

  state = {
    cart: this.props.cart,
    selected_courier: "courier_guy"
  }


  componentDidMount() {
    const { cart, submitGetCartServer } = this.props
    submitGetCartServer()
    this.setState({cart})
  }

  isCollapsed(pack_index) {
    const { cart } = this.state

    const pack = cart.party_packs[pack_index]

    if(pack){
      return pack['collapsed']
    }
    return true
  }

  togglePackCollapse(pack_index) {
    let { cart } = this.state
    let pack = cart.party_packs[pack_index]
    if ('collapsed' in pack){
      pack['collapsed'] = !pack['collapsed']
    } else {
      pack['collapsed'] = true
    }
    cart.party_packs[pack_index] = pack

    this.setState({cart})
  }

  getSubtotal(){
    const { cart } = this.props
    let subtotal = 0
    cart.party_packs.map((party_pack) => (subtotal += parseFloat(party_pack.price)*party_pack.amount))
    return subtotal.toFixed(2)
  }

  getVat(){
    return parseFloat(this.getSubtotal()*0.15).toFixed(2)
  }

  getTotal(){
    return (parseFloat(this.getVat()) + parseFloat(this.getSubtotal())).toFixed(2)
  }

  render() {
    const { is_loading } = this.props

    return (
      <div className="checkout">
        { is_loading && (
          <div className="loading-container">
            <img alt="Loading" className="loading" src={loadingGif}></img>
          </div>
        )}
        
        {/* <div className="heading">
          <div className="title"><b>Final</b> 3 steps!</div>
          <div className="description">Your order will be on it's way once payment has been made.</div>
        </div>

        <div className="step">
          <div className="step-number">
            1
          </div>
          <div className="step-title">
            <b>Check</b> Information
          </div>
        </div> */}

        <BasicInfoSummary />

        {/* <div className="step">
          <div className="step-number">
            2
          </div>
          <div className="step-title">
            <b>Check</b> Cart
          </div>
        </div> */}

        {/* <div className="cart-summary-container">
          <div className="heading">
            <div className="title"><b>Cart Summary</b></div>
            <div className="description">A last look at your cart summary.</div>
          </div>
          <CartSummaryTable />
        </div>

        <div className="step">
          <div className="step-number">
            3
          </div>
          <div className="step-title">
            <b>Pay</b> for your order
          </div>
        </div>

        <div className="pay-container">
          <Pay createNotification={this.props.createNotification}/>
        </div> */}


      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    cart: state.party_data.cart,
    is_loading: state.order_data.is_loading,
    error_message: state.order_data.error_message,
    user: state.auth_data.user_data.user,
  };
};

export default withRouter(connect(mapStateToProps, { submitGetCartServer })(Checkout));
