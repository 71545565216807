import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import dropdownIcon from "assets/images/dropdown-icon.svg"

import './styles.scss'

class Other extends React.Component {
  static propTypes = {
    filters: PropTypes.shape({}).isRequired,
    toggleFilter: PropTypes.func.isRequired,
    toggled: PropTypes.bool.isRequired,
    contentOnly: PropTypes.bool,
    updateFilters: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contentOnly: false
  }

  updateFilters = e => {
    let filters = Object.assign({}, this.props.filters)
    const name = e.target.name
    const filter_name = name.split('.')[2]
    filters.other[filter_name] = !filters.other[filter_name]
    this.props.updateFilters(filters)
  }

  renderContent(filters) {
    return (
      <div className="other-container">
        <div className="other-element-container">
          <div className="checkbox-container">
            <div className="checkbox">
              <input
                id="sweets-checkbox"
                name="filters.other.sweets"
                type="checkbox"
                checked={!filters.other.sweets}
                onChange={this.updateFilters}
                />
              <label htmlFor="sweets-checkbox"></label>
            </div>
          </div>
          <label className="text-label" htmlFor="sweets-checkbox">No Sweets</label>
        </div>
        <div className="other-element-container">
          <div className="checkbox-container">
            <div className="checkbox">
              <input
                id="guns-checkbox"
                name="filters.other.guns"
                type="checkbox"
                checked={!filters.other.guns}
                onChange={this.updateFilters}
                />
              <label htmlFor="guns-checkbox"></label>
            </div>
          </div>
          <label className="text-label" htmlFor="guns-checkbox">No Toy Guns</label>
        </div>
      </div>
    )
  }

  renderContentDropdown(filters) {
    return (
      <div className="other-filter-content">
        { this.renderContent(filters) }
      </div>
    )
  }

  render() {

    const { toggled, toggleFilter, filters, contentOnly } = this.props

    const toggledStyle= toggled ? {transform:'rotate(180deg)', paddingRight:'10px', paddingLeft:'0px'} : {}

    const filter_number = [!filters.other.sweets, !filters.other.guns].filter(Boolean).length
    
    let highlighted = false

    if(filter_number > 0) {
      highlighted = true
    }

    return (

      contentOnly ?
      (
        this.renderContent(filters)
      )
      :
      (
        <div className="other">
          <div className={`filter-heading ${highlighted ? 'active' : ''}`} onClick={() => toggleFilter('other')}>
            Other
            <img src={dropdownIcon} style={toggledStyle} />
            {highlighted && (
              <div className="filter-number">{filter_number}</div>
            )}
          </div>
          { toggled && (
            this.renderContentDropdown(filters)
          )}
        </div>
      )

      
    )
  }
}

const mapStateToProps = state => {
  return {
    filters: state.filter_data.filters,
    tags: state.filter_data.tags,
  };
};

export default withRouter(connect(mapStateToProps, { })(Other));
