import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import './styles.scss'
import CartSummary from 'components/CartSummary'
import CustomerInfo from 'containers/CreatePack/Cart/CartInfo/CustomerInfo'
import Page from 'containers/CreatePack/Cart/Page'

class CartInfo extends React.Component {

  static propTypes = {
  }

  render() {
    return (
      <div className="cart-info">
        <div className="container">
          <Page
            component={<CustomerInfo />}
            cartSummary={<CartSummary mobileEnabled/>}
          />
        </div> 
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
  };
};
export default withRouter(connect(mapStateToProps, { })(CartInfo));
