import React from 'react'
import './styles.scss'
import aboutUsSquiggle from 'assets/images/about-us-squiggle.svg'
import starSquiggleDot from 'assets/images/star-squiggle-dot.svg'
import whatWeOfferImageWebP from 'assets/images/about-page-cake-party.webp'
import howItWorksImageWebP from 'assets/images/party-packs-kids-party.webp'
import whatWeOfferImageJPEG from 'assets/images/about-page-cake-party.jpeg'
import howItWorksImageJPEG from 'assets/images/party-packs-kids-party.jpeg'

import { Helmet } from 'react-helmet'


import GetStarted from 'containers/Basic/Landing/GetStarted'



function About() {
  return (
    <div className="about">
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="We take the hassle out of getting your kids Party Packs sorted with a wide range of products & good value for money that'll make you really want to party." />
        <meta name="keywords" content="party packers, easy, party, kids party, fun, good value for money, packets, gift bags, party packets, events, convenience, surprise bag, customisable" />
      </Helmet>
      <div className="heading-container">
        <img alt="Squiggle" src={aboutUsSquiggle} />
        <div className="heading">About Party Packers</div>
        <div className="sub-heading">If you want the best party boxes and gift bags in town, with none of the hassle, then look no further! Surprise and delight your guests with our wide range of items; there is something for everyone.</div>
      </div>
      <div className="container">
        <div className="row">
          <div className="text-container">
            <img alt="Squiggle" src={starSquiggleDot} />
            <div className="heading">
              What we offer
            </div>
            <div className="text">
              We take the hassle out of getting your kids Party Packs sorted. Offering you a wide range of products and good value for money that will make you really want to party. Getting your party boxes together has never been this easy!
            </div>
          </div>
          <div className="image-container">
            <picture>
              <source srcSet={whatWeOfferImageWebP} type="image/webp" />
              <source srcSet={whatWeOfferImageJPEG} type="image/png" />
              <img alt="What We Offer" src={whatWeOfferImageWebP} />
            </picture>
          </div>
        </div>
        <div className="row reverse">
          <div className="image-container">
              <picture>
                <source srcSet={howItWorksImageWebP} type="image/webp" />
                <source srcSet={howItWorksImageJPEG} type="image/png" />
                <img alt="How It Works" src={howItWorksImageWebP} />
              </picture>
            </div>
            <div className="text-container">
              <img alt="Squiggle" src={aboutUsSquiggle} />
              <div className="heading">
                How it works
              </div>
              <div className="text">
                Just select or build your party pack, fill it with surprises and goodies, and we’ll deliver your order, perfectly packaged, directly to your door. With your party packs sorted, you’ll have more time to relax, cut yourself a piece of cake, and enjoy the party.            </div>
              </div>
          </div>
      </div>
      <GetStarted />
    </div>
  );
}

export default About;
