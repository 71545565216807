import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { NOTIFICATIONS } from 'constants/notifications.js'
import { PROVINCES } from 'constants/provinces.js'
import { submitUserUpdate, submitFetchUser, submitPasswordUpdate, submitEmailUpdate, saveSpecialInstructions } from 'redux/actions.js'
import { withRouter } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';


import Input from 'components/Input'

import Loader from 'components/Loader'
import ButtonLink from 'components/ButtonLink'
import ButtonSubmit from 'components/ButtonSubmit'

import LocationSearchInput from 'components/LocationSearchInput'

import './styles.scss'

class CustomerInfo extends React.Component {


  static propTypes = {
    createNotification: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
    is_loading: PropTypes.bool,
    createNotification: PropTypes.func,
  }

  state = {
    user: this.props.user,
    changing_email: false,
    new_email:"",
    reenter_email: "",
    changing_password: false,
    new_password: "",
    reenter_password: "",
    loading: false,
    special_instructions: this.props.special_instructions
  };

  componentDidMount(){
    this.props.submitFetchUser()
  }

  componentDidUpdate(){

  }

  handle_profile_update = (e, data) => {
    this.props.saveSpecialInstructions(this.state.special_instructions)
    e.preventDefault();
    this.setState({ loading:true })
    this.props.submitUserUpdate(data).then(res=>{
      this.setState({ loading:false })
      this.props.history.push('/cart/payment')
    }).catch(err => {
      this.setState({ loading:false })
      NotificationManager.error(err, 'Oh no!', 2000);
    })
    return
  }

  handle_password_update = () => {
    this.setState({ is_loading:true })
    const { new_password, reenter_password } = this.state
    if(new_password === reenter_password){
      const data = {
        password: new_password,
      }
      this.setState({new_password: "", reenter_password:"", changing_password:false})
      this.props.submitPasswordUpdate(data).then(res=>{
        this.props.createNotification(NOTIFICATIONS.SUCCESS, "Your password was updated successfully.")
      }).catch(err => {
        this.props.createNotification(NOTIFICATIONS.ERROR, "Oh no, something went wrong.")
      })
      return
    } else {
      this.props.createNotification(NOTIFICATIONS.ERROR, "Your passwords do not match")
    }
  }

  handle_email_update = () => {
    this.setState({ is_loading:true })
    const { new_email, reenter_email } = this.state
    if(new_email === reenter_email){
      const data = {
        email: new_email,
      }
      this.setState({new_password: "", reenter_password:"", changing_password:false})
      this.props.submitEmailUpdate(data).then(res=>{
        this.props.createNotification(NOTIFICATIONS.INFO, "We have sent a mail to " + this.state.user.email + " to confirm this action")
      }).catch(err => {
        this.props.createNotification(NOTIFICATIONS.ERROR, "Oh no, something went wrong.")
      })
      return
    } else {
      this.props.createNotification(NOTIFICATIONS.ERROR, "Your emails do not match")
    }
  }

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;

    const split = name.split('.')

    this.setState(prevstate => {
      const newState = { ...prevstate };
      if(split.length == 1) {
        newState.user[name] = value;
      } else if(split.length == 2) {
        newState.user[split[0]][split[1]] = value
      }
      return newState;
    });
  };

  handle_change_password = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState
    })
  }

  handle_special_instructions_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState
    })
  }

  toggleChangePassword(state) {
    this.setState({changing_password: state})
  }

  toggleChangeEmail(state) {
    this.setState({changing_email: state})
  }

  autocompleteShippingAddress = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          shipping_address_1: address.streetAddress,
          shipping_address_2: address.suburb,
          shipping_city: address.city,
          shipping_province: address.province,
          shipping_postal_code: address.postalCode,
        }
      }
    })
  }

  handleShippingAddressChange = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          shipping_address_1: address,
        }
      }
    })
  }

  autocompleteBillingAddress = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          billing_address_1: address.streetAddress,
          billing_address_2: address.suburb,
          billing_city: address.city,
          billing_province: address.province,
          billing_postal_code: address.postalCode,
        }
      }
    })
  }

  handleBillingAddressChange = (address) => {
    this.setState({
      user: {
        ...this.state.user,
        profile: {
          ...this.state.user.profile,
          billing_address_1: address,
        }
      }
    })
  }


  render() {

    const { user, loading, special_instructions } = this.state

    return (
      <div className="customer-info">
        <div className="profile-container">
            <form className="profile-form" onSubmit={e => this.handle_profile_update(e, user)}>
              <div className="form-input">
                <div className="form-section">
                  <div className="address-heading">Contact information</div>
                  <div className="halves">
                    <div className="form-control">
                      <Input
                        type="text"
                        name="first_name"
                        value={user.first_name}
                        handle_change={this.handle_change}
                        required={true}
                        placeholder='First name*'
                      />
                    </div>
                    <div className="form-control">
                      <Input
                        type="text"
                        name="last_name"
                        value={user.last_name}
                        handle_change={this.handle_change}
                        required
                        placeholder='Last name*'
                      />
                    </div>
                  </div>

                  <div className="form-control">
                    <Input
                      type="text"
                      name="email"
                      value={user.email}
                      onChange={this.handle_change}
                      required
                      disabled
                      placeholder="Email address"
                    />
                  </div>
                  <div className="form-control">
                    <Input
                      type="text"
                      name="profile.cell_number"
                      value={user.profile.cell_number}
                      handle_change={this.handle_change}
                      required
                      placeholder="Cellphone number*"
                    />
                  </div>
                </div>
                <div className="form-section">
                  <div className="address-heading">Shipping Address</div>
                  <div className="form-control">
                    <LocationSearchInput label={"Address 1 (Street Address)"} autocompleteCallback={this.autocompleteShippingAddress} handleChange={this.handleShippingAddressChange} address={user.profile.shipping_address_1} />
                  </div>
                  <div className="form-control">
                    <Input
                      type="text"
                      name="profile.shipping_address_2"
                      value={user.profile.shipping_address_2}
                      handle_change={this.handle_change}
                      placeholder="Address 2 (Apartment, suite or space number)"
                    />
                  </div>
                  <div className="thirds">
                    <div className="form-control">
                      <Input
                        type="text"
                        name="profile.shipping_city"
                        value={user.profile.shipping_city}
                        handle_change={this.handle_change}
                        required
                        placeholder="City"
                      />
                    </div>
                    <div className="form-control">
                      <select
                        name="profile.shipping_province"
                        className="select"
                        onChange={this.handle_change}
                        value={user.profile.shipping_province}
                        required
                        placeholder="Province"
                        >
                        { Object.keys(PROVINCES).map(key => {
                          return(
                            <option key={key} value={PROVINCES[key]}>{PROVINCES[key]}</option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="form-control">
                      <Input
                        type="text"
                        name="profile.shipping_postal_code"
                        value={user.profile.shipping_postal_code}
                        handle_change={this.handle_change}
                        required
                        placeholder="Postal Code"
                      />
                    </div>
                  </div>
                  <div className="form-control">
                      <Input
                        type="text"
                        name="profile.shipping_country"
                        value={user.profile.shipping_country}
                        handle_change={this.handle_change}
                        disabled
                        required
                        placeholder="Country"
                      />
                  </div>
                  <div className="sub-heading">Special Delivery Instructions</div>
                  <div className="form-control">
                      <textarea
                        className="special-instructions-textarea"
                        type="text"
                        name="special_instructions"
                        value={special_instructions}
                        onChange={this.handle_special_instructions_change}
                        placeholder="Special Instructions for Delivery"
                      />
                  </div>
                </div>
                <div className="form-section">
                  <div className="address-heading">Billing Address</div>
                  <div className="form-control">
                    <LocationSearchInput label={"Address 1 (Street Address)"} autocompleteCallback={this.autocompleteBillingAddress} handleChange={this.handleBillingAddressChange} address={user.profile.billing_address_1} />
                  </div>
                  <div className="form-control">
                    <Input
                      type="text"
                      name="profile.billing_address_2"
                      value={user.profile.billing_address_2}
                      handle_change={this.handle_change}
                      placeholder="Address 2 (Apartment, suite or space number)"
                    />
                  </div>
                  <div className="thirds">
                    <div className="form-control">
                      <Input
                        type="text"
                        name="profile.billing_city"
                        value={user.profile.billing_city}
                        handle_change={this.handle_change}
                        required
                        placeholder="City"
                      />
                    </div>
                    <div className="form-control">
                      <select
                        name="profile.billing_province"
                        className="select"
                        onChange={this.handle_change}
                        value={user.profile.billing_province}
                        >
                        { Object.keys(PROVINCES).map(key => {
                          return(
                            <option key={key} value={PROVINCES[key]}>{PROVINCES[key]}</option>
                          )
                        })}
                        required
                      </select>
                    </div>
                    <div className="form-control">
                      <Input
                        type="text"
                        name="profile.billing_postal_code"
                        value={user.profile.billing_postal_code}
                        handle_change={this.handle_change}
                        required
                        placeholder="Postal Code"
                      />
                    </div>
                  </div>
                  
                  <div className="form-control">
                    <Input
                      type="text"
                      name="profile.billing_country"
                      value={user.profile.billing_country}
                      handle_change={this.handle_change}
                      required
                      disabled
                      placeholder="Country"
                    />
                  </div>

                </div>
              </div>
              { loading && (
                <Loader />
              )}
              { !loading && (
                <div className="form-submit">
                  <ButtonLink to="/cart" text="Go Back to Cart" color="green" invert />
                  <ButtonSubmit text="Continue" />
                </div>
              )}

            </form>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth_data.user_data.user,
    is_loading:state.auth_data.is_loading,
    special_instructions: state.order_data.special_instructions
  };
};

export default withRouter(connect(mapStateToProps, { submitUserUpdate, submitFetchUser, submitPasswordUpdate, submitEmailUpdate, saveSpecialInstructions })(CustomerInfo));
