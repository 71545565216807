import React, { useEffect, useState } from 'react'
import Land from './Land'
import GetStarted from './GetStarted'
import HowItWorks from './HowItWorks'
import Planning from './Planning'
import bouncingArrow from 'assets/lottie-animations/down-arrow-bouncing.json'
import { Helmet } from 'react-helmet'
import Lottie from 'lottie-react'
import './styles.scss'

function Home() {
  let scrollPosition = useScrollPosition();

  if (scrollPosition === undefined){
    scrollPosition = 0
  }

  return (
    <div className="main">
      <Helmet>
        <title>Party Packers</title>
        <meta name="description" content="You pick it, we pack it. Your kids party pack selection and delivery people." />
        <meta name="keywords" content="party packers, party, parties, kids, party packet, gift bag, convenient, fun, party packs, toys, sweets, surprise, delivered, birthday, event" />
      </Helmet>
      <div className="scroller">
        <div className="scroll-container" style={{opacity: (180-scrollPosition)/100}}>
          <div className="logo">
            <Lottie className="fun-loader" animationData={bouncingArrow} loop={true} style={{width:150, height:150}}/>
          </div>
        </div>
      </div>
      <Land />
      <HowItWorks />
      <Planning />
      <GetStarted />
    </div>
  );
}

function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(undefined);
  useEffect(() => {
    function handleScroll() {
      setScrollPosition(window.pageYOffset + 80);
    }
    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return scrollPosition;
}

export default Home;
