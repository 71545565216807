import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import { Helmet } from 'react-helmet'
import placeHolderIcon from "assets/images/image-coming-soon.jpg"
import crossIcon from "assets/images/cross-icon-white.svg"

import {
    addCurrentItem,
    removeCurrentItem,
    getItems
} from 'redux/actions.js'

import './styles.scss'

class IndividualItem extends React.Component {
  static propTypes = {

  }


  state = {
    id: '',
    item: null,
    loading: false,
  }

  slugify(str){
    return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  }

  componentDidMount() {
    this.setState({loading:true})
    this.props.getItems().then(() => {
        this.setState({loading:false})
        const id = this.props.match.params.id;
        const slug = this.props.match.params.slug;
        const item = this.findItem(id, slug)
    
        if(item){
            this.setState({item})
        }
    
    
        // console.log(id, slug)
        this.setState({id})
    }).catch(() => {
        this.setState({loading:false})
    })

  }

  selectItem(item){
    const { random_items_checked } = this.props

    if(!random_items_checked) {
      this.setState({notification_item: item, item_notification_modal: true})
    }

    this.props.addCurrentItem(item).then(res => {
        this.props.history.push('/build')
    })
  }

  deselectItem(item){
    this.props.removeCurrentItem(item).then(res => {
    })
  }

  clickItem = (item) => {
    if( item !== null) {
      const { current_pack } = this.props

      if( current_pack.find(i => i.item_code === item.item_code)) {
        this.deselectItem(item)
      } else {
        this.selectItem(item)
      }
    }
  }

  findItem(id, slug) {
    const { items } = this.props

    const item = items.find(item => {
        if(item.item_code === id & slug === this.slugify(item.item_description)){
            return true
        }
    })

    return item

  }




  render() {

    const { item, loading} = this.state

    const { current_pack } = this.props

    const item_image_url = item ? process.env.REACT_APP_STATIC_URL+'/img/items/'+item['item_code']+'.jpg' : ''
    const selected = item ? current_pack.find(o => o.item_code === item.item_code): null


    return (
      <div className="individual-item">
        { item ? (
            <div className="card">
                <Helmet>
                    <script type="application/ld+json">
                    {JSON.stringify(
                      {
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": item.item_description,
                        "image": [
                          item_image_url
                        ],
                        "description": item.item_description,
                        "sku": item.item_code,
                        "brand": {
                          "@type": "Brand",
                          "name": "Party Packers",
                          "url": "https://www.partypackers.co.za/"
                        },
                        "review": {
                          "@type": "Review",
                          "author": {
                            "@type": "Person",
                            "name": "Party Packers"
                          },
                          "positiveNotes": {
                            "@type": "ItemList",
                            "itemListElement": [
                              {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Great gift for a party packet"
                              },
                              {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Goes well in a gift bag"
                              },
                              {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "Perfect item for a surprise party packet"
                              },                              {
                                "@type": "ListItem",
                                "position": 4,
                                "name": "A great addition to your party pack"
                              }
                            ]
                          }
                        },
                        "shippingDetails": {
                          "@type": "OfferShippingDetails",
                          "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": "0",
                            "currency": "ZAR"
                          },
                          "shippingDestination": [
                            {
                              "@type": "DefinedRegion",
                              "addressCountry": "ZA",
                              "addressRegion": ["WC"]
                            }
                          ]
                        },
                        "offers": {
                          "@type": "Offer",
                          "url": `https://partypackers.co.za/item/${item.item_code}/${this.slugify(item.item_description)}`,
                          "priceCurrency": "ZAR",
                          "price": item.price.toString(),
                          "itemCondition": "https://schema.org/NewCondition",
                          "availability": "https://schema.org/InStock"
                        }
                      }
                    )}
                    </script>
                </Helmet>
                <div className="carousel-wrapper">
                    <div className="item-image-container">
                        <img alt="Party Item" className="item-image" src={item_image_url} onError={(e)=>{e.target.onerror = null; e.target.src=placeHolderIcon}} onClick={(e) => this.openImageModal(e, item) } />
                        <div className="item-info">
                            <div className="divider" />
                            <div className="text-box">
                            <h5 className="description">
                                {item['item_description']}
                            </h5>
                            <p className="price">
                                R{item['price']} <span className="vat-text">(incl. VAT)</span>
                            </p>
                            </div>
                        </div>
                        { selected ? 
                            (
                                <div className="added-to-pack-button" onClick={ () => this.clickItem(item) }>
                                    <div className="tick-container"><img alt="Remove" className="tick" src={ crossIcon } /></div> 
                                    <span className="remove-long-text">Remove from Pack</span><span className="remove-short-text">Remove</span>
                                </div>
                            )
                            :
                            (
                                <div className="add-to-pack-button" onClick={ () => this.clickItem(item) }>
                                    <div className="plus-container"><span className="plus">+</span></div> 
                                    <span className="add-long-text">Add to Pack</span><span className="add-short-text">Add</span>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    :
    (
        <>
        { loading ? (
            <div className="loader-container">
                <Loader />
            </div>
        ):
        (
            <div className="card">
                <h3>The item you are looking for was not found.</h3>
                <h4></h4>
            </div>
        )
        }
        </>

    )}

            <div className="button-container">
                <Link
                  to={'/pick'}
                >
                  <div className="landing-button pick">
                    Pick a Party Pack
                  </div>
                </Link>
              </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        current_pack: state.party_data.current_pack,
        items: state.item_data.items,
    };
  };

export default withRouter(connect(mapStateToProps, {addCurrentItem, removeCurrentItem, getItems})(IndividualItem));
