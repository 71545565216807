import React from 'react'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from "react-multi-carousel";
import IntegerSelect from 'components/IntegerSelect'

import "react-multi-carousel/lib/styles.css";

import {
  addPartyPack,
  submitGetPremadePacks,
  deletePartyPack,
  loadItemID,
  customisePremadePack,
  editPartyPack,
  updateQuantity
} from 'redux/actions.js'

import { connect } from 'react-redux'

import Loader from 'components/Loader'


import 'rc-slider/assets/index.css';
import './styles.scss'

import magnifyingGlass from "assets/images/search-solid.svg"
import placeHolderIcon from "assets/images/image-coming-soon.jpg"
import crossIcon from "assets/images/cross-icon-white.svg"

class PremadePack extends React.Component {
  static propTypes = {
    handle_form: PropTypes.func,
    premade_packs: PropTypes.array.isRequired,
    error_message: PropTypes.string,
    createNotification: PropTypes.func,
    party_pack: PropTypes.shape({}),
    openImageModal: PropTypes.func,
  }

  state = {
    premade_packs: this.props.premade_packs,
    modal_item: null,
    modal_pack: null,
    selected_packs: [],
    loading: false,
    quantity: 1,
  }

  selectPack(pack){
    this.setState({ selected_packs: [...this.state.selected_packs, pack], loading: true })
    pack.amount = this.state.quantity
    this.props.addPartyPack(pack, this.props.current_party).then(res => {
      let notification_message
      if(this.state.quantity === 1) {
        notification_message = pack.name + " has been added to your cart."
      } else {
        notification_message = pack.amount + " " + pack.name + "'s have been added to your cart."
      }
      this.props.createNotification("info", notification_message)
      this.setState({loading: false})
    }).catch(err => {
      this.setState({loading: false})
    })
  }

  deselectPack(pack){
    this.setState({ selected_packs: this.state.selected_packs.filter( item => item !== pack), loading: true})
    this.props.deletePartyPack(this.getIndex()).then((res) => {
      this.setState({loading: false})
      this.props.createNotification("warning", pack.name + " has been removed from your cart.")
      
    })
  }

  clickPack(pack){
    const { party_packs } = this.props

    const selected = party_packs.find(item => {
      if(item.id === pack.id) {
        return true
      }
      else if(item.original_pack_id === pack.id) {
        pack = item
        return true
      }
      return false
    })

    this.props.loadItemID(pack.id)

    if(selected){
      this.deselectPack(pack)
    } else {
      this.selectPack(pack)
    }
  }

  closeImageModal(){
    this.setState({ modal_item: null, modal_pack: null })
  }

  clickModalItem = () => {
    const { modal_pack } = this.state

    this.clickPack(modal_pack)
  }

  getIndex() {
    const { party_packs, party_pack } = this.props
    let party_pack_index
    party_packs.map((pack, index) => {
      if(party_pack.id === pack.original_pack_id || party_pack.id === pack.id) {
        party_pack_index = index
      }
    })

    return party_pack_index
  }

  handleCustomisePack = () => {
    const { party_pack } = this.props
    if(this.isSelected()) {
      const party_pack_index = this.getIndex()
      this.props.customisePremadePack(party_pack).then(() => {
        this.props.editPartyPack(party_pack_index).then(() => {
          this.props.history.push('/build')
        })
      })

    } else {
      this.props.customisePremadePack(party_pack).then(() => {
        this.props.history.push('/build')
      })
    }
  }

  isSelected() {
    const { party_packs, party_pack } = this.props
    let selected = false
    party_packs.find(item => {
      if(item.id === party_pack.id) {
        selected = true
        return true
      }
      else if(item.original_pack_id === party_pack.id) {
        selected = true
        return true
      }
      return false
    })
    return selected
  }

  handleQuantityInputChange = (event) => {
    let num = +event.target.value
    if(!isNaN(num)){
      if(num > 0 && num <= 100) {
        this.setState({ quantity: num })
      }
    }
  }

  handleUpdateQuantity = (amount) => {
    if(this.isSelected()) {
        const party_pack_index = this.getIndex()
        this.props.updateQuantity(party_pack_index, amount)
    }else {
      const new_num = this.state.quantity + amount
      if(new_num > 0) {
        this.setState({quantity: new_num})
      }
    }
  }

  getPackValue(){
    const { party_pack } = this.props 

    let total = 0
    party_pack.items.map(item => {
      total += parseFloat(item.price);
    })

    return total.toFixed(2)
  }

  onFocus = () => {
    this.setState({ focusing: true })
  }

  onBlur = (quantity) => {
    this.submitQuantityUpdate(quantity)
  }

  render() {
    const { party_packs } = this.props

    const { loading, quantity } = this.state

    let party_pack = this.props.party_pack

    let selected = party_packs.find(item => {
      if(item.id === party_pack.id) {
        return true
      }
      else if(item.original_pack_id === party_pack.id) {
        party_pack = item
        return true
      }
      return false
    })

    let items = party_pack['items'].map((item, i) => {
      const item_image_url = process.env.REACT_APP_STATIC_URL+'/img/items/'+item['item_code']+'.jpg'
      return (
        <div className="item-info" key={i}>
          <div className="item-image-container" onClick={() => this.props.openImageModal(item, party_pack) }>
            <img alt="Party Item" className="item-image" src={item_image_url} onError={(e)=>{e.target.onerror = null; e.target.src=placeHolderIcon}} />
            <img alt="Magnifying Glass" className="magnifying-glass" src={magnifyingGlass} />
          </div>
          <p className="text-box">
            {item['item_description']}
          </p>
        </div>
      )
    })

    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

    return (
        <div className={`premade-pack ${ selected ? 'selected' : ''}`}>
          <h4 className="description">
            {party_pack['name']}
          </h4>
          <div className="divider" />
          <Carousel className="items" responsive={responsive} showDots={true}>
            {items}
          </Carousel>
          <div className="footer-item-info">
            <div className="text-box">
              <p className="price">
                R{this.getPackValue()} <span className="vat-text">(incl. VAT)</span>
              </p>
            </div>
            <div className="button-container">
              { loading ? (
                <Loader />
              )
              :
              (
              <React.Fragment>

                { selected ? (
                    <div className="added-to-pack-button" onClick={ () => this.clickPack(party_pack) }>
                      <div className="tick-container">
                        <img alt="Remove" className="tick" src={ crossIcon } />
                      </div>
                      Remove from Cart
                    </div>
                  )
                  :
                  (
                    <>
                      <IntegerSelect
                        handleQuantityInputChange = { this.handleQuantityInputChange }
                        handleUpdateQuantity = { this.handleUpdateQuantity }
                        onBlur = { this.onBlur }
                        onFocus = { this.onFocus }
                        quantity = { selected ? party_pack.amount : quantity }
                        premadeStyle
                      />
                      <div className="add-to-pack-button" onClick={ () => this.clickPack(party_pack) }>
                        <div className="plus-container"><span className="plus">+</span></div> Add <b>&nbsp;{quantity}&nbsp;</b><span className="add-long-text">to Cart</span>
                      </div>
                    </>
                  ) 
                }
                </React.Fragment>
              )}
            </div>
            { selected ? (
              <div className="customise-pack-button" onClick={()=> this.handleCustomisePack()}>
                Edit Pack
              </div>
            )
            :
            (
              <div className="customise-pack-button" onClick={()=> this.handleCustomisePack()}>
                Customise Pack
              </div>
            )}
          </div>
        </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    current_party: state.party_data.current_party,
    premade_packs: state.premade_data.premade_packs,
    party_packs: state.party_data.cart.party_packs,
    error_message: state.premade_data.error_message,
    is_loading: state.party_data.is_loading,
    loading_item_id: state.party_data.loading_item_id
  };
};

export default withRouter(connect(mapStateToProps, { updateQuantity, editPartyPack, addPartyPack, submitGetPremadePacks, deletePartyPack, loadItemID, customisePremadePack})(PremadePack));
