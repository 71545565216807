import { VALIDATE_STORE_STRUCTURE } from './types.js'

export * from './parties/action.js'
export * from './signup/action.js'
export * from './auth/action.js'
export * from './checkout/action.js'
export * from './meta/action.js'
export * from './premade/action.js'
export * from './orders/action.js'
export * from './collapse/action.js'
export * from './items/action.js'
export * from './filters/action.js'
export * from './emails/action.js'
export * from './password/action.js'

export function validateStoreStructure() {
    return {
      type: VALIDATE_STORE_STRUCTURE
    }
}
  