import {
  RESET_FILTERS,
  UPDATE_FILTERS,
  UPDATE_FILTERS_PREMADE,
  UPDATE_SELECTION_TYPE,
  SUBMIT_GET_TAGS,
  SUBMIT_GET_TAGS_SUCCESS,
  SUBMIT_GET_TAGS_FAILURE
} from './types'

export const initialState = {
  tags: [],
  selection_type: 'choose',
  filters: {
    search_term:"",
    gender: ['B', 'G', 'E'],
    age_range: [1,12],
    price_range: [0,400],
    other:{
      sweets: true,
      guns: true,
    },
    tags: [],
    sort_by: "random"
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_FILTERS: {
      return {
        ...state,
        filters: {
          search_term:"",
          gender: ['B', 'G', 'E'],
          age_range: [1,12],
          price_range: [0,400],
          other:{
            sweets: true,
            guns: true,
          },
          tags: [],
          sort_by: "random"
        }
      }
    }
    case UPDATE_FILTERS: {
      const { filters } = action.payload
      return{
        ...state,
        filters
      }
    }
    case UPDATE_FILTERS_PREMADE: {
      const { filters } = action.payload
      return{
        ...state,
        premade: {
          ...state.premade,
          filters
        }
      }
    }
    case UPDATE_SELECTION_TYPE: {
      const { selection_type } = action.payload
      return{
        ...state,
        selection_type
      }
    }
    case SUBMIT_GET_TAGS: {
      return{
        ...state,
      }
    }
    case SUBMIT_GET_TAGS_SUCCESS: {
      const { tags } = action.payload
      return{
        ...state,
        tags
      }
    }
    case SUBMIT_GET_TAGS_FAILURE: {
      return{
        ...state,
      }
    }
    default:
      return state;
  }
}