export const SUBMITTING = "SUBMITTING"
export const SUBMIT_FAILURE = "SUBMIT_FAILURE"

export const SUBMIT_GET_EMAILS_SUCCESS = "SUBMIT_GET_EMAILS_SUCCESS"

export const SUBMIT_EMAIL_UPDATE = "SUBMIT_EMAIL_UPDATE";
export const SUBMIT_EMAIL_UPDATE_SUCCESS = "SUBMIT_EMAIL_UPDATE_SUCCESS";
export const SUBMIT_EMAIL_UPDATE_FAILURE = "SUBMIT_EMAIL_UPDATE_FAILURE";

export const SUBMIT_CONFIRM_EMAIL_CHANGE = "SUBMIT_CONFIRM_EMAIL_CHANGE";
export const SUBMIT_CONFIRM_EMAIL_CHANGE_SUCCESS = "SUBMIT_CONFIRM_EMAIL_CHANGE_SUCCESS";
export const SUBMIT_CONFIRM_EMAIL_CHANGE_FAILURE = "SUBMIT_CONFIRM_EMAIL_CHANGE_FAILURE";