import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { addCurrentPack, updateExistingPack } from 'redux/actions.js'
import deleteIcon from "assets/images/cross-icon.svg"
import minusIcon from "assets/images/minus-icon.svg"
import plusIcon from "assets/images/plus-icon.svg"
import dropDownIcon from "assets/images/dropdown-icon.svg"
import Loader from 'components/Loader'
import ContinueShoppingModal from './ContinueShoppingModal'

import 'react-multi-carousel/lib/styles.css';
import 'rc-slider/assets/index.css';
import './styles.scss'


class CreatePreview extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    addCurrentPack: PropTypes.func.isRequired,
    createNotification: PropTypes.func.isRequired,
    clickItem: PropTypes.func.isRequired,
  }

  static defaultProps = {
    items: []
  }

  state = {
    quantity: 1,
    focusing: false,
    mobileExpanded: false,
    loading: false,
    continueShoppingModal: false,
  }

  componentDidMount() {
  }

  getPricePerPack() {
    const { items } = this.props
    let total = 0
    items.map((item) => (total += parseFloat(item.price)))
    return total.toFixed(2)
  }

  getTotalPrice() {
    return (this.getPricePerPack() * this.state.quantity).toFixed(2)
  }

  validatePack() {
    const { items, createNotification, minimum_pack_value } = this.props

    if (items.length < 1) {
      createNotification("warning", "You have not added any items to your pack.")
      return false
    } else if (this.getPricePerPack() < minimum_pack_value){
      createNotification("warning", `Your Party Pack needs to have a minumum value of R${minimum_pack_value}.`)
      return false
    } else {
      return true
    }
  }

  addPackToCart() {
    const { addCurrentPack, createNotification } = this.props
    const { quantity } = this.state

    if (this.validatePack()) {
      this.setState({loading: true})
      addCurrentPack(quantity).then(() => {
        this.setState({loading: false})
        createNotification("info", "Your custom pack has been added to your cart.")
        this.setState({continueShoppingModal: true})
      })
    }
  }

  removeItem(item) {
    this.props.clickItem(item)
  }

  handleUpdateQuantity(amount){
    if((this.state.quantity + amount > 0)) {
      this.setState({quantity: this.state.quantity + amount})
    }
  }

  handleQuantityInputChange = (event) => {
    let num = +event.target.value
    if(!isNaN(num)){
      this.setState({ quantity: num })
    }
  }

  cancelUpdateQuantity = () => {
    this.setState({ focusing: false, quantity: this.props.party_pack.amount})
  }

  onFocus = () => {
    this.setState({ focusing: true })
  }

  onBlur = (quantity) => {
    let num = quantity
    if(!isNaN(num) && quantity > 0){
      this.setState({ quantity: num })
    } else {
      this.setState({ quantity: 1})
    }
  }

  renderPackPrice(){
    return(
      <div className="item" key="price-per-pack">
        <p className="description">
          <b>Price per pack</b>
        </p>
        <h5 className="price">
          R{ this.getPricePerPack() }
        </h5>
        <div className="close-container" >
          {/* <img src={ deleteIcon } className="close-icon" /> */}
        </div>
      </div>
    )
  }

  renderTotalPrice(){
    return(
      <div className="item" key="total-price-pack">
        <p className="description">
          <b>Total</b>
        </p>
        <h5 className="price">
          R{ this.getTotalPrice() }
        </h5>
        <div className="close-container" >
          {/* <img src={ deleteIcon } className="close-icon" /> */}
        </div>
      </div>
    )
  }

  handleUpdatePack = () => {
    const { createNotification } = this.props
    if (this.validatePack()) {
      this.props.updateExistingPack().then( () => {
        this.setState({continueShoppingModal: true})
        createNotification("info", "Your pack has been updated.")
      })
    }
  }

  render() {
    const { items, edit_pack_index } = this.props

    const { quantity, mobileExpanded, loading, continueShoppingModal } = this.state

    const editting = edit_pack_index !== null

    return (
      <React.Fragment>
        { continueShoppingModal && (
          <ContinueShoppingModal closeFunc={()=>this.setState({continueShoppingModal: false})} />
        )}
        <div className={`create-preview ${ mobileExpanded?'expanded' : '' }`}>
          <div className="container">
            <div className="preview-heading">
              <h4 className="title">Your party pack</h4>
              <div className="dropdown-container" onClick={ () => this.setState({ mobileExpanded: !mobileExpanded }) }>
                <img alt="Dropdown" className={`dropdown-icon ${ mobileExpanded ? 'drop-collapsed': ''}`} src={dropDownIcon} />
              </div>
            </div>
            <div className="mobile-item-amount">
              { items.length } items
            </div>
            { !mobileExpanded && (
              <React.Fragment>
                { this.renderTotalPrice() }
              </React.Fragment>
            )}

            <div className="items">
              { items.length > 0 ? (
                <React.Fragment>
                { items.map(item => {
                  return(
                    <div className="item" key={item.item_code}>
                      <p className="description">
                        { item.item_description }
                      </p>
                      <h5 className="price">
                        R{ item.price }
                      </h5>
                      <div className="close-container" onClick={() => this.removeItem(item)}>
                        <img alt="Delete" src={ deleteIcon } className="close-icon" />
                      </div>
                    </div>
                  )
                })}
                  { this.renderPackPrice() }
                  <div className="pack-amount-container">
                    <p className="label">
                      Number of packs
                    </p>
                    <div className="pack-amount">
                      <div className="grey-container">
                        <div onMouseDown={() => this.handleUpdateQuantity(-1)}>
                          <img className="minus-icon" src={minusIcon} alt="minus"/>
                        </div>
                        <div className="input-container">
                          <input className="quantity-input" value={ quantity } onChange={ this.handleQuantityInputChange } onFocus={ this.onFocus } onBlur={ () => this.onBlur(quantity) }/>
                        </div>
                        <div onMouseDown={() => this.handleUpdateQuantity(1)}>
                          <img className="plus-icon" src={plusIcon} alt="plus"/>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.renderTotalPrice()}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h5 className="no-items">
                    Your party pack is currently empty
                  </h5>
                  <p className="no-items-small-text">
                    Start building your party pack by adding a few items.
                  </p>
                </React.Fragment>
              )}
              <p className="edit-small-text">
                You will be able to edit the pack and select how many packs you want at checkout.
              </p>
            </div>
            <div className="button-container">
              { loading ? (
                <Loader />
              )
              :
              (
                <React.Fragment>
                  { !editting ? 
                  (
                  <div className="submit-button" onClick={() => this.addPackToCart()} >
                    Add Party Pack to Cart
                  </div>
                  )
                  :
                  (
                  <div className="submit-button" onClick={() => this.handleUpdatePack()}>
                    Update Party Pack
                  </div>
                  )}
                </React.Fragment>
              )}
              
                </div>
            </div>
            <p className="disclaimer-tiny-text">
              Please note: where images show multiple items or full sets, you will be adding one of your selected item and not the full set. Item colour selection is random and based on stock availability.
            </p>
          </div>
          
          

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    current_pack: state.party_data.current_pack,
    edit_pack_index: state.party_data.edit_pack_index,
    minimum_pack_value: state.meta_data.global_settings.minimum_pack_value
  };
};


export default withRouter(connect(mapStateToProps, { addCurrentPack, updateExistingPack })(CreatePreview));
