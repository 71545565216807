import {
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_CANCEL_ORDER_SUCCESS,
  SUBMIT_GET_ORDERS_SUCCESS,
  SUBMIT_GET_ORDER_SUCCESS,
  SUBMIT_RETRY_PAYMENT_SUCCESS,
  SAVE_SPECIAL_INSTRUCTIONS,
} from "./types";

export const initialState = {
  orders:[],
  is_loading:false,
  error_message: null,
  special_instructions: ""
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_ORDER_SUCCESS: {
      const { data } = action.payload
      return {
        ...state,
        orders: [
          ...state.orders,
          data,
        ],
      }
    }

    case SUBMIT_CANCEL_ORDER_SUCCESS: {
      const { orders } = action.payload
      return {
        ...state,
        is_loading: false,
        error_message: null,
        orders,
      }
    }

    case SUBMIT_GET_ORDERS_SUCCESS: {
      const orders = action.payload.data;
      return{
        ...state,
        orders,
        is_loading: false,
        error_message: null,
      }
    }

    case SUBMIT_GET_ORDER_SUCCESS: {
      return{
        ...state,
        is_loading: false,
        error_message: null,
      }
    }

    case SUBMIT_RETRY_PAYMENT_SUCCESS: {
      const new_order = action.payload;
      return{
        ...state,
        orders: [...state.orders.map(order => {
          if(order.id === new_order.id){
            return new_order
          }
          return order
        })],
        is_loading: false,
        error_message: null,
      }
    }

    case SAVE_SPECIAL_INSTRUCTIONS: {
      const special_instructions = action.payload;
      return{
        ...state,
        special_instructions
      }
    }

    default:
      return state;
  }
}
