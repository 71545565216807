import React from 'react'
import { Link } from 'react-router-dom'

import visaLogo from "assets/images/visa.svg"
import mastercardLogo from "assets/images/mastercard.svg"
import partyPackersLogo from "assets/images/party-packers-logo.svg"

// import facebookLogo from "assets/images/socials/facebook.svg"
// import twitterLogo from "assets/images/socials/twitter.svg"
// import youtubeLogo from "assets/images/socials/youtube.svg"


import './styles.scss'

function Footer() {
  return (
    <div className="footer">
      <div className="banner"></div>
      <div className="container">
        {/* <div className="email">
          <img alt="Party Packers" className="party-packers-logo" src={ partyPackersLogo } />
          <div className="heading">Stay in the loop.</div>
          <div className="text">Sign up for updates on all things Party Packers.</div>
          <div className="inputs">
            <input className="email" placeholder="Enter your email address"/>
          </div>
          <div className="sign-up-button">
            Sign Up
          </div>
        </div> */}
        <div className="links">
            <div className="columns">
              <div className="column">
                <div className="heading">HELP</div>
                <ul className="list">
                  <li><Link className="link" to="/contact">Contact</Link></li>
                  <li><Link className="link" to="/faq">FAQs</Link></li>
                </ul>
              </div>
                <div className="column">
                  <div className="heading">My Account</div>
                  {   localStorage.getItem('token') ?
                    <ul className="list">
                      <li><Link className="link" to="/account/basic">My Account</Link></li>
                      <li><Link className="link" to="/account">Track My Order</Link></li>
                      <li><Link className="link" to="/account/orders">My Orders</Link></li>
                    </ul>
                    :
                    <ul className="list">
                      <li><Link className="link" to="/login">Login</Link></li>
                      <li><Link className="link" to="/signup">Signup</Link></li>
                    </ul>
                }
                </div>
              <div className="column">
                <div className="heading">Legal</div>
                <ul className="list">
                  <li><Link className="link" to="/returnsandrefunds">Returns & Refunds</Link></li>
                  <li><Link className="link" to="/shipping">Shipping</Link></li>
                  <li><Link className="link" to="/privacypolicy">Privacy Policy</Link></li>
                </ul>
              </div>
              <div className="column">
                <div className="heading">PAYMENT OPTIONS</div>
                <ul className="list list-inline">
                  <li><img alt="Visa" className="payment-icon" src={visaLogo} /></li>
                  <li><img alt="Master Card" className="payment-icon" src={mastercardLogo} /></li>
                </ul>
              </div>
          </div>
          <div className="columns social-columns">
            {/* <div className="column">
                <div className="heading">SOCIAL</div>
                <ul className="list social-list-inline">
                  <li><img className="social-icon" src={facebookLogo} /></li>
                  <li><img className="social-icon" src={twitterLogo} /></li>
                  <li><img className="social-icon" src={youtubeLogo} /></li>
                </ul>
              </div> */}
              {/* <div className="column">
                <div className="heading">PAYMENT OPTIONS</div>
                <ul className="list list-inline">
                  <li><img alt="Visa" className="payment-icon" src={visaLogo} /></li>
                  <li><img alt="Master Card" className="payment-icon" src={mastercardLogo} /></li>
                </ul>
              </div> */}
          </div>
        </div>
      </div>
      <div className="banner">
        {/* <div className="socials">
          <img className="social-icon" src={facebookLogo} />
          <img className="social-icon" src={twitterLogo} />
          <img className="social-icon" src={youtubeLogo} />
        </div> */}
        {/* <div className="payment-options">
          <div className="title">
            Payment options
          </div>
          <div className="icon-container">
            <img alt="Master Card" className="payment-icon" src={mastercardLogo} />
            <img alt="Visa" className="payment-icon" src={visaLogo} />
          </div>
        </div> */}
        <div className="trade-mark-container">
          <div className="trade-mark">PARTY PACKERS (PTY) LTD Copyright © 2022</div>
          <div className="rights">All Rights Reserved.</div>
        </div>
      </div>
      
    </div>
  );
}

export default Footer;
