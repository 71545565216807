import React from 'react'
import { withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Select from 'react-select';
import dropdownIcon from "assets/images/dropdown-icon.svg"

import './styles.scss'

class Gender extends React.Component {
  static propTypes = {
    filters: PropTypes.shape({}).isRequired,
    toggled: PropTypes.bool.isRequired,
    toggleFilter: PropTypes.func.isRequired,
    contentOnly: PropTypes.bool,
    updateFilters: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contentOnly: false
  }

  onValueChange = event => {
    let filters  = Object.assign({}, this.props.filters)
    if((typeof filters.gender === 'string') | (typeof filters.gender === 'number')) {
      filters.gender = ['B', 'G', 'E'] 
    } else {
      if(filters.gender.includes(event.target.value)){
        filters.gender = filters.gender.filter(e => e !== event.target.value)
      } else {
        filters.gender.push(event.target.value)
      }
    }

    this.props.updateFilters(filters)
  }

  renderContent(filters) {
    let gender = filters.gender
    if((typeof gender === 'string') | (typeof gender === 'number')) {
      gender = ['B', 'G', 'E'] 
    } else {
      gender = filters.gender
    }

    return (
      <div className="gender">
        <div className="clickables">
          <div className="preferred-gender">
            <ul className="filter-list">
              <li>
                <input
                  name="gender"
                  value="B"
                  type="checkbox"
                  checked={gender.includes('B')}
                  onChange={this.onValueChange}
                />
                <label>Boy</label>
              </li>
              <li>
                <input
                  name="gender"
                  value="G"
                  type="checkbox"
                  checked={gender.includes('G')}
                  onChange={this.onValueChange}
                />
                <label>Girl</label>
              </li>
              <li>
                <input
                  name="gender"
                  value="E"
                  type="checkbox"
                  checked={gender.includes('E')}
                  onChange={this.onValueChange}
                />
                <label>Neutral</label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
    
  }

  renderContentDropdown(filters) {
    return (
      <div className="gender-filter-content">
        { this.renderContent(filters) }
      </div>
    )
  }

  render() {

    const { toggled, filters, tags, toggleFilter, contentOnly } = this.props

    const highlighted = ( filters.gender.length !== 3)
    const highlighted_num = 3 - filters.gender.length
    const toggledStyle= toggled ? {transform:'rotate(180deg)', paddingRight:'10px', paddingLeft:'0px'} : {}

    const options = tags.map(tag => {
      if (tag){
        return { value: tag, label: tag.toLowerCase() }
      }
    })
    return (
      contentOnly ? (
        this.renderContent(filters)
      )
      :
      (
        <div className="gender-filter">
          <div className={`filter-heading ${highlighted ? 'active' : ''}`} onClick={ () => toggleFilter('gender') }>
            Gender
            <img src={ dropdownIcon } style={toggledStyle} />
            { highlighted && (
              <div className="filter-number">{highlighted_num}</div>
            )}
          </div>
          { toggled && (
            this.renderContentDropdown(filters)
          )}
        </div>
      )
    )
  }
}

const mapStateToProps = state => {
  return {
    tags: state.filter_data.tags,
  };
};

export default withRouter(connect(mapStateToProps, {  })(Gender));
