export const COURIER_GUY_STATUS_MAP = {
    'submitted': 'Submitted',
    'collection-unassigned': 'Collection Unassigned',
    'collection-assigned': 'Awaiting Collection',
    'collection-rejected': 'Collection Rejected',
    'collection-exception': 'Collection Exception',
    'collection-failed-attempt': 'Collection Failed Attempt',
    'collected': 'Collected',
    'at-hub': 'At Courier Guy Hub',
    'returned-to-hub': 'Returned to Courier Guy Hub',
    'in-transit': 'In Transit',
    'at-destination-hub': 'At Courier Guy Destination Hub',
    'delivery-unassigned': 'Delivery Unassigned',
    'delivery-assigned': 'Delivery Assigned',
    'delivery-rejected': 'Delivery Rejected',
    'out-for-delivery': 'Out For Delivery',
    'delivery-exception': 'Delivery Exception',
    'delivery-failed-attempt': 'Failed Delivery Attempt',
    'in-locker': 'In Locker',
    'delivered': 'Delivered',
    'cancelled': 'Cancelled',
}
