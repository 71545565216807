

export const SUBMIT_STATUS_CHECK = "SUBMIT_STATUS_CHECK";
export const SUBMIT_STATUS_CHECK_SUCCESS = "SUBMIT_STATUS_CHECK_SUCCESS";
export const SUBMIT_STATUS_CHECK_FAILURE = "SUBMIT_STATUS_CHECK_FAILURE";
export const TEMP_AUTHENTICATE = "TEMP_AUTHENTICATE";
export const ACCEPT_COOKIES = "ACCEPT_COOKIES";
export const TOGGLE_RANDOM_ITEMS_CHECK = "TOGGLE_RANDOM_ITEMS_CHECK";
export const SUBMIT_GET_GLOBAL_SETTINGS = "SUBMIT_GET_GLOBAL_SETTINGS";
export const SUBMIT_GET_GLOBAL_SETTINGS_SUCCESS = "SUBMIT_GET_GLOBAL_SETTINGS_SUCCESS";
export const SUBMIT_GET_GLOBAL_SETTINGS_FAILURE = "SUBMIT_GET_GLOBAL_SETTINGS_FAILURE";
export const SUBMIT_LOCATION_TRACKING = "SUBMIT_LOCATION_TRACKING";
export const SUBMIT_LOCATION_TRACKING_SUCCESS = "SUBMIT_LOCATION_TRACKING_SUCCESS";
export const SUBMIT_LOCATION_TRACKING_FAILURE = "SUBMIT_LOCATION_TRACKING_FAILURE";






