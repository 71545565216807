import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group';
import PartyPack from 'components/CartComponent/PartyPack'
import CartSummary from 'components/CartSummary'
import FullPageHeader from 'components/FullPageHeader'
import { Helmet } from 'react-helmet'

import './styles.scss'

import {
  getItems,
  submitGetCartServer,
  startCheckout,
} from 'redux/actions.js'

import backgroundImage from "assets/images/watermelon-gummy-candy-2.jpg"


class Cart extends React.Component {

  static propTypes = {
    cart: PropTypes.shape({}).isRequired,
    logged_in: PropTypes.bool,
    createNotification: PropTypes.func,
  }

  static defaultProps = {
    cart: {
      party_packs:[],
    }
  }

  state = {
    cart: {
      party_packs: [],
    },
    swapping: false,
    pack: null,
    swap_item: null,
    swap_pack_index: null,
    swap_item_index: null,
    swap_pack_price: null,
    party_date: '',
    startDate: null,
    scroll_offset: 0,
    pack_index: null,
    changing: false,
  }


  componentDidMount() {
    this.props.getItems()
    if (localStorage.getItem("token")) {
      this.props.submitGetCartServer()
    }
  }

  getSubtotal(){
    const { cart } = this.state
    let subtotal = 0
    cart.party_packs.map((party_pack) => (subtotal += parseFloat(party_pack.price)*party_pack.amount))
    return subtotal.toFixed(2)
  }

  getPackRealValue(pack){
    let total = 0
    pack.items.map((item) => (total += parseFloat(item.price)))
    return total.toFixed(2)
  }

  getVat(){
    return parseFloat(this.getSubtotal()*0.15).toFixed(2)
  }

  getTotal(){
    return (parseFloat(this.getVat()) + parseFloat(this.getSubtotal())).toFixed(2)
  }

  handle_change = e => {
    const name = e.target.name;
    let value = e.target.value;

    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  handle_checkout = () => {
    const { logged_in } = this.props

    this.props.startCheckout()

    if(logged_in){
      this.props.history.push('/checkout')
    } else {
      this.props.history.push({pathname: '/cart/signin', state: {
        from: this.props.location.pathname
      }})
    }
  }

  getMinDate = () => {
    var today = new Date()
    today.setDate(today.getDate()+7)
    var dateString = today.toISOString().split('T')[0];
    return dateString
  }

  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      // eslint-disable-next-line
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  render_cart() {
    const { cart, createNotification } = this.props

    return (
      <div className="cart">
        <img className="background-image" src={backgroundImage} alt="party-kids"/>

        <div className="container">
          { cart.party_packs.length === 0 && (
            <div className="empty-cart">
              <FullPageHeader headingText={'Cart'} subHeadingText={'It looks like your cart is empty!'} />
              <div className="button-container">
                <Link className="create-link" to={'/pick'}>
                  Create a party?
                </Link>
              </div>
            </div>
          )}
          { cart.party_packs.length > 0 && (
            <div className="cart-container">
              <div className="heading">
                <div className="title">Your Cart</div>
              </div>
              <div className="content">
                <div className="cart-items">
                  <div className="cart-card">
                    {cart.party_packs.map((party_pack, index) => {
                      return(
                        <div className="party-pack-container" key={index}>
                          <PartyPack createNotification={ createNotification } party_pack = { party_pack } index={ index } key={ this.uuidv4() }/>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="cart-summary">
                  <CartSummary pageIsCart={true} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
  render() {
    const { swapping } = this.state

    return(
      <div className="">
      <Helmet>
        <title>Checkout</title>
        <meta name="description" content="Once you’ve added all your awesome party essentials to your cart, head to checkout and complete payment." />
        <meta name="keywords" content="checkout, easy, party, packets, party packers, yoco, payment, cart, delivery" />
      </Helmet>
        <CSSTransition
          in={!swapping}
          timeout={500}
          classNames="slide-pack"
          mountOnEnter
          unmountOnExit
        >
          { this.render_cart() }
        </CSSTransition>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    cart: state.party_data.cart,
    logged_in: state.auth_data.logged_in,
  };
};
export default withRouter(connect(mapStateToProps, { getItems, submitGetCartServer, startCheckout })(Cart));
