export const SUBMITTING = "SUBMITTING"
export const SUBMIT_FAILURE = "SUBMIT_FAILURE"

export const SUBMIT_LOGIN = "SUBMIT_LOGIN";
export const SUBMIT_LOGIN_SUCCESS = "SUBMIT_LOGIN_SUCCESS";
export const SUBMIT_LOGIN_FAILURE = "SUBMIT_LOGIN_FAILURE";
export const SUBMIT_LOGOUT = "SUBMIT_LOGOUT";

export const RESET_REDUX = "RESET_REDUX";

export const SUBMIT_UPDATE_USER = "SUBMIT_UPDATE_USER";
export const SUBMIT_UPDATE_USER_SUCCESS = "SUBMIT_UPDATE_USER_SUCCESS";
export const SUBMIT_UPDATE_USER_FAILURE = "SUBMIT_UPDATE_USER_FAILURE";

export const SUBMIT_FETCH_USER = "SUBMIT_FETCH_USER";
export const SUBMIT_FETCH_USER_SUCCESS = "SUBMIT_FETCH_USER_SUCCESS";
export const SUBMIT_FETCH_USER_FAILURE = "SUBMIT_FETCH_USER_FAILURE";

