import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { submitLocationTracking, getGlobalSettings, tempAuthenticate, submitGetPremadePacks, getTags, validateStoreStructure, submitGetCartServer, getItems, resetRedux, checkBackendStatus, submitFetchUser } from './redux/actions.js'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';

import ReactPixel from 'react-facebook-pixel';

import Header2 from './components/Shared/Header2'
import Footer from './components/Shared/Footer'
import CookiesConsent from './components/CookiesConsent'
import ScrollToTop from './components/ScrollToTop'

import BasicInfo from './containers/Auth/BasicInfo'
import Landing from './containers/Basic/Landing'
import Contact from './containers/Basic/Contact'
import About from './containers/Basic/About'
import Success from './containers/Activation/Success'
import Account from './containers/Account'
import Cart from './containers/CreatePack/Cart'
import CartSignin from './containers/CreatePack/Cart/CartSignin'
import CartInfo from './containers/CreatePack/Cart/CartInfo'
import CartPayment from './containers/CreatePack/Cart/CartPayment'
import Signup from './containers/Auth/Signup'
import IndividualItem from './containers/CreatePack/Packs/IndividualItem'
import IndividualPack from './containers/CreatePack/Packs/IndividualPack'
import VerifyEmail from './containers/Auth/VerifyEmail'
import Login from './containers/Auth/Login'
import OrderResponse from './containers/CreatePack/OrderResponse'
import PageNotFound from './containers/Auth/PageNotFound'
import PasswordReset from './containers/Auth/PasswordReset'
import PasswordResetInfo from './containers/Auth/PasswordResetInfo'
import PasswordResetID from './containers/Auth/PasswordResetID'
import ConfirmEmailChange from './containers/Auth/ConfirmEmailChange'
import FAQ from './containers/Basic/FooterPages/FAQ'
import PrivacyPolicy from './containers/Basic/FooterPages/PrivacyPolicy'
import ReturnsAndRefunds from './containers/Basic/FooterPages/ReturnsAndRefunds'
import Shipping from './containers/Basic/FooterPages/Shipping'
import BuildPack from './containers/CreatePack/Packs/BuildPack'
import PickPack from './containers/CreatePack/Packs/PickPack'
import TagManager from 'react-gtm-module'

import MaintenanceMode from './containers/MaintenanceMode'
import 'react-notifications/lib/notifications.css';

import './App.scss';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const tagManagerArgs = {
  gtmId: 'GTM-K9SJZN4'
}

TagManager.initialize(tagManagerArgs)

const routes = [
  { path: '/', name: 'Landing', Component: Landing, backend_status: true, login_required: false },
  { path: '/about', name: 'About', Component: About, login_required: false },
  { path: '/contact', name: 'Contact', Component: Contact, login_required: false },
  { path: '/account/:page?/:id?', name: 'Account', Component: Account, login_required: true },
  { path: '/update/info', name: 'BasicInfo', Component: BasicInfo, login_required: true },
  { path: '/build', name: 'BuildPack', Component: BuildPack, login_required: false },///premade
  { path: '/pick', name: 'PickPack', Component: PickPack, login_required: false },///premade
  { path: '/item/:id/:slug/', name: 'IndividualItem', Component: IndividualItem, login_required: false },
  { path: '/pack/:id/:slug/', name: 'IndividualPack', Component: IndividualPack, login_required: false },
  { path: '/cart', name: 'Cart', Component: Cart, login_required: false },
  { path: '/cart/signin', name: 'CartSignin', Component: CartSignin, login_required: false },
  { path: '/cart/information', name: 'CartInfo', Component: CartInfo, login_required: true },
  { path: '/cart/payment', name: 'CartPayment', Component: CartPayment, login_required: true },
  { path: '/signup', name: 'Signup', Component: Signup, login_required: false },
  { path: '/verify', name: 'VerifyEmail', Component: VerifyEmail, login_required: false },
  { path: '/login', name: 'Login', Component: Login, login_required: false },
  { path: '/order/resolve/:id', name: 'OrderResponse', Component: OrderResponse, login_required: false },
  { path: '/activation/success', name: 'Success', Component: Success },
  { path: '/reset/password', name: 'PasswordReset', Component: PasswordReset, login_required: false },
  { path: '/reset/info', name: 'PasswordResetInfo', Component: PasswordResetInfo, login_required: false },
  { path: '/reset/password/:id', name: 'PasswordResetID', Component: PasswordResetID, login_required: false },
  { path: '/confirm/email/change/:id', name: 'ConfirmEmailChange', Component: ConfirmEmailChange, login_required: false },
  { path: '/faq', name: 'FAQ', Component: FAQ, login_required: false },
  { path: '/privacypolicy', name: 'PrivacyPolicy', Component: PrivacyPolicy, login_required: false },
  { path: '/returnsandrefunds', name: 'ReturnsAndRefunds', Component: ReturnsAndRefunds, login_required: false },
  { path: '/shipping', name: 'Shipping', Component: Shipping, login_required: false },
]

class App extends Component {

  static props = {
    redux_structure_ok: PropTypes.bool.isRequired,
    temp_authenticated: PropTypes.bool.isRequired,
    cookies_consent: PropTypes.bool.isRequired,
  }

  state = {
    show_login: false,
    show_signup: false,
    signup_state: 'in_progress',
    displayed_form: '',
    username: '',
    email_address: '',
    error_message: '',
  };

  isAuthenticated = () => {
    return localStorage.getItem('token') ? true : false
  }

  componentDidMount() {
    const { logged_in } = this.props

    this.props.submitGetPremadePacks()
    this.props.getGlobalSettings()
    this.props.checkBackendStatus()

    if(this.isAuthenticated()){
      this.props.submitFetchUser()
      this.props.submitGetCartServer()
    }

    this.props.getItems()
    this.props.getTags()

    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init('791635595416671', {}, options);
    ReactPixel.pageView();
  }

  



  createNotification(type, message){
    switch (type) {
      case 'info':
        NotificationManager.info(message);
        break;
      case 'success':
        NotificationManager.success(message, 'Success');
        break;
      case 'warning':
        NotificationManager.warning(message, '', 3000);
        break;
      case 'error':
        NotificationManager.error(message, 'Error...', 5000);
        break;
      default:
        NotificationManager.warning('Something went wrong...', 'Oh no!', 3000);
        break;
    }
  };

  handle_login = () => {
    this.setState({ logged_in: localStorage.getItem('token') ? true : false })
  }

  handle_logout = () => {
    localStorage.removeItem('token');
    this.setState({ logged_in: false, username: '' });
  };

  display_form = form => {
    if (form === 'login'){
      this.setState({show_login: true, show_signup: false})
    } else if (form === 'signup'){
      this.setState({show_signup: true, show_login: false})
    }
  };

  hide_form = () => {
    this.setState({show_signup: false, show_login: false})
  }

  createRoutes = (routes) => {
    return (
      [
        ...routes.map(({ path, Component, login_required }) => (
          <Route key={path} exact path={path}>
              <div className="page">
                <div className="page-container">
                  { login_required && (
                    (this.props.logged_in ? (
                      <Component createNotification = {this.createNotification} />
                    )
                    :
                    (
                      <PageNotFound />
                    )
                  )
                  )}
                  { !login_required && (
                    <Component createNotification = {this.createNotification} />
                  )}
                  <Footer/>
                </div>
              </div>
          </Route>
        )),
        <Route key="*" exact component={PageNotFound} />
      ]
    )
  }

  AnimatedSwitch = withRouter(({location}) => {
    const currentKey = location.pathname.split("/")[1] || "/";

    this.props.submitLocationTracking(location.pathname)

    return (
      <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={390}
            classNames="slide"
            unmountOnExit
          >
            <Switch location={location}>
              {this.createRoutes(routes)}
            </Switch>
        </CSSTransition>
      </TransitionGroup>
    )
  })

  render() {
    this.props.validateStoreStructure()
    const { global_settings, cookies_consent } = this.props
    // if(!redux_structure_ok) {
    //   if(window.confirm("We have detected a problem with your state storage. Do you want to reset your state?")) {
    //     localStorage.clear()
    //     this.props.resetRedux()
    //   }
    // }

    // if(!temp_authenticated) {
    //   const username = prompt("Password", "")
    //   tempAuthenticate(username)
    //   return (
    //     <div>You are not authorised.</div>
    //   )
    // }

    return (
      <div className="App" id="App">
        {/* <button className="dev-reset" onClick={() => this.props.resetRedux()}>Reinitialize Redux</button> */}
        <Router>
          <ScrollToTop />
          <>
            { !global_settings.maintenance_mode ? (
              <>
                <NotificationContainer />
                <Header2
                  logged_in={this.state.logged_in}
                  handle_logout={this.handle_logout}
                />
                {/* <NetworkDetector /> */}
                {/* { !cookies_consent && (
                  <CookiesConsent />
                ) } */}
                <this.AnimatedSwitch />
            </>
            )
            :
            (
              <MaintenanceMode />
            )}
          </>
        </Router>
      </div>
      )
  }
}




const mapStateToProps = state => {
  return {
    backend_status: state.meta_data.backend_status,
    logged_in: state.auth_data.logged_in,
    redux_structure_ok: state.meta_data.redux_structure_ok,
    temp_authenticated: state.meta_data.temp_authenticated,
    cookies_consent: state.meta_data.cookies_consent,
    global_settings: state.meta_data.global_settings,
  };
};

export default connect(mapStateToProps, { submitLocationTracking, getGlobalSettings, tempAuthenticate, submitGetPremadePacks, getTags, validateStoreStructure, submitGetCartServer, getItems, resetRedux, checkBackendStatus, submitFetchUser})(App);
