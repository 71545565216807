import React from 'react'
import './styles.scss'
import FullPageHeader from 'components/FullPageHeader'

import {Helmet} from 'react-helmet'

function ReturnsAndRefunds() {
  return (
    <div className="returns-and-refunds">
      <Helmet>
        <title>Returns and Refunds</title>
        <meta name="description" content="Returns and Refunds" />
        <meta name="keywords" content="returns and refunds, returns, stock availability, right to cancel, limitation of liability" />
      </Helmet>
      <FullPageHeader headingText={"Returns and Refunds"} />
      <div className="container">
        <h4 className="header">
            Returns and Refunds
        </h4>
        <div className="text">
          These Returns and Refunds terms and conditions apply to all purchases made by you, the customer, from us, Party Packers, a division of Melbro Brands. The terms and conditions set out here are in addition to those provided for in the Consumer Protection Act, no. 68 of 1968.<br/>
          Should you have any complaints, or suggestions (or praise!) please communicate with us through one of the following media:<br/><br/>
          <div className="section">
            Email: info@partypackers.co.za<br/>
            Post: Melbro Brands, PO Box 36, Epping Industria, 7475<br/>
            Phone: 0860 000 000<br/>
            Fax: 021 505 5600<br/>
          </div>
          <div className="section">
            <h4 className="header">
            Returns
            </h4>
            <div className="text">
              We want you to be happy with your purchase. If you are not completely satisfied, you can return the product to us and we will either replace it, or credit your account, subject to the below terms. This Policy applies to products bought from Party Packers itself.
              We do our best to ensure that the products we deliver to you are of a high quality, and in good condition. Should a product be damaged or the Party Pack missing any items at the time of delivery, please notify us immediately by contacting our sales team. 
              If you have received a product which is defective or otherwise of poor quality, please notify us as soon as possible. If a refund is agreed to, please note that we only refund to the payment method that you originally used (e.g. payment by credit card will be refunded to the same credit card)
            </div>
          </div>
          <div className="section">
            <h4 className="header">Stock Availability</h4>
            <div className="text">
              It is not possible for us, by the nature of our situation as importers, to warrant that we will at all times be in stock of the product/s offered for sale on this website. We will endeavour to maintain adequate stocks, in line with anticipated demand. If we are unable to honour your order at once, we will (a) notify you of this fact promptly and (b) take immediate steps to resolve the stock shortage. In such event, you will have the option to cancel your purchase, or accept suggested substitutions of the same value.
            </div>
          </div>
          <div className="section">
            <h4 className="header">Right to Cancel</h4>
            <div className="text">
              Should you change your mind with regards to your purchase, you have the right to cancel the order within 24 hours of your purchase, prior to the dispatch of the order.
              1. If the order has already been dispatched to you, such right will lapse 5 days after the product is delivered to you;
              2. In such an event, and unless the product is defective upon receipt, the costs involved in the return will be for your account.
            </div>
          </div>
          <div className="section">
            <h4 className="header">Limitation of Liability</h4>
            <div className="text">
              We only supply products for domestic and private use. We will, accordingly, bear no liability if you use the product for commercial or industrial purposes and suffer any form of damages as a result. We urge you to ensure all items purchased are used with proper care.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReturnsAndRefunds;
