import { 
  SUBMITTING,
  SUBMIT_FAILURE,
  REFRESH_CART,
  SUBMIT_GET_CART_SUCCESS,
  ADD_PARTY_PACK_LOCAL,
  SUBMIT_DELETE_PARTY_PACK_SUCCESS,
  DELETE_ACCESSORY_LOCAL,
  SUBMIT_DELETE_ACCESSORY_SUCCESS,
  SUBMIT_ADD_ACCESSORY_SUCCESS,
  SUBMIT_ACCESSORY_QUANTITY_UPDATE_SUCCESS,
  UPDATE_ACCESSORY_QUANTITY_LOCAL,
  ADD_ACCESSORY_LOCAL,
  SUBMIT_ADD_CURRENT_PACK_SUCCESS,
  UPDATE_PARTY_DATE,
  DELETE_PARTY_PACK,
  SUBMIT_MERGE_CART_SUCCESS,
  SUBMIT_CLEAR_LOCAL_CART,
  SUBMIT_ADD_PARTY_PACK_SUCCESS,
  SUBMIT_QUANTITY_UPDATE_SUCCESS,
  UPDATE_QUANTITY,
  ADD_CURRENT_ITEM,
  REMOVE_CURRENT_ITEM,
  ADD_CURRENT_PACK,
  LOAD_ITEM,
  DELETE_ITEM,
  SUBMIT_DELETE_ITEM_SUCCESS,
  CUSTOMISE_PREMADE_PACK,
  EDIT_PARTY_PACK,
  UPDATE_EXISTING_PACK_LOCAL,
  START_BUILDING_PACK,
  SUBMIT_UPDATE_EXISTING_PACK_SUCCESS,
  SUBMIT_REDEEM_VOUCHER,
  SUBMIT_REDEEM_VOUCHER_SUCCESS,
  SUBMIT_REMOVE_VOUCHER_SUCCESS
 } from "./types";

export const initialState = {
  cart:{
    party_date: null,
    party_packs: [],
    accessories: [],
  },
  current_pack: [],
  edit_pack_index: null,
  default_amount: 1,
  is_loading: false,
  error_message: null,
  loading_item_id: null,
}

export default function(state = initialState, action) {
  // return initialState;
  switch (action.type) {
    case SUBMITTING: {
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }
    case SUBMIT_FAILURE:{
      const error_message = action.payload.error_message;
      return {
        ...state,
        error_message,
        is_loading: false,
      }
    }
    case REFRESH_CART: {
      return initialState
    }
    case SUBMIT_GET_CART_SUCCESS: {
      const cart = action.payload.cart;
      return{
        ...state,
        cart: {
          ...cart,
          party_date: state.cart.party_date,
        },
        is_loading: false,
      }
    }

    case LOAD_ITEM: {
      const id = action.payload.id
      return {
        ...state,
        loading_item_id: id,
      }
    }

    case ADD_PARTY_PACK_LOCAL: {
      const party_pack = action.payload.content
      return {
        ...state,
        cart: {
          ...state.cart,
          party_packs: [
            ...state.cart.party_packs.values(),
            {
              ...party_pack,
              id: null,
              original_pack_id: party_pack.id,
            },
          ]
        },
        is_loading: false,
      }
    }

    case SUBMIT_ADD_PARTY_PACK_SUCCESS: {
      const cart = action.payload.cart;
      return{
        ...state,
        cart,
        loading_item_id: null,
      }
    }

    case SUBMIT_DELETE_PARTY_PACK_SUCCESS : {
      const cart = action.payload.cart;
      return {
        ...state,
        cart,
        is_loading: false,
        loading_item_id: null,
      }
    }

    case SUBMIT_DELETE_ITEM_SUCCESS : {
      const cart = action.payload.cart;
      return {
        ...state,
        cart,
        is_loading: false,
        loading_item_id: null,
      }
    }

    case DELETE_PARTY_PACK: {
      const { party_pack_index } = action.payload
      return {
        ...state,
        edit_pack_index: party_pack_index === state.edit_pack_index ? null : state.cart.party_packs.length > 1 ? state.edit_pack_index - 1 : null,
        current_pack: party_pack_index === state.edit_pack_index ? [] : state.current_pack,
        cart: {
          ...state.cart,
          party_packs: state.cart.party_packs.filter((party_pack, index) => {
            if(index !== party_pack_index){
              return party_pack
            }
            return null
          })
        },
        loading_item_id: null,
      }
    }

    case SUBMIT_ADD_ACCESSORY_SUCCESS : {
      const cart = action.payload.cart;
      return {
        ...state,
        cart,
        is_loading: false,
      }
    }

    case DELETE_ACCESSORY_LOCAL: {
      const { index } = action.payload

      return {
        ...state,
        cart: {
          ...state.cart,
          accessories: state.cart.accessories.filter((accessory, i) => {
            if(index !== i ){
              return accessory
            }
            return null
          })
        }
      }
    }
    
    case SUBMIT_DELETE_ACCESSORY_SUCCESS: {
      const { cart } = action.payload;
      return {
        ...state,
        cart,
        is_loading: false,
      }
    }

    case SUBMIT_QUANTITY_UPDATE_SUCCESS : {
      const cart = action.payload.cart
      return {
        ...state,
        cart,
        is_loading: false,
      }
    }

    case UPDATE_QUANTITY: {
      const { party_pack_index, amount } = action.payload
      return {
        ...state,
        cart: {
          ...state.cart,
          party_packs: state.cart.party_packs.map((party_pack,i) => {
            if(i === party_pack_index){
              if (parseInt(party_pack.amount) + parseInt(amount) >= 1){
                party_pack.amount = parseInt(party_pack.amount + amount)
                return party_pack
              }
            }
            if(party_pack.amount + amount < 1 ){
              party_pack.amount = 1
            }
            return party_pack
            })
          }
        }
    }


    case UPDATE_ACCESSORY_QUANTITY_LOCAL: {
      const { index, amount } = action.payload
      return {
        ...state,
        cart: {
          ...state.cart,
          accessories: state.cart.accessories.map((accessory, i) => {
            if (index === i) {
              accessory.quantity += amount
            }
            return accessory
          })
        }
      }
    }

    case SUBMIT_ACCESSORY_QUANTITY_UPDATE_SUCCESS : {
      const cart = action.payload.cart
      return {
        ...state,
        cart,
        is_loading: false,
      }
    }

    case SUBMIT_MERGE_CART_SUCCESS: {
      const { parties } = action.payload
      return {
        ...state,
        parties: parties,
        is_loading: false
      }
    }

    case SUBMIT_CLEAR_LOCAL_CART: {
      return {
        ...state
      }
    }

    case ADD_CURRENT_ITEM: {
      const item = action.payload.item;
      return {
        ...state,
        current_pack: state.current_pack.concat(item)
      }
    }

    case REMOVE_CURRENT_ITEM: {
      const current_item = action.payload.item;
      return {
        ...state,
        current_pack: state.current_pack.filter(item => item.item_code !== current_item.item_code)
      }
    }

    case ADD_ACCESSORY_LOCAL: {
      const item = action.payload.item;
      return {
        ...state,
        cart: {
          ...state.cart,
          accessories: state.cart.accessories.concat({
            quantity : 1,
            item
          })
        }
      }
    }

    case SUBMIT_ADD_CURRENT_PACK_SUCCESS: {
      const cart = action.payload.cart;
      return{
        ...state,
        cart,
        current_pack: []
      }
    }
    
    case ADD_CURRENT_PACK: {
      const quantity = action.payload.quantity

      return {
        ...state,
        cart: {
          ...state.cart,
          party_packs: state.cart.party_packs.concat({
            name:"Custom Pack",
            items: state.current_pack,
            amount: quantity,
          }),
        },
        current_pack: []
      }
    }

    case UPDATE_PARTY_DATE: {
      const date = action.payload.date;
      return {
        ...state,
        cart:{
          ...state.cart,
          party_date: date,
        }
      }
    }

    case DELETE_ITEM: {
      const { pack_index, item_index } = action.payload

      return {
        ...state,
        cart:{
          ...state.cart,
          party_packs: state.cart.party_packs.map((party_pack, i) => {
            if (pack_index === i) {
              let pack = party_pack
              let items = party_pack.items.filter((item, index) => {
                return (index !== item_index)
              })

              pack.items = items
              
              return pack
            }
            return party_pack
          })
        }
      }
    }

    case CUSTOMISE_PREMADE_PACK: {
      const pack = action.payload.pack;
      return {
        ...state,
        current_pack: pack.items,
        edit_pack_index: null,
      }
    }

    case EDIT_PARTY_PACK: {
      const party_pack_index = action.payload.party_pack_index
      return {
        ...state,
        edit_pack_index: party_pack_index
      }
    }

    case UPDATE_EXISTING_PACK_LOCAL: {
      const party_pack_index = state.edit_pack_index
      return {
        ...state,
        cart: {
          ...state.cart,
          party_packs: state.cart.party_packs.map((party_pack, i) => {
            if(i === party_pack_index) {
              let new_party_pack = party_pack
              new_party_pack.items = state.current_pack
              return new_party_pack
            } else {
              return party_pack
            }
          })
        }
      }
    }

    case START_BUILDING_PACK: {
      if(state.edit_pack_index !== null) {
        return {
          ...state,
          edit_pack_index: null,
          current_pack: []
        }
      } else {
        return {
          ...state,
        }
      }
    }

    case SUBMIT_UPDATE_EXISTING_PACK_SUCCESS: {
      const cart = action.payload.cart;
      return{
        ...state,
        cart,
        loading_item_id: null,
      }
    }

    case SUBMIT_REDEEM_VOUCHER_SUCCESS: {
      const { cart } = action.payload.data;
      return{
        ...state,
        cart: cart
      }
    }

    case SUBMIT_REMOVE_VOUCHER_SUCCESS: {
      const { cart } = action.payload.data;
      return{
        ...state,
        cart: cart
      }
    }


    default:
      return state;
  }
}
