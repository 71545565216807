import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Link, Redirect } from 'react-router-dom'
import './styles.scss'

import { submitOrder, refreshCart, submitGetOrdersRequest, submitRedeemVoucher } from 'redux/actions'

import { NotificationManager } from 'react-notifications';

import YocoPayment from './YocoPayment'

import { getTotal } from 'utils/cart'

import Input from 'components/Input'

class BasicInfoSummary extends React.Component {

  state = {
    loading: false,
    voucher_number: ''
  }


  handleVoucherNumberChange= (e) => {
    this.setState({voucher_number: e.target.value})
  }


  handleSubmitOrder(yoco_token){
    const { cart } = this.props

    if(getTotal(cart) < this.props.minimum_order_total) {
      NotificationManager.warning(`Your order total needs to be above R${this.props.minimum_order_total}.`, '', 3000);
      return
    }
    
    const selected_courier = "courier_guy"
    const special_instructions = this.props.special_instructions

    this.setState({loading: true})
    this.props.submitOrder(selected_courier, special_instructions, yoco_token).then(res => {
      this.props.refreshCart()
      this.setState({loading: false})
      // window.location.replace(res.payu_payment_url);
      // console.log(res)
      // console.log({"uuid":res.payfast_identifier})
      // window.payfast_do_onsite_payment({"uuid":res.payfast_identifier});
      NotificationManager.success("Payment was successful.", 'Success!', 3000)
      this.props.submitGetOrdersRequest()
      this.props.history.push(`/order/resolve/${res.order.id}`)
    }).catch(err => {
      console.log(err)
      NotificationManager.warning(err, 'Error...', 3000)
      this.setState({loading: false})
    })
  }

  submitVoucherNumber(){
    const { voucher_number } = this.state

    this.props.submitRedeemVoucher(voucher_number).then((response) => {
      NotificationManager.success(response.message, 'Success!', 3000)
    }).catch(err => {
      NotificationManager.warning(err, 'Error...', 3000)
    })


  }


  render() {
    const { user, cart } = this.props

    const { loading, voucher_number } = this.state


    return(
      <div className="basic-info-summary">
        <div className="cust-info">
          <div className="row">
            <div className="label">
              Contact
            </div>
            <div className="data">
              {user.first_name} {user.last_name}, {user.email}
            </div>

          </div>
          <div className="row">
            <div className="label">
              Address
            </div>
            <div className="data">
            {user.profile.shipping_address_1}, {user.profile.shipping_address_2}, {user.profile.shipping_city}, {user.profile.shipping_province}, {user.profile.shipping_postal_code}
            </div>

          </div>
        </div>
        {/*<div className="payment-info">
          <h4>
            Redeem a Voucher
          </h4>
          <div className="row">
            <div className="input-container">
              <Input
                type="text"
                name="voucher_number"
                value={voucher_number}
                handle_change={this.handleVoucherNumberChange}
                required={true}
                placeholder='Voucher Number'
              />
            </div>
            <div className="button-container voucher-button-container">
              <button type="submit" id="pay-button" className="redeem-voucher-button" onClick={() => this.submitVoucherNumber()}>
                Redeem Voucher
              </button>
            </div>
          </div>
        </div>*/}

        <YocoPayment
          key={getTotal(cart)}
          total={getTotal(cart)}
        />



        {/* <div className="payment-info">
          <div className="row">
            <form id="payment-form">
              <div className="one-liner">
                <div id="card-frame" className="card-frame">
                </div>
                <div className="button-container">
                  { loading ? (
                    <>
                      <Loader />
                    </>
                  )
                :
                (
                  <>
                    <Link className="pay-now-button" to="/cart/information">
                      Go Back
                    </Link>
                    <button type="submit" id="pay-button" className="pay-now-button">
                      Pay R{this.getTotal()}
                    </button>
                  </>
                )}

                </div>

              </div>
              <p className="success-payment-message" />
            </form>
          </div>
        </div> */}

        {/* <div className="payment-info">
          <form id="payment-form" method="POST">
            <div class="one-liner">
              <div id="card-frame">
              </div>
              <button id="pay-button">
                PAY ZAR 24.99
              </button>
            </div>
            <p class="success-payment-message" />
          </form>
        </div> */}


        {/* <div className="payment-info">
          <div className="row">
            <div className="data">
              <img alt="Instant EFT" className="sub-logo" src={instantEftLogo}/>
              <img alt="Maestro" className="sub-logo" src={maestroLogo}/>
              <img alt="Master Card" className="sub-logo" src={mastercardLogo}/>
              <img alt="Master Pass" className="sub-logo" src={masterpassLogo}/>
              <img alt="MobiCred" className="sub-logo" src={mobicredLogo}/>
              <img alt="S Code" className="sub-logo" src={sCodeLogo}/>
              <img alt="Visa" className="sub-logo" src={visaLogo}/>
              <img alt="Visa Electron" className="sub-logo" src={visaElectronLogo}/>
            </div>
          </div>
          <div className="row">
            <div>
              You will be redirected to a PayU website after clicking the ‘Pay Now’ button.
            </div>
          </div>
        </div> */}
{/* 
        <div className="button-container">
          { loading ? 
            (
              <Loader />
            )
            :
            (
            <>
              <div className="pay-now-button" onClick={() => this.handleSubmitOrder()}>
                Pay Now
              </div>
            </>
            )
          }

        </div> */}
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    user: state.auth_data.user_data.user,
    special_instructions: state.order_data.special_instructions,
    minimum_order_total: state.meta_data.global_settings.minimum_order_total,
    cart: state.party_data.cart,
  };
};
export default withRouter(connect(mapStateToProps, { submitOrder, refreshCart, submitGetOrdersRequest, submitRedeemVoucher })(BasicInfoSummary));
