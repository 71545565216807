import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Loader from 'components/Loader'
import { Helmet } from 'react-helmet'
import PremadePack from '../PickPack/PremadePack'



import {
    addCurrentItem,
    removeCurrentItem,
    getItems,
    submitGetPremadePacks
} from 'redux/actions.js'



import './styles.scss'

class IndividualPack extends React.Component {
  static propTypes = {

  }


  state = {
    id: '',
    pack: null,
    loading: false,
  }

  slugify(str){
    return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
  }

  componentDidMount() {
    this.setState({loading:true})
    this.props.submitGetPremadePacks().then(() => {
        this.setState({loading:false})
        const id = this.props.match.params.id;
        const slug = this.props.match.params.slug;
        const pack = this.findItem(id, slug)
    
        if(pack){
            this.setState({pack})
        }
    
    
        // console.log(id, slug)
        this.setState({id})
    }).catch(() => {
        this.setState({loading:false})
    })



    // this.props.submitGetPremadePacks()
  }

  selectItem(item){
    const { random_items_checked } = this.props

    if(!random_items_checked) {
      this.setState({notification_item: item, item_notification_modal: true})
    }

    this.props.addCurrentItem(item).then(res => {
        this.props.history.push('/build')
    })
  }

  deselectItem(item){
    this.props.removeCurrentItem(item).then(res => {
    })
  }

  clickItem = (item) => {
    if( item !== null) {
      const { current_pack } = this.props

      if( current_pack.find(i => i.item_code === item.item_code)) {
        this.deselectItem(item)
      } else {
        this.selectItem(item)
      }
    }
  }

  findItem(id, slug) {
    const { premade_packs } = this.props
    const ipack = premade_packs.find(pack => {
      if(pack.id.toString() === id & slug === this.slugify(pack.name)){
          return true
      }
    })
    return ipack
  }




  render() {

    const { pack, loading } = this.state

    let item_image_urls = []
    if (pack){
      pack.items.map(item => {
        const item_image_url = process.env.REACT_APP_STATIC_URL+'/img/items/'+item['item_code']+'.jpg'
        item_image_urls.push(item_image_url)
      })
    }


    return (
      <div className="individual-pack">
        { pack ? (
            <div className="card">
                <Helmet>
                    <script type="application/ld+json">
                    {JSON.stringify(
                      {
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": pack.name,
                        "image": [
                          item_image_urls
                        ],
                        "description": pack.name,
                        "sku": pack.id,
                        "brand": {
                          "@type": "Brand",
                          "name": "Party Packers",
                          "url": "https://www.partypackers.co.za/"
                        },
                        "review": {
                          "@type": "Review",
                          "author": {
                            "@type": "Person",
                            "name": "Party Packers"
                          },
                          "positiveNotes": {
                            "@type": "ItemList",
                            "itemListElement": [
                              {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Great gift for a party"
                              },
                              {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Perfect for a kids party"
                              },
                              {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "Convenient for parents"
                              },                              {
                                "@type": "ListItem",
                                "position": 4,
                                "name": "A customisable, editable party pack for your childs party"
                              }
                            ]
                          }
                        },
                        "shippingDetails": {
                          "@type": "OfferShippingDetails",
                          "shippingRate": {
                            "@type": "MonetaryAmount",
                            "value": "0",
                            "currency": "ZAR"
                          },
                          "shippingDestination": [
                            {
                              "@type": "DefinedRegion",
                              "addressCountry": "ZA",
                              "addressRegion": ["WC"]
                            }
                          ]
                        },
                        "offers": {
                          "@type": "Offer",
                          "url": `https://partypackers.co.za/item/${pack.id}/${this.slugify(pack.name)}`,
                          "priceCurrency": "ZAR",
                          "price": pack.price.toString(),
                          "itemCondition": "https://schema.org/NewCondition",
                          "availability": "https://schema.org/InStock"
                        }
                      }
                    )}
                    </script>
                </Helmet>
                <PremadePack
                  party_pack={pack}
                  // selectPack={(pack) => this.selectPack(pack)}
                  // createNotification={ this.props.createNotification }
                  // openImageModal={this.openImageModal}
                />
            </div>
        )
    :
    (
        <>
        { loading ? (
            <div className="loader-container">
                <Loader />
            </div>
        ):
        (
            <div className="card">
                <h3>The party pack you are looking for was not found.</h3>
                <h4></h4>
            </div>
        )
        }
        </>

    )}

            <div className="button-container">
                <Link
                  to={'/pick'}
                >
                  <div className="landing-button pick">
                    Pick a Party Pack
                  </div>
                </Link>
              </div>

      </div>
    )
  }
}

const mapStateToProps = state => {
    return {
        current_party: state.party_data.current_party,
        premade_packs: state.premade_data.premade_packs,
        party_packs: state.party_data.cart.party_packs,
        current_pack: state.party_data.current_pack,
        items: state.item_data.items,
    };
  };

export default withRouter(connect(mapStateToProps, {submitGetPremadePacks, addCurrentItem, removeCurrentItem, getItems})(IndividualPack));
