import {
  SUBMIT_GET_ITEMS,
  SUBMIT_GET_ITEMS_SUCCESS,
  SUBMIT_GET_ITEMS_FAILURE
} from "./types";

export const initialState = {
  items:[],
  is_loading:false,
  error_message: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_GET_ITEMS: {
      return{
        ...state,
        is_loading: true,
        error_message: null,
      }
    }
    case SUBMIT_GET_ITEMS_SUCCESS: {
      const items = action.payload.items;
      return{
        ...state,
        items,
        is_loading: false,
        error_message: null,
      }
    }
    case SUBMIT_GET_ITEMS_FAILURE: {
      const error_message = action.payload.error;
      return{
        ...state,
        is_loading: false,
        error_message,
      }
    }
    default:
      return state;
  }
}
