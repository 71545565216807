import React from 'react'
import backgroundImageWebP from "assets/images/group-of-kids-celebrate-party-fun-together.webp"
import backgroundImageJPG from "assets/images/group-of-kids-celebrate-party-fun-together.jpg"
import ButtonLink from 'components/ButtonLink'

import './styles.scss'
function Land() {
  return (
    <div className="land">
      <div className="background-container">
        <picture>
          <source srcSet={backgroundImageWebP} type="image/webp" />
          <source srcSet={backgroundImageJPG} type="image/jp2" />
          <img className="landing-background-image" src={backgroundImageWebP} alt="party-kids"/>
        </picture>
      </div>
      <div className="title-block">
        <h1 className="title">You pick it,<br/>we pack it</h1>
        <div className="subtitle">Your kids party pack selection and delivery people.</div>
        <div className="button-container">
          <ButtonLink to="/pick" text="Start Here" color="pink"/>
        </div>
      </div>
    </div>
  );
}

export default Land;
