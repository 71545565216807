import React from 'react'
import backgroundImageWebP from "assets/images/lets-get-started.webp"
import backgroundImagePNG from "assets/images/lets-get-started.png"
import ButtonLink from 'components/ButtonLink'

import './styles.scss'
function GetStarted() {
  return (
    <div className="get-started">
      <picture>
        <source srcSet={backgroundImageWebP} type="image/webp" />
        <source srcSet={backgroundImagePNG} type="image/png" />
        <img className="background-image" src={backgroundImageWebP} alt="party-kids"/>
      </picture>
      <div className="title-block">
        <h2 className="title">Let's get started</h2>
        <p className="subtitle">Ready to make your easiest party pack ever?</p>
        <div className="button-container">
          <ButtonLink to="/pick" text="Start Here" color="lightblue"/>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
