import React, { useState } from 'react'

import { connect } from 'react-redux'

import {Link} from 'react-router-dom'

import Input from 'components/Input'
import FullPageHeader from 'components/FullPageHeader'

import './styles.scss'
import { validateEmail } from 'utils/validation'

import facebookLogo from 'assets/images/socials/facebook.svg'
import twitterLogo from 'assets/images/socials/twitter.svg'
import youtubeLogo from 'assets/images/socials/youtube.svg'

import { sendSupportEmail } from 'redux/actions'
import { NotificationManager } from 'react-notifications'

import { Helmet } from 'react-helmet'

function Contact({sendSupportEmail}) {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  
  const updateMessage = (newMessage) => {
    if (newMessage.length <= 500) {
      setMessage(newMessage)
    }
  }

  const submitForm = () => {
    if(validateEmail(email)){
      sendSupportEmail({name, email, message})
        .then((data)=>{
          NotificationManager.success("Your feedback is appreciated! We'll get back to you as soon as possible.", 'Success');
          setName("")
          setEmail("")
          setMessage("")
          return
        })
        .catch((error) => {
          NotificationManager.error(error, 'Error...', 5000);
        })
    } else {
      NotificationManager.warning("Your email address is not valid...", 'Error...', 5000);
      return
    }
  }

  return (
    <div className='contact-us'>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact Party Packers on email or phone. We're looking forward to hearing from you!" />
        <meta name="keywords" content="party packers, easy, party, kids party, fun, packets, gift bags, party packets, events, convenience, contact us, email, phone, facebook, twitter, youtube" />
      </Helmet>
      <FullPageHeader headingText={"Contact Us"} subHeadingText={"Any questions or concerns? Give us a call!"}/>
      <div className='container'>
        <div className="block">
          <div className="heading">
            Call Us
          </div>
          <div className="phone-number-container">
            <a className="phone-number" href="tel:0861555356">086 155 5356</a>
          </div>
          <div className="heading">
            Connect with us
          </div>
          <div className="connect-with-us">
            <div className="social-logos">
              <img alt="Facebook" src={facebookLogo} />
              <img alt="Twitter" src={twitterLogo} />
              <img alt="YouTube" src={youtubeLogo} />
            </div>
          </div>
            <div className="heading">
              Read our <Link className="link" to="faq" >FAQs</Link>
            </div>

        </div>
        <div className="block">
          <div className="heading">
              Get in touch
          </div>
          <div className="form-container">
            <Input className="input" value={name} placeholder="Name*" handle_change={(e) => setName(e.target.value)} />
            <Input className="input" value={email} placeholder="Email Address*" handle_change={(e) => setEmail(e.target.value)} />
            <div className="text-area-container">
              <div className="message-length">{message.length}  / 500</div>
              <textarea className="text-area" value={message} placeholder="Message*" onChange={(e) => updateMessage(e.target.value)}/>
            </div>

            <div className="submit-button" type="button" onClick={() => submitForm()}>Submit</div>
          </div>
        </div>
        {/* <div className="text-container">
          <div className="call-us">
            <div className="mini-heading">
              Call Us
            </div>
            <div className="phone-number">
              086 155 5356
            </div>
          </div>
          <div className="connect-with-us">
            <div className="mini-heading">
              Connect with us
            </div>
            <div className="social-logos">
              <img alt="Facebook" src={facebookLogo} />
              <img alt="Twitter" src={twitterLogo} />
              <img alt="YouTube" src={youtubeLogo} />
            </div>
          </div>
          <div className="connect-faq">
            <div>
              Get help
            </div>
            <Link className="link" to={ '/faq' }>
              Read our FAQ's
            </Link>
          </div>
        </div> */}

      </div>
    </div>
  );
}



export default connect(null, {sendSupportEmail} )(Contact);
