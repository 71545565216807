import { SUBMIT_SIGNUP, SUBMIT_SIGNUP_SUCCESS, SUBMIT_SIGNUP_FAILURE } from "./types";


export const initialState = {
  user: {},
  is_loading: false,
  error_message: null,
  signed_up: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_SIGNUP: {
      return {
        ...state,
        is_loading: true,
        error_message: null,
      }
    }

    case SUBMIT_SIGNUP_SUCCESS:{
      const user = action.payload.user;
      return {
        ...state,
        user,
        is_loading: false,
        error_message:null,
        signed_up: true,
      }
    }

    case SUBMIT_SIGNUP_FAILURE:{
      const error_message = action.payload.error;
      return {
        ...state,
        error_message
      }
    }

    default:
      return state;
  }
}
