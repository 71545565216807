import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { submitPasswordReset } from 'redux/actions.js'
import { NotificationManager } from 'react-notifications';
import FullPageHeader from 'components/FullPageHeader';
import Input from 'components/Input'
import { validateEmail } from 'utils/validation'
import './styles.scss'

class PasswordReset extends React.Component {

  static propTypes = {

  };

  state = {
    email: '',
  };

  handle_change = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(prevstate => {
      const newState = { ...prevstate };
      newState[name] = value;
      return newState;
    });
  };

  async handle_submit (e, data) {
    e.preventDefault();

    if(validateEmail(data.email)){
      data = {
        email:data.email,
      }
  
      this.props.submitPasswordReset(data).then((res) => {
        this.props.history.push('/reset/info')
      })
    } else {
      NotificationManager.warning(`Please enter a valid email address.`, '', 3000);
    }
  }


  render() {
    const { email } = this.state
    return (
      <div className="password-reset">
        <FullPageHeader headingText={"Password Recovery"} subHeadingText={"Please enter your email address below."} />
        <div className="container">
          <form className="password-reset-form" onSubmit={e => this.handle_submit(e, this.state)}>
            <div className="form-input">
              <Input
                type="text"
                name="email"
                value={email}
                placeholder="Email*"
                handle_change={this.handle_change}
              />
            </div>
            <div className="form-submit">
              <input type="submit" />
            </div>
          </form>
        </div>
      </div>
    )
  }

}

export default withRouter(connect(null, {submitPasswordReset})(PasswordReset));
