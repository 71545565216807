import {
  SUBMITTING,
  SUBMIT_FAILURE,
  DELETE_ACCESSORY_LOCAL,
  SUBMIT_DELETE_ACCESSORY_SUCCESS,
  SUBMIT_ADD_ACCESSORY_SUCCESS,
  SUBMIT_ACCESSORY_QUANTITY_UPDATE_SUCCESS,
  UPDATE_ACCESSORY_QUANTITY_LOCAL,
  ADD_ACCESSORY_LOCAL,
  REFRESH_CART,
  SUBMIT_ADD_CURRENT_PACK_SUCCESS,
  UPDATE_PARTY_DATE,
  ADD_PARTY_PACK_LOCAL,
  SUBMIT_DELETE_PARTY_PACK_SUCCESS,
  DELETE_PARTY_PACK,
  SUBMIT_MERGE_CART_SUCCESS,
  SUBMIT_CLEAR_LOCAL_CART,
  SUBMIT_ADD_PARTY_PACK_SUCCESS,
  SUBMIT_QUANTITY_UPDATE_SUCCESS,
  UPDATE_QUANTITY,
  SUBMIT_GET_CART_SUCCESS,
  ADD_CURRENT_ITEM,
  REMOVE_CURRENT_ITEM,
  ADD_CURRENT_PACK,
  LOAD_ITEM,
  DELETE_ITEM,
  SUBMIT_DELETE_ITEM_SUCCESS,
  CUSTOMISE_PREMADE_PACK,
  EDIT_PARTY_PACK,
  UPDATE_EXISTING_PACK_LOCAL,
  START_BUILDING_PACK,
  SUBMIT_UPDATE_EXISTING_PACK_SUCCESS,
  SUBMIT_REDEEM_VOUCHER,
  SUBMIT_REDEEM_VOUCHER_SUCCESS,
  SUBMIT_REDEEM_VOUCHER_FAILURE,
  SUBMIT_REMOVE_VOUCHER_SUCCESS
} from "./types";

import { submitAuthorizedPost, submitAuthorizedGet } from 'redux/api.js'
import axios from 'axios'
import store from 'redux/store'
import ReactPixel from 'react-facebook-pixel';



const submitting = () => ({
  type: SUBMITTING
});

const submitFailure = error_message => ({
  type: SUBMIT_FAILURE,
  payload: {
    error_message
  }
});

export const submitGetCartServer = () => async dispatch => {
  return submitAuthorizedGet('/cart/', submitting, submitGetCartSuccess, submitFailure)
}

export const refreshCart = () => async dispatch => {
  dispatch({ type: REFRESH_CART })
}

export const loadItemID = (id) => {
  if (localStorage.getItem('token')) {
    return ({
      type: LOAD_ITEM,
      payload: { id }
    });
  }
  return ({
    type: 'default',
  })
} 

const submitGetCartSuccess = (data) => dispatch => {
  const cart = data
  return dispatch({
    type: SUBMIT_GET_CART_SUCCESS,
    payload: { cart }
  });
}

// ADD ACCESSORY

export const addAccessory = (new_item) => {
  if (localStorage.getItem('token')) {
    return addAccessoryServer(new_item['item_code'])
  } else {
    return addAccessoryLocal(new_item)
  }
}

const addAccessoryServer = (item_code) => async dispatch => {
  submitAuthorizedPost("/cart/add/accessory/", { item_code }, submitting, submitAddAccessorySuccess, submitFailure)
}

const submitAddAccessorySuccess = (data) => {
  const cart = data.cart
  return ({
    type: SUBMIT_ADD_ACCESSORY_SUCCESS,
    payload: { cart }
  });
}


const addAccessoryLocal = item => async dispatch => {
  return dispatch({
    type: ADD_ACCESSORY_LOCAL,
    payload: {item}
  });
}

//DELETE ACCESSORY


export const deleteAccessory = (index) => {
  const id = store.getState().party_data.cart.accessories[index].id
  if (localStorage.getItem('token')) {
    return deleteAccessoryServer(id)
  } else {
    return deleteAccessoryLocal(index)
  }
}

const deleteAccessoryServer = (id) => async dispatch => {
  submitAuthorizedPost("/cart/delete/accessory/", { id }, submitting, submitDeleteAccessorySuccess, submitFailure)
}

const submitDeleteAccessorySuccess = (data) => {
  const cart = data.cart
  return ({
    type: SUBMIT_DELETE_ACCESSORY_SUCCESS,
    payload: { cart }
  });
}

const deleteAccessoryLocal = index => async dispatch => {
  return dispatch({
    type: DELETE_ACCESSORY_LOCAL,
    payload: {index}
  });
}


// ADD PARTY PACK
export const addPartyPack = (content) => {
  if(!store.getState().party_data.cart.party_packs.find( pack => pack.id === content.id)){
    let total = 0
    let count = 0
    content.items.forEach(item => {
      total += item.price
      count += 1
    })

    total = total * content.amount
    count = count * content.amount
    ReactPixel.trackCustom('Add To Cart', { number: 1, value:total, currency: 'ZAR' });

    if (localStorage.getItem('token')) {
      return addPartyPackServer(content)
    } else {
      return addPartyPackLocal(content)
    }
  }
}



// LOCAL
const addPartyPackLocal = (content, index) => async dispatch => {
  // delete content.id
  return dispatch({
    type: ADD_PARTY_PACK_LOCAL,
    payload: { index, content }
  });
}


export const addPartyPackServer = (content) => async dispatch => {
  const data = {
    party_pack: content,
  }
  return submitAuthorizedPost("/cart/add/partypack/", data, submitting, submitAddPartyPackSuccess, submitFailure)
}

const submitAddPartyPackSuccess = (data) => {
  const cart = data
  return ({
    type: SUBMIT_ADD_PARTY_PACK_SUCCESS,
    payload: { cart }
  });
}

export const deletePartyPack = (index) => {
  if (localStorage.getItem('token')) {
    const party_pack_id = store.getState().party_data.cart.party_packs[index].id
    return deletePartyPackServer(party_pack_id)
  } else {
    return deletePartyPackLocal(index)
  }
}


export const deletePartyPackServer = (party_pack_id) => async dispatch => {
  const data = {
    party_pack_id
  }
  return submitAuthorizedPost('/cart/delete/partypack/', data, submitting, submitDeletePartyPackSuccess, submitFailure)
}

const submitDeletePartyPackSuccess = (data) => {
  const cart = data
  return ({
    type: SUBMIT_DELETE_PARTY_PACK_SUCCESS,
    payload: { cart }
  });
}

export const deletePartyPackLocal = (party_pack_index) => async dispatch => {
  return dispatch({
    type: DELETE_PARTY_PACK,
    payload: { party_pack_index }
  });
}

export const updateQuantity = (party_pack_index, amount) => {
  if (localStorage.getItem('token')) {
    return updateQuantityServer(party_pack_index, amount)
  } else {
    return ({
      type: UPDATE_QUANTITY,
      payload: { party_pack_index, amount }
    });
  }
}

const updateQuantityServer = (party_pack_index, amount) => async dispatch => {
  const party_data = store.getState().party_data
  const data = {
    party_pack_id: party_data.cart.party_packs[party_pack_index].id,
    amount: amount,
  }
  return submitAuthorizedPost("/cart/update/quantity/partypack/", data, submitting, submitQuantityUpdateSuccess, submitFailure)
}

const submitQuantityUpdateSuccess = (data) => {
  return ({
    type: SUBMIT_QUANTITY_UPDATE_SUCCESS,
    payload: { cart: data }
  });
}

export const updateAccessoryQuantity = (accessory_index, amount) => {
  if (localStorage.getItem('token')) {
    return updateAccessoryQuantityServer(accessory_index, amount)
  } else {
    return updateAccessoryQuantityLocal(accessory_index, amount)
  }
}

const updateAccessoryQuantityLocal = (index, amount) => async dispatch => {
  return dispatch({
    type: UPDATE_ACCESSORY_QUANTITY_LOCAL,
    payload: { index, amount}
  })
}

const updateAccessoryQuantityServer = (accessory_index, amount) => async dispatch => {
  const party_data = store.getState().party_data
  const data = {
    accessory_id: party_data.cart.accessories[accessory_index].id,
    amount,
  }
  return submitAuthorizedPost("/cart/update/quantity/accessory/", data, submitting, submitAccessoryQuantityUpdateSuccess, submitFailure)
}

const submitAccessoryQuantityUpdateSuccess = (data) => {
  return ({
    type: SUBMIT_ACCESSORY_QUANTITY_UPDATE_SUCCESS,
    payload: { party: data }
  });
}


export const submitMergeCart = () => async dispatch => {
  const cart_data = store.getState().party_data.cart

  return submitAuthorizedPost("/cart/merge/", cart_data, submitting, submitMergeCartSuccess, submitFailure)
}


const submitMergeCartSuccess = data => ({
  type: SUBMIT_MERGE_CART_SUCCESS,
  payload: {
    party: data.cart
  }
});

export const submitClearLocalCart = () => ({
  type: SUBMIT_CLEAR_LOCAL_CART,
});


export const addCurrentItem = (item) => async dispatch => {
  return dispatch({
    type: ADD_CURRENT_ITEM,
    payload: {
      item
    }
  })
};

export const removeCurrentItem = (item) => async dispatch => {
  return dispatch({
    type: REMOVE_CURRENT_ITEM,
    payload: {
      item
    }
  })
};

export const deleteItem = (pack_index, item_index) => async dispatch => {
  const party_pack = store.getState().party_data.cart.party_packs[pack_index]
  const party_pack_id = party_pack.id
  const item_code = party_pack.items[item_index].item_code
  if (localStorage.getItem('token')) {
    const data = {
      party_pack_id,
      item_code
    }
  
    return submitAuthorizedPost('/cart/delete/item/', data, submitting, submitDeleteItemSuccess, submitFailure)
  } else {
    if (party_pack.items.length <= 1) {
      return dispatch({
        type: DELETE_PARTY_PACK,
        payload: {
          party_pack_index: pack_index,
        }
      })
    } else {
      return dispatch({
        type: DELETE_ITEM,
        payload: {
          pack_index,
          item_index
        }
      })
    }

  }
};

const submitDeleteItemSuccess = (data) => {
  const cart = data
  return ({
    type: SUBMIT_DELETE_ITEM_SUCCESS,
    payload: { cart }
  });
}


export const addCurrentPack = (quantity) => async dispatch => {
  if(localStorage.getItem('token')){
    const current_pack = store.getState().party_data.current_pack

    let total = 0
    let count = 0
    current_pack.forEach(item => {
      total += item.price
      count += 1
    })
    total = total * quantity
    count = count * quantity
    ReactPixel.trackCustom('Add To Cart', { number: 1, value:total, currency: 'ZAR' });

    const data = {
      party_pack: {
        name: "Custom Pack",
        amount: quantity,
        items: current_pack,
      }
    }
    return submitAuthorizedPost("/cart/add/partypack/", data, submitting, submitAddCurrentPackSuccess, submitFailure)
  } else {
    return dispatch({
      type: ADD_CURRENT_PACK,
      payload: { quantity }
    })
  }
}

const submitAddCurrentPackSuccess = (data) => {
  const cart = data
  return ({
    type: SUBMIT_ADD_CURRENT_PACK_SUCCESS,
    payload: { cart }
  });
}

export const updatePartyDate = (date) => async dispatch => {
  dispatch({
    type: UPDATE_PARTY_DATE,
    payload: { date }
  })
}

export const startBuildingPack = () => async dispatch => {
  return dispatch ({
    type: START_BUILDING_PACK,
  });
}



export const customisePremadePack = (pack) => async dispatch => {
  return dispatch ({
    type: CUSTOMISE_PREMADE_PACK,
    payload: { pack }
  });
}

export const editPartyPack = (party_pack_index) => async dispatch => {
  return dispatch ({
    type: EDIT_PARTY_PACK,
    payload: { party_pack_index }
  });
}

export const updateExistingPack = () => async dispatch => {
  if (localStorage.getItem('token')) {
    const state = store.getState().party_data
    const current_pack = state.current_pack
    let pack = state.cart.party_packs[state.edit_pack_index]
    pack.items = current_pack
    return submitAuthorizedPost("/cart/update/partypack/", pack, submitting, submitUpdateExistingPackSuccess, submitFailure)
  } else {
    return dispatch(updateExistingPackLocal())
  }
}

const submitUpdateExistingPackSuccess = (data) => async dispatch => {
  const cart = data
  return dispatch ({
    type: SUBMIT_UPDATE_EXISTING_PACK_SUCCESS,
    payload: { cart }

  });
}

const updateExistingPackLocal = () => {
  return {
    type: UPDATE_EXISTING_PACK_LOCAL,
  }
}

export const submitRedeemVoucher = (voucher_code) => async () => {
  return submitAuthorizedPost('/orders/voucher/add/', {voucher_code}, submitting, submitRedeemVoucherSuccess, submitFailure)
}

const submitRedeemVoucherSuccess = (data) => {
  return ({
    type: SUBMIT_REDEEM_VOUCHER_SUCCESS,
    payload: { data }
  });
}

export const removeVoucher = (voucher_id) => async () => {
  return submitAuthorizedPost('/orders/voucher/remove/', {voucher_id}, submitting, submitRemoveVoucherSuccess, submitFailure)
}

const submitRemoveVoucherSuccess = (data) => {
  return ({
    type: SUBMIT_REMOVE_VOUCHER_SUCCESS,
    payload: { data }
  });
}