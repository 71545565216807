import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './styles.scss'
import Collapsible from 'react-collapsible';
import IntegerSelect from 'components/IntegerSelect';


import { 
  deletePartyPack,
  updateQuantity,
  deleteItem,
  customisePremadePack,
  editPartyPack,
} from 'redux/actions.js'


import downArrowIcon from "assets/images/angle-down-solid.svg"
import minusIcon from "assets/images/minus-icon.svg"
import plusIcon from "assets/images/plus-icon.svg"
import checkIcon from "assets/images/check-circle.svg"
import crossIcon from "assets/images/cross-icon.svg"
import deleteIcon from "assets/images/x-circle-fill.svg"
import placeHolderIcon from "assets/images/image-coming-soon.jpg"


class CartPack extends React.Component {

  static propTypes = {
    party_pack: PropTypes.object.isRequired, 
    index: PropTypes.number,
    createNotification: PropTypes.func,
    closeCart: PropTypes.func.isRequired,
  }

  static defaultProps = {
    closeCart: () => null
  }

  state = {
    expanded: true,
    quantity: this.props.party_pack.amount,
    focusing: false,
  }

  togglePackExpand() {
    this.setState({ expanded: !this.state.expanded })
  }

  getPackRealValue(pack){
    let total = 0
    pack.items.map(item => {
      total += parseFloat(item.price);
    })

    return total.toFixed(2)
  }

  handleUpdateQuantity = (amount) => {
    this.props.updateQuantity(this.props.index, amount)
  }

  handleQuantityInputChange = (event) => {
    let num = +event.target.value
    if(!isNaN(num)){
      if(num <= 100 && num > 0){
        this.setState({ quantity: num })
      }
    }
  }

  submitQuantityUpdate (amount) {
    const { party_pack, index } = this.props

    let validatedAmount = 0

    if(amount < 1) {
      validatedAmount = 1
    }

    const newAmount = amount - party_pack.amount + validatedAmount

    this.handleUpdateQuantity(newAmount)
    this.setState({ focusing: false })
  }

  onFocus = () => {
    this.setState({ focusing: true })
  }

  onBlur = (quantity) => {
    this.submitQuantityUpdate(quantity)
  }

  cancelUpdateQuantity = () => {
    this.setState({ focusing: false, quantity: this.props.party_pack.amount})
  }

  removeItem = (item_index) => {
    const { index } = this.props
    this.props.deleteItem(index, item_index)
  }

  handleEditPack = () => {
    const { party_pack, index } = this.props
      this.props.customisePremadePack(party_pack).then(() => {
        this.props.editPartyPack(index).then(() => {
          this.props.closeCart()
          this.props.history.push('/build')
      })
    })
  }
  
  render() {
    const { expanded, quantity, focusing } = this.state
    const { index, party_pack } = this.props

    return(
      <div key={party_pack.id} className="party-pack">
        <div className="pack-details">
          <div className="name">
              { party_pack['name'] }
          </div>
          <div className="options-container">
            <div className="edit-pack-text" onClick={() => this.handleEditPack()} >
              Edit
            </div>
            <div className="delete-pack-text" onClick={() => this.props.deletePartyPack(index)} >
              Remove
            </div>
          </div>

        </div>
        <Collapsible
          open={ expanded }
          transitionTime={350}
          easing="cubic-bezier(.25,.1,.25,1)"
          >
          <div className="items">
            <div className="card-container">
              {
                party_pack.items.map((item,item_index) => {
                  const image_url = process.env.REACT_APP_STATIC_URL+'/img/items/'+item['item_code']+'.jpg'
                  return (
                    <div className="item-info" key={item.item_code}>
                      <div className="item-image-container">
                        <img className="item-image" src={image_url} onError={(e)=>{e.target.onerror = null; e.target.src=placeHolderIcon}} />
                      </div>
                      <div className="text-box">
                        <div className="description-container">
                          <div className="description">
                            {item['item_description']}
                          </div>
                          <div className="delete-item-icon" onClick={ () => this.removeItem(item_index) }>
                            <img src={crossIcon} />
                          </div>
                        </div>
                        <div className="price-container">
                          <div className="label">
                            Item price
                          </div>
                          <div className="price">
                            R{item['price']} {/*<span className="vat-text">(incl. VAT)</span>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                )})
              }
            </div>
          </div>
          <div className="pack-amount-container">
            <p className="label">
              Number of packs
            </p>
            <IntegerSelect
              handleQuantityInputChange = { this.handleQuantityInputChange }
              handleUpdateQuantity = { this.handleUpdateQuantity }
              onBlur = { this.onBlur }
              onFocus = { this.onFocus }
              quantity = { quantity }
            />
          </div>
          <div className="pack-price-container">
            <p className="label">
              Price per pack
            </p>
            <div className="pack-price">
              R{ this.getPackRealValue(party_pack) }
            </div>
          </div>
        </Collapsible>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    party: state.party_data.party,
    logged_in: state.auth_data.logged_in,
  };
};
export default withRouter(connect(mapStateToProps, { customisePremadePack, editPartyPack, deletePartyPack, updateQuantity, deleteItem })(CartPack));
