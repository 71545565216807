import {
  SUBMITTING,
  SUBMIT_FAILURE,
  SUBMIT_PASSWORD_RESET_SUCCESS,
  SUBMIT_PASSWORD_CHANGE_ID_SUCCESS,
  SUBMIT_PASSWORD_UPDATE_SUCCESS
} from './types.js'
import { submitUnauthorizedGet, submitUnauthorizedPost, submitAuthorizedPost } from 'redux/api.js'

const submitting = () => ({
  type: SUBMITTING
});

const submitFailure = error_message => ({
  type: SUBMIT_FAILURE,
  payload: {
    error_message
  }
});

export const submitPasswordUpdate = data => async dispatch => {
  return submitAuthorizedPost('/password/update/', data, submitting, submitPasswordUpdateSuccess, submitFailure)
}

const submitPasswordUpdateSuccess = data => {
  return ({
    type: SUBMIT_PASSWORD_UPDATE_SUCCESS,
    payload: {
      data
    }
  });
}

export const submitPasswordReset = data => async dispatch => {
  submitUnauthorizedPost('/reset/password/', data, submitting, submitPasswordResetSuccess, submitFailure)
}

const submitPasswordResetSuccess = data => {
  return ({
    type: SUBMIT_PASSWORD_RESET_SUCCESS,
    payload: {
      data
    }
  });
}

export const submitPasswordChangeID = data => async dispatch => {
  return submitUnauthorizedPost('/reset/password/change/', data, submitting, submitPasswordChangeIDSuccess, submitFailure)
}

const submitPasswordChangeIDSuccess = data => {
  return ({
    type: SUBMIT_PASSWORD_CHANGE_ID_SUCCESS,
    payload: {
      data
    }
  });
}

export const submitValidatePasswordRecoveryID = id => async dispatch => {
  return submitUnauthorizedGet('/reset/password/validate/'+id+'/', submitting, submitValidatePasswordRecoveryIDSuccess, submitFailure)
}

const submitValidatePasswordRecoveryIDSuccess = data => {
  return ({
    type: SUBMIT_PASSWORD_CHANGE_ID_SUCCESS,
    payload: {
      data
    }
  });
}

