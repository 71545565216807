
export const getCartValueExclVat = (cart) => {
    return parseFloat((getCartValueInclVat(cart)/1.15).toFixed(2))
}

export const getCartValueInclVat = (cart) => {
    let total = 0
    cart.party_packs.map(party_pack => {
        let party_pack_value = 0
        party_pack.items.map(item => {
          party_pack_value += parseFloat(item.price)
        })
        total += parseFloat(party_pack_value)*party_pack.amount
      })

      cart.accessories.map(accessory => {
        total += parseFloat(accessory.item.price) * accessory.quantity
      })

      return parseFloat(total.toFixed(2))
}

export const getShippingPriceInclVAT = (cart) => {
    return parseFloat((getShippingPriceExclVAT(cart) * 1.15).toFixed(2))
}

export const getShippingPriceExclVAT = (cart) => {
    return 0
    if(getCartValueInclVat(cart) < 500) {
        return 86.96
    } else {
        return 0
    }
}

const getVoucherDeductions = (cart) => {
    let voucher_deductions = 0
    if(cart.vouchers){
        cart.vouchers.forEach(voucher => {
            voucher_deductions += voucher.amount_in_cents / 100
        })

        voucher_deductions = parseFloat(voucher_deductions.toFixed(2))
        return voucher_deductions
    }
    return 0
}

export const getTotalWithoutVoucher = (cart) => {
    const total = getCartValueInclVat(cart) + getShippingPriceInclVAT(cart)
    return parseFloat((total).toFixed(2))
}

export const getTotal = (cart) => {
    const cart_value = getCartValueInclVat(cart)
    const shipping_price = getShippingPriceInclVAT(cart)
    const voucher_deductions = getVoucherDeductions(cart)

    let total = cart_value + shipping_price - voucher_deductions
    if(total < 0){
        total = 0
    }

    return parseFloat((total).toFixed(2))
  }

export const getVat = (cart) => {
    const total = getTotal(cart)
    return parseFloat(total - (total/1.15)).toFixed(2)
}

export const getSubtotal = (cart) => {
    return parseFloat(getCartValueExclVat(cart)).toFixed(2)
}
