import React from 'react'
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import dropdownIcon from "assets/images/dropdown-icon.svg"

import './styles.scss'

class Tags extends React.Component {
  static propTypes = {
    filters: PropTypes.shape({}).isRequired,
    toggled: PropTypes.bool.isRequired,
    toggleFilter: PropTypes.func.isRequired,
    contentOnly: PropTypes.bool,
    updateFilters: PropTypes.func.isRequired,
  }

  static defaultProps = {
    contentOnly: false
  }

  state = {
    search_term: ""
  }

  handleChange = (selectedOption) => {
    let filters = Object.assign({}, this.props.filters)
    filters.tags = selectedOption
    this.props.updateFilters(filters)
  };

  handleSearchInputChange = event => {
    const value = event.target.value
    this.setState({ search_term: value })
  }

  handleCheckboxChange = (event, tag) => {
    const checked = event.target.checked

    let filters = Object.assign({}, this.props.filters)

    if(checked) {
      filters.tags.push(tag)
    } else {
      filters.tags = filters.tags.filter(function(el) { return el != tag; }); 
    }

    this.props.updateFilters(filters)
  }

  clearTags = () => {
    let filters = Object.assign({}, this.props.filters)
    filters.tags = new Array()
    this.setState({search_term:""})

    this.props.updateFilters(filters)
  }
  
  camelize = (str) => {
    let lcase = str.toLowerCase()
    const ccase = lcase.charAt(0).toUpperCase() + lcase.slice(1);
    return ccase
  }

  renderContent(){
    const { filters, tags } = this.props

    const { search_term } = this.state

    let filtered_tags = tags

    if (search_term.length > 0) {
      filtered_tags = filtered_tags.filter(item => {
        return item.toLowerCase().match(new RegExp(search_term.toLowerCase(), 'g'))
      })
    }

    return(
      <div className="tags-new">
        <div className="search-input">
          <input onChange={this.handleSearchInputChange} value={search_term} placeholder="Search tags" />
        </div>
        <div className="tag-list">
            {filtered_tags.map(tag => {
              return (
                <div className="tag-element-container" key={ tag }>
                  <div className="checkbox-container">
                    <div className="checkbox">
                      <input
                        id={tag}
                        name={tag}
                        type="checkbox"
                        checked={filters.tags.find(el => el===tag) === undefined ? false : true}
                        value={ tag }
                        onChange={(event) => this.handleCheckboxChange(event, tag)}
                      />
                      <label htmlFor={ tag }></label>
                    </div>
                  </div>
                  <label className="text-label" htmlFor={ tag }>{ this.camelize(tag)  }</label>
                </div>
              )
            })}
        </div>
        <div className="clear-tags" onClick={this.clearTags}>
          Clear all
        </div>
      </div>
    )
  }

  renderContentDropdown(){
    return(
      <div className="filter-content">
        { this.renderContent() }
      </div>
    )
  }


  render() {

    const { filters, toggled, toggleFilter, contentOnly } = this.props
    const toggledStyle= toggled ? {transform:'rotate(180deg)', paddingRight:'10px', paddingLeft:'0px'} : {}
    const highlighted = filters.tags.length > 0

    return (
      contentOnly ? (
        this.renderContent()
      )
      :
      (
        <div className="tags-filter">
          <div className={`filter-heading ${highlighted ? 'active' : ''}`} onClick={() => toggleFilter('tags')}>
            Tags
            <img src={dropdownIcon} style={toggledStyle} />
            {highlighted && (
              <div className="filter-number">{filters.tags.length}</div>
            )}
          </div>
          {toggled && (
            this.renderContentDropdown()
          )}
        </div>
      )
    )
  }
}

const mapStateToProps = state => {
  return {
    tags: state.filter_data.tags,
  };
};

export default withRouter(connect(mapStateToProps, { })(Tags));
