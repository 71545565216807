export const SUBMITTING = "SUBMITTING"
export const SUBMIT_FAILURE = "SUBMIT_FAILURE"

export const SUBMIT_PASSWORD_RESET = "SUBMIT_PASSWORD_RESET";
export const SUBMIT_PASSWORD_RESET_SUCCESS = "SUBMIT_PASSWORD_RESET_SUCCESS";
export const SUBMIT_PASSWORD_RESET_FAILURE = "SUBMIT_PASSWORD_RESET_FAILURE";

export const SUBMIT_PASSWORD_CHANGE_ID = "SUBMIT_PASSWORD_CHANGE_ID";
export const SUBMIT_PASSWORD_CHANGE_ID_SUCCESS = "SUBMIT_PASSWORD_CHANGE_ID_SUCCESS";
export const SUBMIT_PASSWORD_CHANGE_ID_FAILURE = "SUBMIT_PASSWORD_CHANGE_ID_FAILURE";

export const SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID = "SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID";
export const SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_SUCCESS = "SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_SUCCESS";
export const SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_FAILURE = "SUBMIT_VALIDATE_PASSWORD_RECOVERY_ID_FAILURE";

export const SUBMIT_PASSWORD_UPDATE = "SUBMIT_PASSWORD_UPDATE";
export const SUBMIT_PASSWORD_UPDATE_SUCCESS = "SUBMIT_PASSWORD_UPDATE_SUCCESS";
export const SUBMIT_PASSWORD_UPDATE_FAILURE = "SUBMIT_PASSWORD_UPDATE_FAILURE";
