import { combineReducers } from "redux";
import party_data, { initialState as party } from "./parties/reducer.js";
import signup_data, { initialState as signup } from "./signup/reducer.js";
import auth_data, { initialState as auth } from "./auth/reducer.js";
import checkout_data, { initialState as checkout } from "./checkout/reducer.js";
import meta_data, { initialState as meta } from "./meta/reducer.js";
import premade_data, { initialState as premade } from "./premade/reducer.js";
import order_data, { initialState as order } from "./orders/reducer.js";
import collapse_data, { initialState as collapse } from "./collapse/reducer.js";
import item_data, { initialState as item } from "./items/reducer.js";
import filter_data, { initialState as filter } from "./filters/reducer.js";
import email_data, { initialState as email } from "./emails/reducer.js";
import password_data, { initialState as password } from "./password/reducer.js";

import { SUBMIT_LOGOUT, RESET_REDUX } from './auth/types'
import { VALIDATE_STORE_STRUCTURE } from './types.js'

const appReducer = combineReducers({
  party_data,
  signup_data,
  auth_data,
  checkout_data,
  meta_data,
  premade_data,
  order_data,
  collapse_data,
  item_data,
  filter_data,
  email_data,
  password_data
});


const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === SUBMIT_LOGOUT) {
    // we keep a reference of the keys we want to maintain
    // other keys will be passed as undefined and this will call
    // reducers with an initial state
    localStorage.clear()

    state = {
      ...state,
      party_data: party,
      signup_data: signup,
      auth_data: auth,
      checkout_data: checkout,
      order_data: order,
      collapse_data: collapse,
      filter_data: filter,
      email_data: email,
    };
  }

  if (action.type === RESET_REDUX) {
    state = {
      party_data: party,
      signup_data: signup,
      auth_data: state.auth_data,
      checkout_data: checkout,
      premade_data: premade,
      order_data: order,
      collapse_data: collapse,
      item_data: item,
      filter_data: filter,
      email_data: email,
    };
  }

  if (action.type === VALIDATE_STORE_STRUCTURE) {

    let validated_state = state

    const state_mapping = {
      "party_data": party,
      "signup_data": signup,
      "auth_data": auth,
      "checkout_data": checkout,
      "meta_data": meta,
      "premade_data": premade, 
      "order_data": order,
      "collapse_data": collapse,
      "item_data": item,
      "filter_data": filter,
      "email_data": email,
    }

    const state_map_keys = Object.keys(state_mapping)

    for (var k = 0; k < state_map_keys.length; k++) {
      const initial_keys = Object.keys(state_mapping[state_map_keys[k]]).sort()
      const current_keys = Object.keys(state[state_map_keys[k]]).sort()

      // console.log(initial_keys, current_keys)

      let valid = true

      for(var i = 0; i < initial_keys.length; i++) {
        if(initial_keys[i] !== current_keys[i]){
          // console.log("VALIDATE STORE STRUCTURE ERROR")
          // console.log("KEYS", initial_keys[i], current_keys[i])
          // console.log(state_map_keys[k])
          // console.log(validated_state[state_map_keys[k]][initial_keys[i]], state_mapping[state_map_keys[k]][initial_keys[i]])

          validated_state[state_map_keys[k]][initial_keys[i]] = state_mapping[state_map_keys[k]][initial_keys[i]]
          // console.log(initial_keys[i])
          // console.log(current_keys[i])
          valid = false
        }
      }

      // if (valid) {
      //   state.meta_data.redux_structure_ok = true
      // } else {
      //   state.meta_data.redux_structure_ok = false
      //   break;
      // }
    }
    return validated_state
  }

  return appReducer(state, action);
};

export default rootReducer;
