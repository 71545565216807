import {
  SUBMITTING,
  SUBMIT_FAILURE,
  SUBMIT_GET_EMAILS_SUCCESS,
  SUBMIT_EMAIL_UPDATE_SUCCESS,
  SUBMIT_CONFIRM_EMAIL_CHANGE_SUCCESS
} from './types.js'
import { submitAuthorizedGet, submitAuthorizedPost, submitUnauthorizedPost } from 'redux/api.js'

const submitting = () => ({
  type: SUBMITTING
});

const submitFailure = error_message => ({
  type: SUBMIT_FAILURE,
  payload: {
    error_message
  }
});

export const submitGetEmailsServer = () => async dispatch => {
  submitAuthorizedGet('/email/logs/', submitting, submitGetEmailsSuccess, submitFailure)
}

const submitGetEmailsSuccess = (data) => {
  return ({
    type: SUBMIT_GET_EMAILS_SUCCESS,
    payload: { emails: data }
  });
}

export const submitEmailUpdate = data => async dispatch => {
  submitAuthorizedPost('/email/update/', data, submitting, submitEmailUpdateSuccess, submitFailure)
}

const submitEmailUpdateSuccess = data => {
  return ({
    type: SUBMIT_EMAIL_UPDATE_SUCCESS,
    payload: {
      data
    }
  });
}

export const submitConfirmEmailChange = data => async dispatch => {
  submitAuthorizedPost('/email/update/confirm/', data, submitting, submitConfirmEmailChangeSuccess, submitFailure)
}

const submitConfirmEmailChangeSuccess = data => {
  return ({
    type: SUBMIT_CONFIRM_EMAIL_CHANGE_SUCCESS,
    payload: {
      data
    }
  });
}

export const sendSupportEmail = (data) => async dispatch => {
  return submitUnauthorizedPost('/support/email/', data, submitting, submitting, submitting)
}

